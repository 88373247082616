import * as Yup from "yup";
import { TFunction } from "i18next";
import {BranchConfigInput, BranchMultipleConfigUpdateInput, BranchProxy} from "@me-team/host/main/graphql/types";

export class FacebookPixelService {
    public static FacebookPixelValidation = (
        translate: TFunction<"translation">,
        branchList: BranchProxy[]
    ) => {
        const pixelRegex = /fbq\(.*?['"]init['"].*?\)/;

        return Yup.object({
            branches: Yup.object().shape(
                branchList.reduce(
                    (acc: Record<string, Yup.StringSchema>, branch: BranchProxy) => {
                        acc[branch.id.toString()] = Yup.string()
                            .nullable()
                            .notRequired()
                            .test(
                                "isValidPixelCode",
                                translate(
                                    "The Pixel Code is invalid or missing the fbq('init') call."
                                ),
                                (value) => {
                                    if (!value || value.trim().length === 0) {
                                        return true;
                                    }
                                    return pixelRegex.test(value);
                                }
                            );
                        return acc;
                    },
                    {}
                )
            ),
        });
    };

    public static mutationConfigService = (branches : {}) : BranchMultipleConfigUpdateInput => {

        return <BranchMultipleConfigUpdateInput>{
            configs: Object.entries(branches).map(([branchId, jsConfig]) => ({
                branch: parseInt(branchId, 10),
                jsConfig: jsConfig ? jsConfig : null,
            }))
        }

    }
}
