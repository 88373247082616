import React from "react";
import {PosCalendar} from "@me-pos/calendar";
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {CalendarClientFactory} from '../../network/CalendarClientFactory';
import {CalendarFilterI} from './constants';

interface CalendarWrapperComponentProps {
  currentCompanyId: number;
  currentBranchId: number;
  filters: CalendarFilterI;
  timeZone: string;
  showPreloaderHandler: () => void;
  hidePreloaderHandler: () => void;
  newBookingId?: number;
  newDate?: string;
}

export const CalendarWrapperComponent: React.FC<CalendarWrapperComponentProps> = ({
                                                                                    currentCompanyId,
                                                                                    currentBranchId,
                                                                                    filters,
                                                                                    showPreloaderHandler,
                                                                                    hidePreloaderHandler,
                                                                                    newBookingId,
                                                                                    timeZone,
                                                                                    newDate
                                                                                  }) => {
  const {t, i18n} = useTranslation();
  const basePath = process.env.REACT_APP_POS_URL;
  const WSS_URL = process.env.REACT_APP_SOCKET;

  return (
    <PosCalendar
      branchId={+currentBranchId}
      companyId={+currentCompanyId}
      locale={i18n.language}
      statusId={filters.statusId}
      positionId={filters.positionId}
      employeeId={filters.employeeId}
      serviceId={filters.serviceId}
      timeZone={timeZone}
      onRequestStart={showPreloaderHandler}
      onRequestEnd={hidePreloaderHandler}
      apiUrl={basePath + "/"}
      socketUrl={WSS_URL}
      axios={CalendarClientFactory.create()}
      i18n={i18next}
      newBookingId={newBookingId}
      newDate={newDate}
    />
  )
}