import React, {useEffect, useState} from 'react';
import { TableHeader } from '@services/ui-components';
import { useTranslation } from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import CreateUpdateClientForm from '../CreateClient/CreateUpdateClientForm';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import ErrorService from '../../services/ErrorService';
import {UserRole} from '../../utils/enums';
import { useGetModulesListQuery } from '@me-team/host/main/modulesList/graphql/modulesList.hooks';
import {
  useGetOneClientQuery,
  useUpdateCompanyModuleRelationMutation
} from '@me-team/host/main/clients/graphql/clients.hooks';

const UpdateClient: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const [isLoadAddModule, setIsLoadAddModule] = useState<boolean>(!isEmployeeRole);
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const { id } = useParams();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const {data: modulesList} = useGetModulesListQuery({
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })
  const clientsModuleId = modulesList && modulesList?.relations?.modules?.find(module => module.name === "Clients");


  const [updateCompanyModuleRelation] = useUpdateCompanyModuleRelationMutation({
    variables: {companyId: +currentCompanyId, input: {id: clientsModuleId?.id, isActive: true}},
    context: {
      errorType: 'local'
    },
    onCompleted: (data) => {
      data && setIsLoadAddModule(false)
    },
    onError: (error: ApolloError) => {
      const graphQLErrors = error?.graphQLErrors[0]?.extensions?.category
      console.error('error', graphQLErrors)
      error && setIsLoadAddModule(false)
      if (graphQLErrors === 'user') {
        navigate("/error/403", {replace: true} )
      }
      else if (graphQLErrors === 'internal') {
        navigate("/error/500", {replace: true} )
      } else {
        console.error(`[GraphQL error]: ${error?.graphQLErrors[0]?.extensions}`);
      }
    }
  })

  useEffect(() => {
    if (clientsModuleId && !isEmployeeRole) {
      updateCompanyModuleRelation()
    }
  }, [currentCompanyId, clientsModuleId, isEmployeeRole]);


  const {
    data: oneClientData,
    error,
    loading,
  } = useGetOneClientQuery({
    variables: {
      id: +id,
      companyId: +currentCompanyId,
    },
    skip: !currentCompanyId || !id,
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const client = oneClientData?.user?.company?.clients?.clients[0];

  if (loading || isLoadAddModule) return <Spinner />;
  return (
    <div className='mb-3 px-3'>
      <TableHeader
        title={`${t('Editing')} ${client?.name}`}
        paths={[
          { path: `/clients/${currentCompanyId}/list/${id}/edit`, label: t('Customers') },
          { path: `/clients/${currentCompanyId}/list`, label: t('List of Clients') },
          { path: `/clients/${currentCompanyId}/list/${id}/edit`, label: `${t('Editing')} ${client?.name}` },
        ]}
        colSize={12}
      />
      <CreateUpdateClientForm />
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </div>
  );
};

export default UpdateClient;
