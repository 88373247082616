/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetServicesProvidersDocument = gql`
    query GetServicesProviders($serviceId: Int!, $page: Int, $itemsPerPage: Int, $employeePosition: Int, $employeeName: String, $companyId: Int!) {
  user {
    id
    company(id: $companyId) {
      employeesList(search: null, positionId: null) {
        id
        name
        employeeServiceRelations(serviceId: $serviceId) {
          id
        }
      }
      services(id: $serviceId) {
        services {
          id
          name
          price
          duration
          onlineRegistration
          serviceEmployees(
            page: $page
            itemsPerPage: $itemsPerPage
            employeePosition: $employeePosition
            employeeName: $employeeName
          ) {
            serviceEmployees {
              id
              name
              surname
              recordSetting {
                id
                isOnlineSignUpAvailable
              }
              position {
                id
                name
              }
              image
              employeeServiceRelations(serviceId: $serviceId) {
                id
                duration
                price
                online
                salaryType
                salaryValue
                deleted
                employee {
                  name
                  surname
                  recordSetting {
                    id
                    isOnlineSignUpAvailable
                  }
                }
                service {
                  name
                }
              }
            }
            totalCount
            currentPage
            itemsPerPage
            pageCount
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetServicesProvidersQuery__
 *
 * To run a query within a React component, call `useGetServicesProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesProvidersQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      employeePosition: // value for 'employeePosition'
 *      employeeName: // value for 'employeeName'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetServicesProvidersQuery(baseOptions: Apollo.QueryHookOptions<GetServicesProvidersQuery, GetServicesProvidersQueryVariables> & ({ variables: GetServicesProvidersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServicesProvidersQuery, GetServicesProvidersQueryVariables>(GetServicesProvidersDocument, options);
      }
export function useGetServicesProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServicesProvidersQuery, GetServicesProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServicesProvidersQuery, GetServicesProvidersQueryVariables>(GetServicesProvidersDocument, options);
        }
export function useGetServicesProvidersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetServicesProvidersQuery, GetServicesProvidersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetServicesProvidersQuery, GetServicesProvidersQueryVariables>(GetServicesProvidersDocument, options);
        }
export type GetServicesProvidersQueryHookResult = ReturnType<typeof useGetServicesProvidersQuery>;
export type GetServicesProvidersLazyQueryHookResult = ReturnType<typeof useGetServicesProvidersLazyQuery>;
export type GetServicesProvidersSuspenseQueryHookResult = ReturnType<typeof useGetServicesProvidersSuspenseQuery>;
export type GetServicesProvidersQueryResult = Apollo.QueryResult<GetServicesProvidersQuery, GetServicesProvidersQueryVariables>;
export type GetServicesProvidersQueryVariables = Types.Exact<{
  serviceId: Types.Scalars['Int']['input'];
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  employeePosition?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  employeeName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetServicesProvidersQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', employeesList?: Array<{ __typename?: 'EmployeeProxy', id: number, name?: string | null, employeeServiceRelations?: Array<{ __typename?: 'EmployeeServiceRelationProxy', id: number }> | null }> | null, services?: { __typename?: 'ServicePaginated', services?: Array<{ __typename?: 'ServiceProxy', id: number, name: string, price?: number | null, duration?: string | null, onlineRegistration: boolean, serviceEmployees?: { __typename?: 'ServiceEmployeesPaginated', totalCount?: number | null, currentPage?: number | null, itemsPerPage?: number | null, pageCount?: string | null, serviceEmployees?: Array<{ __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null, image?: string | null, recordSetting?: { __typename?: 'RecordSettingProxy', id: number, isOnlineSignUpAvailable: boolean } | null, position?: { __typename?: 'EmployeePositionProxy', id: number, name: string } | null, employeeServiceRelations?: Array<{ __typename?: 'EmployeeServiceRelationProxy', id: number, duration?: string | null, price?: number | null, online: boolean, salaryType?: number | null, salaryValue?: number | null, deleted: boolean, employee?: { __typename?: 'EmployeeProxy', name?: string | null, surname?: string | null, recordSetting?: { __typename?: 'RecordSettingProxy', id: number, isOnlineSignUpAvailable: boolean } | null } | null, service?: { __typename?: 'ServiceProxy', name: string } | null }> | null }> | null } | null }> | null } | null } | null } | null };
