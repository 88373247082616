import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {  useParams } from 'react-router-dom';
import { Col, Row, Button } from "react-bootstrap";
import {FiltersButton, ReactSelect, TableHeader} from '@services/ui-components';
import VioletBadge from '@services/ui-components/src/VioletBadge/VioletBadge';
import ServiceProvidersTable from './ServiceProvidersTable/ServiceProvidersTable';
import {useReactiveVar} from "@apollo/client";
import {currentCompanyIdVar} from "@me-team/host/src/apollo/globalVar/state";
import { useGetEmployeesPositionsQuery } from '@me-team/host/main/addEmployee/graphql/addEmployee.hooks';

const ServiceProviders: React.FC = () => {
    const { t } = useTranslation();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);

    const { id } = useParams();
    const inputRef = useRef(null);
    const [showFilters, setShowFilters] = useState(false)
    const [filter, setFilter] = useState({ name: null, positionId: null, })
    const [currentFilter, setCurrentFilter] = useState({ name: inputRef.current?.value, positionId: null, })
    const [name, setName] = useState()
    const { data: possitions, loading: loadingPossitions } = useGetEmployeesPositionsQuery({
        variables: { companyId: +currentCompanyId },
        skip: !currentCompanyId,
    })
    const toggleFilters = () => setShowFilters(!showFilters);

    const handlePositionChange = (selectedOption: any) => {
        setCurrentFilter(prevFilter => ({
            ...prevFilter,
            positionId: selectedOption?.value
        }));
    };


    const applyFilters = () => {
        setFilter({
            name: inputRef.current?.value,
            positionId: currentFilter.positionId
        })
    };
    const clearFilters = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
        }
        setCurrentFilter({ name: null, positionId: null });
        setFilter({ name: null, positionId: null });
    };
    const positionsOptions = possitions?.user?.company?.employeePositions?.employeePositions.map(position => ({
        value: position.id,
        label: position.name
    }));
    return (
        <>
            <VioletBadge text={t('On this page, you can choose service providers and specify different service durations and prices for different employees.')} />
            <TableHeader
                title={`${t('Service Providers')} «${name || ' '}»`}
                paths={[{ path: "/services-providers/" + id, label: t('Services') }, { path: `/services/service/${currentCompanyId}/service-list`, label: t('Service list') }, { path: "/services-providers/" + id, label: t('Service Providers') }]}
                buttons={
                    <Row className="mt-4 mt-lg-0 justify-content-end">
                        <Col sm={6} lg xxl={9}>
                            <FiltersButton filter={filter} showFilters={showFilters} onClick={toggleFilters}/>
                        </Col>
                    </Row>
                }
                showFilters={showFilters}
                colSize={10}
                btnColSize={2}
            >
                <Row className="gx-3 mb-3">
                    <Col lg={8} xl={9}>
                        <Row className="gx-3">
                            <Col lg={6} className="text-primary">
                                <div className="position-relative mb-3 mb-lg-0">
                                    <input
                                        type="text"
                                        name="name"
                                        id="serviceProviders-search-field"
                                        className="form-control ps-4 pe-5 py-6 text-truncate"
                                        placeholder={t('Search by name, surname, phone or e-mail')}
                                        ref={inputRef}
                                    />
                                    <i className="bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-2"></i>
                                </div>
                            </Col>
                            <Col lg={6} className="text-primary  mb-3 mb-lg-0">
                                <ReactSelect
                                    name={"serviceProviders-selectPosition-field"}
                                    id={"serviceProviders-selectPosition-field"}
                                    options={positionsOptions}
                                    placeholder={t('Select Position')}
                                    onChange={handlePositionChange}
                                    value={currentFilter.positionId ? positionsOptions.find(option => option.value === currentFilter.positionId) : null}
                                    isSearchable={true}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4} xl={3}>
                        <Row className="gx-3 h-100">
                            <Col xs={6} className="mb-3 mb-lg-0">
                                <button
                                    type="button"
                                    className="btn btn-outline-dark border-grey w-100 fw-normal"
                                    onClick={clearFilters}
                                >
                                    {t('clear')}
                                </button>
                            </Col>
                            <Col xs={6} className="mb-3 mb-lg-0">
                                <button
                                    className="btn btn-primary w-100 px-1"
                                    onClick={applyFilters}
                                >
                                    {t('apply')}
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>


            </TableHeader>

            <ServiceProvidersTable filter={filter} setServiceName={setName} />
        </>
    );
};

export default ServiceProviders;
