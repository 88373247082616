import {TFunction} from "i18next";
import {
    ReactSelectInterface,
    recordFormValueType,
    ServicePickCategoryInterfaceInner,
    ServicePickSubcategoryInner,
    StatusPickerInner,
    ValidationRecordError
} from "../../interfaces/interfaces";
import {DateTime} from "luxon";
import {bookingStatusIds, notificationRecordOption} from "../../utils/enums";
import {
    BookingMainProxy,
    BookingStatusProxy, BookingTime, EmployeeServiceRelationProxy,
    ServiceCategoryNewProxy,
    ServiceProxy
} from "@me-team/host/main/graphql/types";
import * as Yup from "yup";
import {PhoneNumberUtil} from "google-libphonenumber";
import {
    BookingAdminInput,
    ClientAdminBookingInput, EmployeeBookingProxy,
} from "@me-team/host/booking/graphql/types";
import {ApolloError} from "@apollo/client";

type inputValues = {
    isPicked: boolean,
    value: string
}

type notificationOptionsType = {
    value: number,
    label: string,
    thresholdMinutes?: number
}

export class AddRecordService {

    //TODO ADD TRANSLATE TO ERROR
    public static getCategoryAndServiceName = (categoryList: ServiceCategoryNewProxy[],
                                               categoryId: number, serviceId: number,
                                               translate: TFunction<"translation">): inputValues => {
        if (categoryList && categoryList.length !== 0 && categoryId && serviceId) {
            const currentCategory: ServiceCategoryNewProxy = categoryList.find((category: ServiceCategoryNewProxy) => category.id === categoryId);

            if (currentCategory) {
                const currentService = currentCategory.services.find((service: ServiceProxy) => service.id === serviceId);
                if (currentService) {
                    return {
                        isPicked: true,
                        value: `${currentService.name} "${currentCategory.name}"`
                    }
                } else {
                    return {
                        isPicked: false,
                        value: 'Service Not Found'
                    }
                }
            } else {
                return {
                    isPicked: false,
                    value: 'Category Not Found'
                }
            }
        }
        return {
            isPicked: false,
            value: translate('Select Service')
        }
    }

    public static searchCategoryAndService = (
        categoryList: ServicePickCategoryInterfaceInner[], currentInputValue: string)
        : ServicePickCategoryInterfaceInner[] => {

        if (currentInputValue !== '') {
            return categoryList.map((category: ServicePickCategoryInterfaceInner): ServicePickCategoryInterfaceInner => {
                const searchWords: string[] = currentInputValue.split(" ");

                const categoryMatch = searchWords.some((word: string) =>
                    category.name.toLowerCase().includes(word.toLowerCase())
                );

                const filteredSubcategories: ServicePickSubcategoryInner[] = category.servicePickerList.map(
                    (subcategory: ServicePickSubcategoryInner): ServicePickSubcategoryInner => ({
                        ...subcategory,
                        isHidden: !subcategory.name
                            .toLowerCase()
                            .includes(currentInputValue.toLowerCase()),
                    })
                );

                const subcategoryMatch: boolean = filteredSubcategories.some(
                    (subcategory: ServicePickSubcategoryInner) => !subcategory.isHidden
                );


                return {
                    ...category,
                    isHidden: !(categoryMatch || subcategoryMatch),
                    isOpen: true,
                    servicePickerList: filteredSubcategories,
                }
            })
        }
        return categoryList.map((category) => {
            return {
                ...category,
                isHidden: false,
                isOpen: false,
                servicePickerList: category.servicePickerList.map((service) => {
                    return {
                        ...service,
                        isHidden: false
                    }
                })
            }
        })
    }

    public static isCategoryEmpty = (categoryList: ServicePickCategoryInterfaceInner[]): boolean => {
        if (categoryList && categoryList.length !== 0) {
            return categoryList.every((category) =>
                category.servicePickerList.every((service) => service.isHidden)
            );
        }
        return true;
    }

    public static statusToShow = (statusList: BookingStatusProxy[], pickedDate: DateTime, isWithWarehouse: boolean): StatusPickerInner[] => {
        const currentDate = DateTime.now();

        const filteredStatus = statusList.filter((status) => {
            if (isWithWarehouse) {
                return status.id !== bookingStatusIds.COMPLETED
            } else {
                return status.id !== bookingStatusIds.COMPLETED_WITH_WAREHOUSE
            }
        })

        const checkByDate = (status: BookingStatusProxy): boolean => {
            if (pickedDate <= currentDate) {
                switch (status.id) {
                    case (bookingStatusIds.COMPLETED) : {
                        return false
                    }
                    case (bookingStatusIds.COMPLETED_WITH_WAREHOUSE) : {
                        return false
                    }
                    case (bookingStatusIds.BOOKING_WAS_CANCELED) : {
                        return false
                    }
                    case (bookingStatusIds.CLIENT_HAS_NOT_ARRIVED) : {
                        return false
                    }
                    default : {
                        return true
                    }
                }
            }

            return !(pickedDate >= currentDate)
        };

        const setDefaultStatus = (status: BookingStatusProxy): boolean => {
            if (pickedDate <= currentDate) {
                switch (status.id) {
                    case (bookingStatusIds.COMPLETED) : {
                        return true
                    }
                    case (bookingStatusIds.COMPLETED_WITH_WAREHOUSE) : {
                        return true
                    }
                    default : {
                        return false
                    }
                }
            } else {
                return status.id === bookingStatusIds.WAITING_FOR_CLIENT
            }

        }


        return filteredStatus.map((status: BookingStatusProxy) => {
            return {
                ...status,
                isHidden: checkByDate(status),
                useByDefault: setDefaultStatus(status)
            };
        });
    };

    public static calcEndTime = (startTime: DateTime, duration: string) => {
        const [hours, minutes, seconds] = duration.split(':').map(Number);
        return startTime
            .plus({hours})
            .plus({minutes})
            .plus({seconds})
    }

    public static statusService = (status: {
        isSelected: boolean,
        statusId: number
    }, statusList: StatusPickerInner[], recordUuid?: string): StatusPickerInner => {
        const clearStatusList: StatusPickerInner[] = statusList.filter((statusItem) => !statusItem.isHidden)
        const selectedStatus: StatusPickerInner = clearStatusList.find((statusItem) => status.statusId === statusItem.id);
        if (recordUuid) {
            const currentStatus = statusList.find((currentStatus) => {
                return currentStatus.id === status.statusId
            });
            currentStatus.isHidden = false;
            currentStatus.useByDefault = true;
            return currentStatus
        } else {
            if (selectedStatus && status.isSelected) {
                return selectedStatus
            } else {
                return statusList.find((statusItem: StatusPickerInner) => statusItem.useByDefault)
            }
        }


    }

    public static notificationOptions = (translate: TFunction<"translation">): notificationOptionsType[] => {
        return [
            {
                label: translate('In 30 minutes'),
                value: notificationRecordOption.IN_30_MIN,
                thresholdMinutes: 30
            },
            {
                label: translate('In an Hour'),
                value: notificationRecordOption.IN_1_HOUR,
                thresholdMinutes: 60
            },
            {
                label: translate('In 2 hours'),
                value: notificationRecordOption.IN_2_HOUR,
                thresholdMinutes: 120
            },
            {
                label: translate('In 3 hours'),
                value: notificationRecordOption.IN_3_HOUR,
                thresholdMinutes: 180
            },
            {
                label: translate('In a Day'),
                value: notificationRecordOption.IN_ONE_DAY,
                thresholdMinutes: 1440
            },
            {
                label: translate('In 2 days'),
                value: notificationRecordOption.IN_TWO_DAYS,
                thresholdMinutes: 2880
            },
            {
                label: translate('Do not notify'),
                value: notificationRecordOption.DO_NOT_NOTIFY
            }
        ];
    }

    public static notificationSelectOptions = (
        selectedDate: DateTime,
        translate: TFunction<"translation">
    ): ReactSelectInterface[] => {

        const options = this.notificationOptions(translate);

        if (selectedDate) {
            const now = DateTime.now();
            const timeDiffInMinutes = selectedDate.diff(now, 'minutes').minutes;

            if (timeDiffInMinutes <= 0) {
                return options.filter(option => option.value === notificationRecordOption.DO_NOT_NOTIFY);
            }

            return options.filter(option =>
                !option.thresholdMinutes || option.thresholdMinutes <= timeDiffInMinutes
            );
        }
        return options
    };


    public static getDefaultNotificationOption = (
        selectedDate: DateTime,
        translate: TFunction<"translation">
    ): ReactSelectInterface | null => {
        const now = DateTime.now();
        const timeDiffInMinutes = selectedDate.diff(now, 'minutes').minutes;

        const options = this.notificationOptions(translate);

        if (timeDiffInMinutes >= 1440) {
            const option = options.find(option => option.value === notificationRecordOption.IN_ONE_DAY);
            return option || null;
        }

        const defaultOption = options.find(option =>
            option.thresholdMinutes && option.thresholdMinutes <= timeDiffInMinutes
        );

        return defaultOption || options.find(option => option.value === notificationRecordOption.DO_NOT_NOTIFY) || null;
    };

    public static notificationValueCalc = (selectedNotification: {
        isUserPick: boolean,
        date: string
    }, selectedDate: DateTime, translate: TFunction<"translation">): ReactSelectInterface => {
        if (!selectedNotification.isUserPick) {
            return this.getDefaultNotificationOption(selectedDate, translate)
        }
        if (selectedNotification.isUserPick) {
            const options = this.notificationOptions(translate);
            if (selectedNotification.date) {
                const valueToDateTime = DateTime.fromFormat(selectedNotification.date, 'yyyy-MM-dd HH:mm:ss');
                const diff = selectedDate.diff(valueToDateTime, 'minutes').minutes;

                if (diff >= 1440) {
                    return options.find(option => option.value === notificationRecordOption.IN_ONE_DAY);
                } else {
                    if (diff > 0) {
                        return options.find((option) => option.thresholdMinutes === diff)
                    } else {
                        return this.getDefaultNotificationOption(selectedDate, translate)
                    }
                }

            } else {
                return options.find(option => option.value === notificationRecordOption.DO_NOT_NOTIFY)
            }
        }
    }

    public static notificationValueToString = (selectedValue: ReactSelectInterface, selectedDate: DateTime) => {
        const notificationsTimes = {
            halfHour: 30,
            hour: 60,
            twoHour: 120,
            threeHour: 180,
            oneDay: 1440,
            twoDays: 2880,
        };

        let notificationDate: DateTime | null = null;

        if (selectedValue) {
            switch (selectedValue.value) {
                case notificationRecordOption.IN_30_MIN:
                    notificationDate = selectedDate.minus({minutes: notificationsTimes.halfHour});
                    break;
                case notificationRecordOption.IN_1_HOUR:
                    notificationDate = selectedDate.minus({minutes: notificationsTimes.hour});
                    break;
                case notificationRecordOption.IN_2_HOUR:
                    notificationDate = selectedDate.minus({minutes: notificationsTimes.twoHour});
                    break;
                case notificationRecordOption.IN_3_HOUR:
                    notificationDate = selectedDate.minus({minutes: notificationsTimes.threeHour});
                    break;
                case notificationRecordOption.IN_ONE_DAY:
                    notificationDate = selectedDate.minus({minutes: notificationsTimes.oneDay});
                    break;
                case notificationRecordOption.IN_TWO_DAYS:
                    notificationDate = selectedDate.minus({minutes: notificationsTimes.twoDays});
                    break;
                default:
                    notificationDate = null;
                    break;
            }
        }

        return notificationDate
            ? notificationDate.set({second: 0}).toFormat('yyyy-MM-dd HH:mm:ss')
            : null
    }


    public static recordValidation = (translate: TFunction<"translation">) => {

        const phoneUtil = PhoneNumberUtil.getInstance();

        const isPhoneValid = (phone: string) => {
            try {
                return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
            } catch (error) {
                return false;
            }
        };

        const emailValid = (email : string) => {
            if (email) {
                const emailRegex =
                    /^([\w!#$%&'*+/=?^`{|}~]+\.)*[\w!#$%&'*+/=?^`{|}~]+@(([a-z0-9][a-z0-9\-]{0,62}[a-z0-9]\.)+[a-z]{2,6}|\d{1,3}(\.\d{1,3}){3}(:\d{1,5})?)$/i;
                return emailRegex.test(email);
            } else {
                return true
            }
        }

        return Yup.object().shape({
            service: Yup.object().shape({
                serviceId: Yup.number().nullable().required(translate('Service is required')),
            }),
            employeeId: Yup.number().nullable().required(translate('Employee is required')),
            pickedDate: Yup.date()
                .nullable()
                .required(translate('Date is required'))
                .typeError(translate('Invalid date format')),
            startTime: Yup.date()
                .nullable()
                .required(translate('Start time is required')),
            phone: Yup.string()
                .nullable()
                .test(
                    'phone-or-email',
                    translate('Either phone or email must be provided'),
                    function (value) {
                        const {email} = this.parent;
                        return value || email;
                    }
                )
                .test('is-valid-phone', translate('Phone number is not valid'), value => !value || isPhoneValid(value)),
            email: Yup.string()
                .trim()
                .nullable()
                .email(translate('Invalid email format'))
                .test('is-valid-email', translate('Invalid email format'), (value) => emailValid(value || ''))
                .test(
                    'email-or-phone',
                    translate('Either phone or email must be provided'),
                    function (value) {
                        const {phone} = this.parent;
                        return value || phone;
                    }
                ),
            clientName: Yup.string()
                .trim()
                .min(2, translate('Client name must be at least 2 characters'))
                .max(50, translate('Client name cannot be longer than 50 characters'))
                .required(translate('Client name is required')),

            clientMiddleName: Yup.string()
                .trim()
                .nullable()
                .max(50, translate('Client middle name cannot be longer than 50 characters')),

            clientLastName: Yup.string()
                .trim()
                .nullable()
                .max(50, translate('Client last name cannot be longer than 50 characters')),

            notes: Yup.string()
                .trim()
                .max(200, translate('Notes cannot be longer than 200 characters'))
                .nullable()
                .test(
                    'no-url',
                    translate('Notes cannot contain a URL'),
                    function (value) {
                        const urlPattern = /(https?:\/\/[^\s]+)/g;
                        return !urlPattern.test(value || '');
                    }
                ),
        });
    }


    public static generateRecordInput = (values: recordFormValueType, branchId: number, selectedTechnologicalMaps: ReactSelectInterface[], bookingUuid: string): BookingAdminInput => {

        const client: ClientAdminBookingInput = {
            name: values.clientName,
            surname: values.clientMiddleName,
            patronymic: values.clientLastName,
            phone: values.phone ? values.phone : null,
            email: values.email ? values.email : null,
        }

        let techMap: number[] = []

        if (selectedTechnologicalMaps.length !== 0) {
            selectedTechnologicalMaps.forEach((techMapItem: ReactSelectInterface) => {
                techMap.push(techMapItem.value)
            })
        }

        return {
            uuid: bookingUuid ? bookingUuid : null,
            branch: branchId,
            service: values.service.serviceId,
            technologicalMaps: techMap,
            status: values.status.statusId,
            employee: values.employeeId,
            date: DateTime.fromJSDate(values.pickedDate).toFormat('yyyy-MM-dd'),
            time: values.startTime.toFormat('HH:mm:ss'),
            endTime: values.endTime.toFormat('HH:mm:ss'),
            price: values.price,
            client: client,
            notificationTime: values.notification.date,
            notes: values.notes
        }
    }

    public static submitRecordErrors = (error: ApolloError) => {
        let currentError: ValidationRecordError
        if (error.graphQLErrors) {
            error.graphQLErrors.forEach((graphQLError) => {
                if (graphQLError.extensions) {
                    if (graphQLError.extensions.validation) {
                        currentError = graphQLError.extensions.validation as ValidationRecordError
                    }
                }
            });
        }
        return currentError
    }

    public static generateInitialValuesFromBooking = (booking: BookingMainProxy): recordFormValueType => {
        const currentService: EmployeeServiceRelationProxy =
            booking.employee.employeeServiceRelations.find((service) => service.service.id === booking.service.id);
        const duration: string = currentService.duration;

        return {
            service: {
                categoryId: booking.service.serviceCategoryNew.id,
                serviceId: booking.service.id,
                duration: duration
            },
            technologicalMap: booking.technologicalMaps.map((technologicalMap) => {
                return {
                    label: technologicalMap.name,
                    value: technologicalMap.id,
                }
            }),
            employeeId: booking.employee.id,
            price: booking.price,
            pickedDate: new Date(booking.date),
            startTime: DateTime.fromFormat(booking.time, 'HH:mm:ss'),
            endTime: DateTime.fromFormat(booking.endTime, 'HH:mm:ss'),
            status: {isSelected: true, statusId: booking.status.id},
            phone: booking.client.phone ? booking.client.phone : '',
            email: booking.client.email,
            clientName: booking.client.name,
            clientMiddleName: booking.client.surname,
            clientLastName: booking.client.patronymic,
            notes: booking.notes ? booking.notes : '',
            notification: {isUserPick: true, date: booking.notificationTime}
        }
    }

    public static disableClientContacts = (recordUuid: string, statusId: number): boolean => {
        return recordUuid && (statusId === bookingStatusIds.CLIENT_HAS_NOT_ARRIVED || statusId === bookingStatusIds.BOOKING_WAS_CANCELED ||
            statusId === bookingStatusIds.COMPLETED || statusId === bookingStatusIds.COMPLETED_WITH_WAREHOUSE);
    }

    public static getEmployeeForSelect = (employeeId : number, employeeList: EmployeeBookingProxy[]) : ReactSelectInterface => {
        if (employeeId && employeeList.length !== 0) {
            const currentEmployee: EmployeeBookingProxy = employeeList.find((employee) => employee.id === employeeId);
            if (currentEmployee) {
                return {label: `${currentEmployee.surname} ${currentEmployee.name}`, value: employeeId}
            }
        }
        return null
    }

    public static checkTimesRangeInCurrentDate = (
        pickedDate: Date,
        bookingTimes: BookingTime[],
        selectedTime: DateTime
    ): boolean => {

        const selectedDateTime = DateTime.fromObject({
            year: pickedDate.getFullYear(),
            month: pickedDate.getMonth() + 1,
            day: pickedDate.getDate(),
            hour: selectedTime.hour,
            minute: selectedTime.minute,
            second: selectedTime.second,
        });

        return bookingTimes.some((timeRange) => {
            const startTime = DateTime.fromISO(`${pickedDate.toISOString().split('T')[0]}T${timeRange.start}`);
            const endTime = DateTime.fromISO(`${pickedDate.toISOString().split('T')[0]}T${timeRange.end}`);


            return selectedDateTime >= startTime && selectedDateTime <= endTime;
        });
    };


}