/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetBranchJsConfigListDocument = gql`
    query getBranchJSConfigList($companyId: Int!) {
  user {
    id
    company(id: $companyId) {
      id
      bookingQrLink
      isPremiumOn
      name
      phone
      address
      deleted
      branches {
        id
        name
        deleted
        address
        bookingQrLink
        currencyCode
        onlineRegistration
        meBookingConfig {
          jsConfig
        }
        removedOfTariff
        timeZone
        country {
          id
          name
          continentName
          isInEuropeanUnion
          isoCode
          localeCode
        }
      }
    }
  }
}
    `;

/**
 * __useGetBranchJsConfigListQuery__
 *
 * To run a query within a React component, call `useGetBranchJsConfigListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBranchJsConfigListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBranchJsConfigListQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetBranchJsConfigListQuery(baseOptions: Apollo.QueryHookOptions<GetBranchJsConfigListQuery, GetBranchJsConfigListQueryVariables> & ({ variables: GetBranchJsConfigListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBranchJsConfigListQuery, GetBranchJsConfigListQueryVariables>(GetBranchJsConfigListDocument, options);
      }
export function useGetBranchJsConfigListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBranchJsConfigListQuery, GetBranchJsConfigListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBranchJsConfigListQuery, GetBranchJsConfigListQueryVariables>(GetBranchJsConfigListDocument, options);
        }
export function useGetBranchJsConfigListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBranchJsConfigListQuery, GetBranchJsConfigListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBranchJsConfigListQuery, GetBranchJsConfigListQueryVariables>(GetBranchJsConfigListDocument, options);
        }
export type GetBranchJsConfigListQueryHookResult = ReturnType<typeof useGetBranchJsConfigListQuery>;
export type GetBranchJsConfigListLazyQueryHookResult = ReturnType<typeof useGetBranchJsConfigListLazyQuery>;
export type GetBranchJsConfigListSuspenseQueryHookResult = ReturnType<typeof useGetBranchJsConfigListSuspenseQuery>;
export type GetBranchJsConfigListQueryResult = Apollo.QueryResult<GetBranchJsConfigListQuery, GetBranchJsConfigListQueryVariables>;
export const UpdateBookingBranchConfigDocument = gql`
    mutation updateBookingBranchConfig($companyId: Int!, $configs: BranchMultipleConfigUpdateInput!) {
  updateBookingBranchConfig(companyId: $companyId, configs: $configs)
}
    `;
export type UpdateBookingBranchConfigMutationFn = Apollo.MutationFunction<UpdateBookingBranchConfigMutation, UpdateBookingBranchConfigMutationVariables>;

/**
 * __useUpdateBookingBranchConfigMutation__
 *
 * To run a mutation, you first call `useUpdateBookingBranchConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingBranchConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingBranchConfigMutation, { data, loading, error }] = useUpdateBookingBranchConfigMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      configs: // value for 'configs'
 *   },
 * });
 */
export function useUpdateBookingBranchConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookingBranchConfigMutation, UpdateBookingBranchConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookingBranchConfigMutation, UpdateBookingBranchConfigMutationVariables>(UpdateBookingBranchConfigDocument, options);
      }
export type UpdateBookingBranchConfigMutationHookResult = ReturnType<typeof useUpdateBookingBranchConfigMutation>;
export type UpdateBookingBranchConfigMutationResult = Apollo.MutationResult<UpdateBookingBranchConfigMutation>;
export type UpdateBookingBranchConfigMutationOptions = Apollo.BaseMutationOptions<UpdateBookingBranchConfigMutation, UpdateBookingBranchConfigMutationVariables>;
export type GetBranchJsConfigListQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetBranchJsConfigListQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, bookingQrLink: string, isPremiumOn: boolean, name: string, phone: string, address: string, deleted: boolean, branches?: Array<{ __typename?: 'BranchProxy', id: number, name: string, deleted: boolean, address: string, bookingQrLink: string, currencyCode: string, onlineRegistration: boolean, removedOfTariff: boolean, timeZone: string, meBookingConfig?: { __typename?: 'MeBookingBranchConfigProxy', jsConfig?: string | null } | null, country: { __typename?: 'Country', id: string, name: string, continentName: string, isInEuropeanUnion: boolean, isoCode: string, localeCode: string } }> | null } | null } | null };

export type UpdateBookingBranchConfigMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  configs: Types.BranchMultipleConfigUpdateInput;
}>;


export type UpdateBookingBranchConfigMutation = { __typename?: 'Mutation', updateBookingBranchConfig: boolean };
