import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {ApolloError, useReactiveVar} from '@apollo/client';
import {
  useCreateReviewMutation,
  useGetEmployeesForReviewsQuery
} from '@me-team/host/main/reviews/graphql/reviews.hooks';
import {useNavigate} from 'react-router-dom';
import TextInput from '../TextInput/TextInput';
import {ReactSelect, StarRatingChoice, TextareaInputWithCounter} from '../index';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import {createReviewFormValidationSchema} from './constants';
import ErrorService from '@me-team/common/src/services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';

interface FormValuesReview {
  name: string;
  text?: string;
  rating: number;
  employeeId: number;
}

interface CreateReviewModalProps {
  isModalOpened: boolean;
  setIsModalOpened: (isOpened: boolean) => void;
  onSuccess?: () => void;
  toggleShowToast?: (text: string) => void
}

interface Option {
  value: number | string | string[];
  label: string;
}

const CreateReviewModal: React.FC<CreateReviewModalProps> = ({
                                                               isModalOpened,
                                                               setIsModalOpened,
                                                               onSuccess,
                                                               toggleShowToast
                                                             }) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [selectedReviewEmployees, setSelectedReviewEmployees] = useState(null);

  const {data: employeesData, loading, error} = useGetEmployeesForReviewsQuery({
    variables: { companyId: +currentCompanyId },
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  })

  const employeesOptions = employeesData?.user?.company?.employees?.employees?.map((employee) => {
    return {
      value: employee.id,
      label: `${employee.name} ${employee.surname} (${employee.position.name})`
    }
  }) || [];

  const handleEmployeeChange = (selectedOption: Option, setFieldValue: any) => {
    setSelectedReviewEmployees(selectedOption)
    setFieldValue('employeeId', selectedOption?.value)
  };
  const handleModalClose = () => {
    setSelectedReviewEmployees(null); // Reset the selected employee when closing the modal window
    setIsModalOpened(false);
  };

  const [createReview] = useCreateReviewMutation();

  const initialValues: FormValuesReview = {
    name: '',
    text: '',
    rating: null,
    employeeId: null,
  }

  const handleReviewSubmit = async (values: FormValuesReview,
                                    {setSubmitting}: {
                                      setSubmitting: (isSubmitting: boolean) => void
                                    }) => {
    const employeeId = values?.employeeId
    const input = {
      name: values?.name,
      text: values?.text ? values?.text : '',
      rating: values?.rating
    }
    await createReview({
      variables: {employeeId, input, companyId: +currentCompanyId},
      context: {
        errorType: 'local'
      },
      onCompleted: (data) => {
        data && navigate(`/booking/review/company/${currentCompanyId}`);
        setSubmitting(false);
        onSuccess()
        setIsModalOpened(false)
        setSelectedReviewEmployees(null)
        toggleShowToast(t("Created"))
      },
      onError: (error: ApolloError) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error))
      },
    });
  }

  return (<>

      <Modal
        show={isModalOpened}
        size="lg"
        onHide={handleModalClose}
        centered>
        <Modal.Header
          closeButton
          className='border-0'>
          <Modal.Title className="h5">{t("Review modal and table.Create new Review")}</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={createReviewFormValidationSchema(t)}
          onSubmit={handleReviewSubmit}
        >
          {({isSubmitting, setFieldValue}) => (
            <Form>
              <Modal.Body className='text-center mb-3 pb-0 ps-4'>
                <Row className="align-items-center mt-3 pe-3">
                  <Col sm={3} className='text-start'>
                    <label htmlFor="employeeId">
                      <span
                        className="fs-7 fw-bold text-start">{t("Review modal and table.Employee")}</span>
                      <span className="text-danger ms-1">*</span>
                    </label>
                  </Col>
                  <Col sm={9} className='text-start'>
                    <ReactSelect
                      name='employeeId'
                      id='addReview-employeeId-field'
                      value={selectedReviewEmployees}
                      options={employeesOptions}
                      placeholder={t('Review modal and table.Select a Master')}
                      onChange={(selectedOption) => handleEmployeeChange(selectedOption, setFieldValue)}
                      isSearchable={true}
                    />
                    <ErrorMessage name='employeeId'>
                      {msg => <div className='invalid-feedback d-block'>{msg}</div>}
                    </ErrorMessage>
                  </Col>
                </Row>
                <Row className="mt-3 pe-3">
                  <Col sm={3} className='text-start'>
                    <label htmlFor="addReview-rating-field">
                      <span className="fs-7 fw-bold text-start">{t("Review modal and" +
                        " table.Rating")}</span>
                      <span className="text-danger ms-1">*</span>
                    </label>
                  </Col>
                  <Col sm={9} className="text-start">
                    <Field name='rating' component={StarRatingChoice}/>
                    <ErrorMessage name='rating'>
                      {msg => <div className='invalid-feedback d-block'>{msg}</div>}
                    </ErrorMessage>
                  </Col>
                </Row>
                <div className="form-group">
                  <Row className="mt-3 pe-3">
                    <TextInput
                      name='name'
                      id='addReview-name-field'
                      labelCol={3}
                      inputCol={9}
                      placeholder={t('Add your name')}
                      label={<span>{t('Review modal and table.Client name')}&nbsp;<span
                        className='text-danger'>*</span></span>}
                      maxLength={50}
                    />
                  </Row>
                </div>
                <Row className="mt-3 pe-3">
                  <TextareaInputWithCounter
                    name='text'
                    id='addReview-text-field'
                    maxLength={200}
                    labelCol={3}
                    inputCol={9}
                    placeholder={t('Review modal and table.Add Review')}
                    label={t('Review modal and table.Text')}
                  />
                </Row>
              </Modal.Body>
              <Modal.Footer className='border-0'>
                <Row className="w-100 justify-content-end m-0 ms-4 ps-2">
                  <Col xs={6} lg={3} className='px-1 ps-0'>
                    <Button
                      onClick={handleModalClose}
                      variant="outline-primary" type="submit" disabled={isSubmitting}
                      className="w-100 fw-normal"
                    >
                      {t("Cancel")}
                    </Button>
                  </Col>
                  <Col xs={6} lg={3} className='px-1 pe-0'>
                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100"
                    >
                      {t("Add")}
                    </Button>
                  </Col>
                </Row>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  )
}

export default CreateReviewModal;
