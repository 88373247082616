import React, {useRef, useState} from 'react';
import {DateTime, Interval} from 'luxon';
import {TimePicker} from "@me-pos/time-picker";
import i18next from 'i18next';
import {ErrorMessage, useField, useFormikContext} from 'formik';
import {Col} from 'react-bootstrap';

interface DurationInputProps {
  name: string;
  label: string;
  prevValue?: string;
  id: string;
}

const DurationInput: React.FC<DurationInputProps> = ({
                                                       name,
                                                       label,
                                                       prevValue,
                                                       id
}) => {
  const {setFieldValue} = useFormikContext();
  const timePickerDivRef = useRef<HTMLDivElement>(null);
  const [field, meta] = useField(name);

  const interval = 15
  const startDateTime = DateTime.now().set({hour: 0, minute: 15});
  const endDateTime = DateTime.now().set({hour: 23, minute: 59});

  const inactiveIntervals = [
    Interval.fromDateTimes(
      DateTime.fromObject({hour: 0, minute: 0}),
      DateTime.fromObject({hour: 0, minute: 15})
    )
  ];

  const startValue = prevValue ? prevValue : ""
  const [value, setValue] = useState(startValue);

  const handleChange = async (newDateTime: DateTime) => {
    const formattedTime = newDateTime.toFormat('HH:mm');
    try {
      await setFieldValue(name, formattedTime);
    } catch (error) {
      console.error('Error setting value:', error);
    }
  };

  return (
    <>
      <Col xs={2}
           className='mb-2 mb-md-0'>
        <label
          htmlFor={id}
          className='text-dark fs-7 fw-bold pt-3'
          onClick={() => timePickerDivRef.current?.focus()}
        >
          {label}&nbsp;<span className='text-danger'>*</span>
        </label>
      </Col>
      <Col sm={12} md={3}>
        <div
          className={`time-picker-wrapper position-relative form-control p-0 ${meta.touched && meta.error ? 'is-invalid' : ''}`}
          id={id}
          ref={timePickerDivRef}
          tabIndex={-1}
        >
          <TimePicker
            i18n={i18next}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
            inactiveIntervals={inactiveIntervals}
            stepInMinutes={interval}
            onChange={handleChange}
            value={value}
            timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
            inputName="duration"
            isDisabled={false}
            isRequired={false}
          />
          {meta.touched && meta.error &&
            <i
              className="bi bi-exclamation-circle fs-20 position-absolute top-50 end-0 translate-middle-y text-danger pe-2 me-1 fw-bold"
            >
            </i>
          }
        </div>
        <ErrorMessage name={name}>
          {msg => <div className='text-danger fs-7 mt-1'>{msg}</div>}
        </ErrorMessage>
      </Col>
    </>
  );
};

export default DurationInput;