import React, {useEffect, useRef, useState} from "react";
import { useReactiveVar } from "@apollo/client";
import { currentBranchIdVar, currentCompanyIdVar } from "@me-team/host/src/apollo/globalVar/state";
import { useGetInfoCompanyQuery } from "@me-team/host/main/company/graphql/company.hooks";
import { ScheduleCalendarWrapperComponent } from "./ScheduleCalendarWrapperComponent";
import VioletBadge from "@services/ui-components/src/VioletBadge/VioletBadge";
import {EmptyList, FiltersButton, ReactSelect, RenderEmptyList, TableHeader} from "@services/ui-components";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useGetEmployeesPositionsQuery } from "@me-team/host/main/addEmployee/graphql/addEmployee.hooks";
import Spinner from "@services/ui-components/src/Spinner/Spinner"
import {useLocation, useParams} from "react-router-dom";
import AddVacationModal from "./modals/AddVacationModal/AddVacationModal";
import CustomDayModal from "./modals/CustomDayModal/CustomDayModal";
import JoyrideWrapper from "../Joyride/JoyrideWrapper";
import {useGetEmployeeForCheckEmptyListQuery} from "@me-team/host/main/work-shedule/graphql/workShedule.hooks";
import ToastComponent from "../ui-components/ToastComponent/ToastComponent";
import {useToast} from "../../hooks/useToast";

interface Option {
    value: any;
    label: string;
}
interface JoyrideStepEventDetail {
    direction: 'back' | 'next' | 'other';
}
interface WeekCellClickEventDetail {
    date: string;
    resource: string;
}
type JoyrideStepEvent = CustomEvent<JoyrideStepEventDetail>;
type WeekCellClickEvent = CustomEvent<WeekCellClickEventDetail>;


const WorkSchedule: React.FC = () => {
    const basePath = process.env.REACT_APP_POS_URL;
    const { t } = useTranslation();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const { id } = useParams();
    const location = useLocation();
    const [showFilters, setShowFilters] = useState(false);
    const [showPreloader, setShowPreloader] = useState<boolean>(false);
    const [filter, setFilter] = useState({ positionId: null, employeeId: id ?  +id : null, })
    const [currentFilter, setCurrentFilter] = useState({ positionId: null,  employeeId: null})
    const runJoy = localStorage.getItem('run') === 'true';
    const step = localStorage.getItem('step') || '0';
    const [stepIndex, setStepIndex] = useState(+step);
    const [run, setRun] = useState(runJoy);
    const [modalJoyride, setModalJoyride] = useState(false);
    const [modalData, setModalData] = useState({ show: false, employeeId: null, edit: null });
    const [editDate, setEditDate] = useState({startDate: null})
    const [customModalData, setCustomModalData] = useState({ show: false, date: null, resource: null });
    const [eventTrigger, setEventTrigger] = useState(100);
    const [scheduleTrigger, setScheduleTrigger] = useState(100);
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [ keySchedule, setKeySchedule] = useState(0)
    const { data: possitions, loading: loadingPossitions } = useGetEmployeesPositionsQuery({
        variables: { companyId: +currentCompanyId },
        skip: !currentCompanyId,
    });
    const { data: infoCompanyData, loading: loadingInfoCompanyData, refetch } = useGetInfoCompanyQuery({
        variables: { companyId: +currentCompanyId },
        skip: !currentCompanyId,
    });
    const { showToast, toastText, setShowToast, toggleShowToast } = useToast();
    const {
        data: employeesForCheckEmptyList,
        loading: loadingEmployeesForCheckEmptyList,
    } = useGetEmployeeForCheckEmptyListQuery({
        variables: {
            id: filter?.employeeId,
            positionId: filter?.positionId,
            companyId: +currentCompanyId,
        },
        skip: !currentCompanyId,
        context: {
            errorType: 'global',
        },
    });
    const filteredEmployees = employeesForCheckEmptyList?.user?.company?.employees?.employees?.filter(employee => employee.status.id !== 4);

    const employeesOptions = (employeesForCheckEmptyList?.user?.company?.employees?.employees || [])
        .map((employee) => ({
            value: employee.id,
            label: `${employee.name} ${employee.surname} (${employee.position.name})`,
            surname: employee.surname,
        }))
        .sort((a, b) => a.surname.localeCompare(b.surname));


    const timeZone = infoCompanyData?.user?.company?.timeZone?.name;

    // Handle custom event inside modal
    useEffect(() => {
        if (!runJoy) return
        const handleJoyrideStep = (event: JoyrideStepEvent) => {
            const { direction} = event.detail;
            setEventTrigger((prev) => prev + 1);
            setStepIndex((prevIndex) => {
                if (direction === 'back') {
                    return prevIndex - 1;
                } else if (direction === 'next') {
                    setKeySchedule(prev => ++prev)
                    return 10;
                } else {
                    return prevIndex;
                }
            });
            setEventTrigger((prev) => prev + 1);
        };
        window.addEventListener('joyrideStepHandler', handleJoyrideStep);
        return () => {
            window.removeEventListener('joyrideStepHandler', handleJoyrideStep);
        };
    }, []);

    // Event joyride Open Modal
    useEffect(() => {
        const handleJoyrideOpenModal = (event: JoyrideStepEvent) => {
            setStepIndex(8);
            setEventTrigger((prev) => prev + 1)
            setScheduleTrigger((prev) => prev + 1)
        };
        window.addEventListener('joyrideOpenModal', handleJoyrideOpenModal);
        return () => {
            window.removeEventListener('joyrideOpenModal', handleJoyrideOpenModal);
        };
    }, [eventTrigger]);
    useEffect(() => {
        const handleMutations = (mutationsList: any) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    for (const node of mutation.addedNodes) {
                        if (node instanceof HTMLElement && node.classList.contains('work-schedule-modal')) {
                            setEventTrigger(prev => prev + 1);
                        }
                    }
                    for (const node of mutation.removedNodes) {
                        if (node instanceof HTMLElement && node.classList.contains('work-schedule-modal')) {
                            setEventTrigger(prev => prev + 1);
                        }
                    }
                }
            }
        };
        const observer = new MutationObserver(handleMutations)
        observer.observe(document.body, {
            childList: true,
            subtree: true,
        })
        const initialElement = document.querySelector('.work-schedule-modal');
        if (initialElement) {
            setEventTrigger(prev => prev + 1);
        }
        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const element = document.querySelector('.work-schedule-step');
            if (element) {
                setEventTrigger(prev => prev + 1);
                clearInterval(intervalId);
            }
        }, 0)

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleClick = (event) => {
        const target = event.target.closest('[data-action="click->ajax-modal-get-data#loadModalContent"]');
        if (target) {
            event.preventDefault();
            const url = target.getAttribute('data-url');
            const employeeId = extractEmployeeIdFromUrl(url);
            setModalData({ show: true, employeeId: employeeId, edit: false });
        }
    };

    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === "childList") {
                    const modalElement = document.getElementById("sheduleModal");
                    if (modalElement) {
                        modalElement.style.overflowY = "scroll";
                    }
                }
            });
        });
        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
        return () => {
            observer.disconnect();
        };
    }, []);


    const handleWeekCellClick = (event) => {
        const { date, resource } = event.detail;
        setCustomModalData({ show: true, date, resource });
    };
    const handleCustomDay = (event) => {
        const date = event.detail.event.startDate
        const resource = event.detail.event.responseObject
        if (resource.color === "#EEECF9") {
            setEditDate({
                startDate: event.detail.event.responseObject.start.substring(0, 10)
            })
            setModalData({ show: true, employeeId: event.detail.event.resourceId, edit: true });
            handleClick(event)
        } else {setCustomModalData({ show: true, date, resource });}

    }
    // editCustomDayModal
    useEffect(() => {
        document.addEventListener('click', handleClick);

        window.addEventListener('weekCellClick', handleWeekCellClick);
        window.addEventListener('editCustomDayModal', handleCustomDay);
        return () => {
            document.removeEventListener('click', handleClick);
            window.removeEventListener('weekCellClick', handleWeekCellClick);
            window.removeEventListener('editCustomDayModal', handleCustomDay);
        };
    }, []);

    const observerRef = useRef<MutationObserver | null>(null);

    useEffect(() => {
        const handleMutation = (mutationsList: MutationRecord[]) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    const element = document.getElementById('sheduleModalContainer');
                    if (element) {
                        setRun(false)
                        element.classList.add('work-schedule-modal');
                        setTimeout(()=> {
                            setRun(true)
                            setStepIndex(8)
                            setEventTrigger(prev => prev + 1)
                        } , 505)

                        if (observerRef.current) {
                            observerRef.current.disconnect();
                        }
                    }
                }
            }
        };
        observerRef.current = new MutationObserver(handleMutation);
        observerRef.current.observe(document.body, {
            childList: true,
            subtree: true,
        });
        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [scheduleTrigger]);

    const secondComponent = React.useMemo(
        () => (
            <ScheduleCalendarWrapperComponent
                branchId={+currentBranchId}
                filters={filter}
                timeZone={timeZone}
                showPreloaderHandler={showPreloaderHandler}
                hidePreloaderHandler={hidePreloaderHandler}
                companyId={+currentCompanyId}
            />
        ),
        [currentBranchId, timeZone, filter,  JSON.stringify(filter),  currentCompanyId, refreshTrigger]
    );
    const forceRefresh = () => {
        setRefreshTrigger((prev) => prev + 1);
    };

    const positionsOptions = possitions?.user?.company?.employeePositions?.employeePositions.map((position) => ({
        value: position.id,
        label: position.name,
    }));
    const handleClose = () => {
        setModalData({ show: false, employeeId: null, edit: null });
    };

    const handleCustomModalClose = () => {
        setCustomModalData({ show: false, date: null, resource: null });
    };

    const toggleFilters = () => setShowFilters(!showFilters);
    const showPreloaderHandler = () => {
        setShowPreloader(true);
    };
    const hidePreloaderHandler = () => {
        setShowPreloader(false);
    };
    const handlePositionChange = (selectedOption: Option) => {
        setCurrentFilter((prevFilter) => ({
            ...prevFilter,
            positionId: selectedOption?.value,
        }));
    };

    const handleEmployeesChange = (selectedOption: Option) => {
        setCurrentFilter((prevFilter) => ({
            ...prevFilter,
            employeeId: selectedOption?.value
        }));
    };

    const applyFilters = () => {
        const newFilter = {
            positionId: currentFilter.positionId,
            employeeId: currentFilter.employeeId,
        }
        if (JSON.stringify(newFilter) !== JSON.stringify(filter)) {
            setFilter(newFilter);
        }
    };

    const clearFilters = () => {
        const defaultFilter: { positionId: number | null; employeeId: number | null } = {
            positionId: null,
            employeeId: null
        };
        if (JSON.stringify(filter) !== JSON.stringify(defaultFilter)) {
            setFilter(defaultFilter);
        }
        setCurrentFilter(defaultFilter);
    }

    if (loadingPossitions || loadingInfoCompanyData  || loadingEmployeesForCheckEmptyList || !currentBranchId || !currentCompanyId) return <Spinner />;

    return (
        <>
            {modalData.show && (
                <AddVacationModal
                    show={modalData.show}
                    handleClose={handleClose}
                    employeeId={modalData.employeeId}
                    forceRefresh={forceRefresh}
                    toggleShowToast={toggleShowToast}
                    edit={modalData.edit}
                    editDate={editDate}
                />
            )}

            <div className="px-6">
                <VioletBadge
                    text={t(
                        'On this page, you can set the work schedule of employees for a certain period or day, and create vacations.'
                    )}
                />
                <TableHeader
                    title={t('Staff Work Schedule')}
                    paths={[
                        {path: location?.pathname, label: t('Personnel management')},
                        {path: location?.pathname, label: t('Work schedule')},
                    ]}
                    buttons={
                        <Row className="justify-content-end mt-4 mt-lg-0">
                            <Col xs={12} sm={6} lg xxl={9}>
                                <FiltersButton filter={filter} showFilters={showFilters} onClick={toggleFilters}/>
                            </Col>
                        </Row>
                    }
                    showFilters={showFilters}
                    colSize={10}
                    btnColSize={2}
                >
                    <Row className="gx-3 mb-3">
                        <Col lg={8} xl={9}>
                            <Row className="gx-3">
                                <Col xs={12} sm={6} className="text-primary mb-3 mb-lg-0">
                                    <ReactSelect
                                        name={"serviceProviders-selectPosition-field"}
                                        id={"serviceProviders-selectPosition-field"}
                                        options={positionsOptions}
                                        placeholder={t('Select Position')}
                                        onChange={handlePositionChange}
                                        value={currentFilter.positionId ? positionsOptions.find(option => option.value === currentFilter.positionId) : null}
                                        isSearchable={true}
                                    />
                                </Col>
                                <Col xs={12} sm={6} className="text-primary mb-3 mb-lg-0">
                                    <ReactSelect
                                        name='employeeId'
                                        id='reviews-employeeId-field'
                                        value={+currentFilter.employeeId ? employeesOptions.find(option => option.value === +currentFilter.employeeId) : null}
                                        options={employeesOptions}
                                        placeholder={t('Employee')}
                                        onChange={handleEmployeesChange}
                                        isSearchable={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} xl={3}>
                            <Row className="gx-3 h-100">
                                <Col xs={6}>
                                    <Button
                                        variant="outline-dark"
                                        className=" w-100 fw-normal h-100"
                                        onClick={clearFilters}
                                    >
                                        {t('clear')}
                                    </Button>
                                </Col>
                                <Col xs={6}>
                                    <Button
                                        variant="primary"
                                        className="w-100 h-100 px-1"
                                        onClick={applyFilters}
                                    >
                                        {t('apply')}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </TableHeader>
            </div>
            <div key={keySchedule}>
                {
                    filteredEmployees?.length === 0 ? <EmptyList title={'No data found for the given values'}/>
                        : secondComponent
                }
            </div>
            {customModalData  &&
                < CustomDayModal
                    show={customModalData.show}
                    toggleModal={handleCustomModalClose}
                    date={customModalData?.date?.c}
                    resource={customModalData.resource}
                    forceRefresh={() => setKeySchedule(prev => ++prev)}
                    toggleShowToast={toggleShowToast}
                />
            }
            {eventTrigger && run && runJoy &&
                <JoyrideWrapper run={run} stepIndex={stepIndex} setStepIndex={setStepIndex} setRun={setRun}
                                setModalJoyride={setModalJoyride}/>}
            {showToast && <ToastComponent show={showToast} setShow={setShowToast} text={toastText}/>}
        </>
    );
};

export default WorkSchedule;

function extractEmployeeIdFromUrl(url: string): number | null {
    const match = url.match(/\/ajax\/schedule\/(?:new\/custom\/day|create)\/(\d+)/);
    return match ? parseInt(match[1], 10) : null;
}
