/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetModuleListForRecordDocument = gql`
    query getModuleListForRecord($companyId: Int!) {
  user {
    company(id: $companyId) {
      id
      name
      deleted
      phone
      address
      bookingQrLink
      currency {
        id
        currencySign
      }
      modules {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetModuleListForRecordQuery__
 *
 * To run a query within a React component, call `useGetModuleListForRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleListForRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleListForRecordQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetModuleListForRecordQuery(baseOptions: Apollo.QueryHookOptions<GetModuleListForRecordQuery, GetModuleListForRecordQueryVariables> & ({ variables: GetModuleListForRecordQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModuleListForRecordQuery, GetModuleListForRecordQueryVariables>(GetModuleListForRecordDocument, options);
      }
export function useGetModuleListForRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModuleListForRecordQuery, GetModuleListForRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModuleListForRecordQuery, GetModuleListForRecordQueryVariables>(GetModuleListForRecordDocument, options);
        }
export function useGetModuleListForRecordSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetModuleListForRecordQuery, GetModuleListForRecordQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetModuleListForRecordQuery, GetModuleListForRecordQueryVariables>(GetModuleListForRecordDocument, options);
        }
export type GetModuleListForRecordQueryHookResult = ReturnType<typeof useGetModuleListForRecordQuery>;
export type GetModuleListForRecordLazyQueryHookResult = ReturnType<typeof useGetModuleListForRecordLazyQuery>;
export type GetModuleListForRecordSuspenseQueryHookResult = ReturnType<typeof useGetModuleListForRecordSuspenseQuery>;
export type GetModuleListForRecordQueryResult = Apollo.QueryResult<GetModuleListForRecordQuery, GetModuleListForRecordQueryVariables>;
export const GetTechnologicalMapsServiceDocument = gql`
    query getTechnologicalMapsService($serviceId: Int, $companyId: Int!) {
  user {
    company(id: $companyId) {
      id
      name
      deleted
      phone
      address
      bookingQrLink
      technologicalMaps(serviceId: $serviceId) {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetTechnologicalMapsServiceQuery__
 *
 * To run a query within a React component, call `useGetTechnologicalMapsServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTechnologicalMapsServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTechnologicalMapsServiceQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetTechnologicalMapsServiceQuery(baseOptions: Apollo.QueryHookOptions<GetTechnologicalMapsServiceQuery, GetTechnologicalMapsServiceQueryVariables> & ({ variables: GetTechnologicalMapsServiceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTechnologicalMapsServiceQuery, GetTechnologicalMapsServiceQueryVariables>(GetTechnologicalMapsServiceDocument, options);
      }
export function useGetTechnologicalMapsServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTechnologicalMapsServiceQuery, GetTechnologicalMapsServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTechnologicalMapsServiceQuery, GetTechnologicalMapsServiceQueryVariables>(GetTechnologicalMapsServiceDocument, options);
        }
export function useGetTechnologicalMapsServiceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTechnologicalMapsServiceQuery, GetTechnologicalMapsServiceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTechnologicalMapsServiceQuery, GetTechnologicalMapsServiceQueryVariables>(GetTechnologicalMapsServiceDocument, options);
        }
export type GetTechnologicalMapsServiceQueryHookResult = ReturnType<typeof useGetTechnologicalMapsServiceQuery>;
export type GetTechnologicalMapsServiceLazyQueryHookResult = ReturnType<typeof useGetTechnologicalMapsServiceLazyQuery>;
export type GetTechnologicalMapsServiceSuspenseQueryHookResult = ReturnType<typeof useGetTechnologicalMapsServiceSuspenseQuery>;
export type GetTechnologicalMapsServiceQueryResult = Apollo.QueryResult<GetTechnologicalMapsServiceQuery, GetTechnologicalMapsServiceQueryVariables>;
export type GetModuleListForRecordQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetModuleListForRecordQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', id: number, name: string, deleted: boolean, phone: string, address: string, bookingQrLink: string, currency?: { __typename?: 'CurrencyProxy', id: number, currencySign?: string | null } | null, modules?: Array<{ __typename?: 'ModuleProxy', id: number, name?: string | null }> | null } | null } | null };

export type GetTechnologicalMapsServiceQueryVariables = Types.Exact<{
  serviceId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetTechnologicalMapsServiceQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', id: number, name: string, deleted: boolean, phone: string, address: string, bookingQrLink: string, technologicalMaps?: Array<{ __typename?: 'TechnologicalMapProxy', id: number, name: string }> | null } | null } | null };
