/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetAllServicesDocument = gql`
    query GetAllServices($page: Int, $itemsPerPage: Int, $name: String, $categoryId: Int, $id: Int, $companyId: Int!, $employeeRelationsDeleted: Boolean) {
  user {
    id
    company(id: $companyId) {
      id
      services(
        page: $page
        name: $name
        itemsPerPage: $itemsPerPage
        categoryId: $categoryId
        id: $id
      ) {
        currentPage
        itemsPerPage
        pageCount
        totalCount
        services {
          id
          deleted
          image
          name
          description
          price
          onlineRegistration
          serviceCategoryNew {
            id
            name
            deleted
            parentCategory {
              id
              name
              deleted
            }
          }
          serviceCategory {
            id
            name
            deleted
          }
          serviceSubcategory {
            id
            name
            deleted
            serviceCategory {
              id
              deleted
              name
            }
          }
          employeeServiceRelations(deleted: $employeeRelationsDeleted) {
            id
            online
            deleted
          }
          technologicalMapServiceRelations {
            id
            connect
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllServicesQuery__
 *
 * To run a query within a React component, call `useGetAllServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllServicesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      name: // value for 'name'
 *      categoryId: // value for 'categoryId'
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      employeeRelationsDeleted: // value for 'employeeRelationsDeleted'
 *   },
 * });
 */
export function useGetAllServicesQuery(baseOptions: Apollo.QueryHookOptions<GetAllServicesQuery, GetAllServicesQueryVariables> & ({ variables: GetAllServicesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllServicesQuery, GetAllServicesQueryVariables>(GetAllServicesDocument, options);
      }
export function useGetAllServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllServicesQuery, GetAllServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllServicesQuery, GetAllServicesQueryVariables>(GetAllServicesDocument, options);
        }
export function useGetAllServicesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllServicesQuery, GetAllServicesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllServicesQuery, GetAllServicesQueryVariables>(GetAllServicesDocument, options);
        }
export type GetAllServicesQueryHookResult = ReturnType<typeof useGetAllServicesQuery>;
export type GetAllServicesLazyQueryHookResult = ReturnType<typeof useGetAllServicesLazyQuery>;
export type GetAllServicesSuspenseQueryHookResult = ReturnType<typeof useGetAllServicesSuspenseQuery>;
export type GetAllServicesQueryResult = Apollo.QueryResult<GetAllServicesQuery, GetAllServicesQueryVariables>;
export const GetCategoryDocument = gql`
    query GetCategory($companyId: Int!) {
  user {
    id
    company(id: $companyId) {
      id
      serviceCategoriesNew {
        id
        name
        deleted
        parentCategory {
          id
          name
          deleted
        }
        childCategories {
          id
          name
          deleted
        }
      }
    }
  }
}
    `;

/**
 * __useGetCategoryQuery__
 *
 * To run a query within a React component, call `useGetCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryQuery, GetCategoryQueryVariables> & ({ variables: GetCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryQuery, GetCategoryQueryVariables>(GetCategoryDocument, options);
      }
export function useGetCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryQuery, GetCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryQuery, GetCategoryQueryVariables>(GetCategoryDocument, options);
        }
export function useGetCategorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCategoryQuery, GetCategoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCategoryQuery, GetCategoryQueryVariables>(GetCategoryDocument, options);
        }
export type GetCategoryQueryHookResult = ReturnType<typeof useGetCategoryQuery>;
export type GetCategoryLazyQueryHookResult = ReturnType<typeof useGetCategoryLazyQuery>;
export type GetCategorySuspenseQueryHookResult = ReturnType<typeof useGetCategorySuspenseQuery>;
export type GetCategoryQueryResult = Apollo.QueryResult<GetCategoryQuery, GetCategoryQueryVariables>;
export const GetOneServiceFromServicesListDocument = gql`
    query GetOneServiceFromServicesList($employeeId: Int, $name: String, $categoryId: Int, $deleted: Boolean, $companyId: Int!) {
  user {
    id
    company(id: $companyId) {
      id
      servicesList(
        employeeId: $employeeId
        name: $name
        categoryId: $categoryId
        deleted: $deleted
      ) {
        id
        name
        image
        deleted
        description
        onlineRegistration
        duration
        price
        company {
          id
          address
          bookingQrLink
          deleted
          isPremiumOn
          name
          paidFeaturesPaginated {
            totalCount
          }
          phone
        }
        employeeServiceRelations {
          id
          online
          deleted
        }
        technologicalMapServiceRelations {
          id
          connect
        }
        serviceCategoryNew {
          id
          name
          deleted
          parentCategory {
            name
            id
            deleted
          }
        }
        bookings {
          totalCount
          bookings {
            id
            date
            service {
              id
              name
              deleted
              onlineRegistration
            }
            uuid
            needClientRecall
          }
        }
      }
      deleted
      name
      phone
      address
      bookingQrLink
    }
  }
}
    `;

/**
 * __useGetOneServiceFromServicesListQuery__
 *
 * To run a query within a React component, call `useGetOneServiceFromServicesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneServiceFromServicesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneServiceFromServicesListQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      name: // value for 'name'
 *      categoryId: // value for 'categoryId'
 *      deleted: // value for 'deleted'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetOneServiceFromServicesListQuery(baseOptions: Apollo.QueryHookOptions<GetOneServiceFromServicesListQuery, GetOneServiceFromServicesListQueryVariables> & ({ variables: GetOneServiceFromServicesListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneServiceFromServicesListQuery, GetOneServiceFromServicesListQueryVariables>(GetOneServiceFromServicesListDocument, options);
      }
export function useGetOneServiceFromServicesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneServiceFromServicesListQuery, GetOneServiceFromServicesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneServiceFromServicesListQuery, GetOneServiceFromServicesListQueryVariables>(GetOneServiceFromServicesListDocument, options);
        }
export function useGetOneServiceFromServicesListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOneServiceFromServicesListQuery, GetOneServiceFromServicesListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOneServiceFromServicesListQuery, GetOneServiceFromServicesListQueryVariables>(GetOneServiceFromServicesListDocument, options);
        }
export type GetOneServiceFromServicesListQueryHookResult = ReturnType<typeof useGetOneServiceFromServicesListQuery>;
export type GetOneServiceFromServicesListLazyQueryHookResult = ReturnType<typeof useGetOneServiceFromServicesListLazyQuery>;
export type GetOneServiceFromServicesListSuspenseQueryHookResult = ReturnType<typeof useGetOneServiceFromServicesListSuspenseQuery>;
export type GetOneServiceFromServicesListQueryResult = Apollo.QueryResult<GetOneServiceFromServicesListQuery, GetOneServiceFromServicesListQueryVariables>;
export const GetOneServiceDocument = gql`
    query GetOneService($id: Int, $companyId: Int!) {
  user {
    company(id: $companyId) {
      currency {
        currency
        code
        currencySign
      }
      services(id: $id) {
        services {
          serviceCategoryNew {
            id
            name
            deleted
            parentCategory {
              id
              name
              deleted
            }
          }
          employeeServiceRelations {
            id
          }
          company {
            id
          }
          name
          serviceCategory {
            id
            name
          }
          serviceSubcategory {
            id
            name
          }
          duration
          price
          onlineRegistration
          description
          id
          name
          deleted
          image
          bookings {
            totalCount
            bookings {
              service {
                id
              }
              date
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetOneServiceQuery__
 *
 * To run a query within a React component, call `useGetOneServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneServiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetOneServiceQuery(baseOptions: Apollo.QueryHookOptions<GetOneServiceQuery, GetOneServiceQueryVariables> & ({ variables: GetOneServiceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneServiceQuery, GetOneServiceQueryVariables>(GetOneServiceDocument, options);
      }
export function useGetOneServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneServiceQuery, GetOneServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneServiceQuery, GetOneServiceQueryVariables>(GetOneServiceDocument, options);
        }
export function useGetOneServiceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOneServiceQuery, GetOneServiceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOneServiceQuery, GetOneServiceQueryVariables>(GetOneServiceDocument, options);
        }
export type GetOneServiceQueryHookResult = ReturnType<typeof useGetOneServiceQuery>;
export type GetOneServiceLazyQueryHookResult = ReturnType<typeof useGetOneServiceLazyQuery>;
export type GetOneServiceSuspenseQueryHookResult = ReturnType<typeof useGetOneServiceSuspenseQuery>;
export type GetOneServiceQueryResult = Apollo.QueryResult<GetOneServiceQuery, GetOneServiceQueryVariables>;
export const GetCurrencyDocument = gql`
    query GetCurrency($companyId: Int!) {
  user {
    company(id: $companyId) {
      currency {
        currency
        code
        currencySign
      }
    }
  }
}
    `;

/**
 * __useGetCurrencyQuery__
 *
 * To run a query within a React component, call `useGetCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrencyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCurrencyQuery(baseOptions: Apollo.QueryHookOptions<GetCurrencyQuery, GetCurrencyQueryVariables> & ({ variables: GetCurrencyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrencyQuery, GetCurrencyQueryVariables>(GetCurrencyDocument, options);
      }
export function useGetCurrencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrencyQuery, GetCurrencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrencyQuery, GetCurrencyQueryVariables>(GetCurrencyDocument, options);
        }
export function useGetCurrencySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCurrencyQuery, GetCurrencyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrencyQuery, GetCurrencyQueryVariables>(GetCurrencyDocument, options);
        }
export type GetCurrencyQueryHookResult = ReturnType<typeof useGetCurrencyQuery>;
export type GetCurrencyLazyQueryHookResult = ReturnType<typeof useGetCurrencyLazyQuery>;
export type GetCurrencySuspenseQueryHookResult = ReturnType<typeof useGetCurrencySuspenseQuery>;
export type GetCurrencyQueryResult = Apollo.QueryResult<GetCurrencyQuery, GetCurrencyQueryVariables>;
export const GetModulesDocument = gql`
    query GetModules($companyId: Int!) {
  user {
    company(id: $companyId) {
      modules {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetModulesQuery__
 *
 * To run a query within a React component, call `useGetModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetModulesQuery(baseOptions: Apollo.QueryHookOptions<GetModulesQuery, GetModulesQueryVariables> & ({ variables: GetModulesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModulesQuery, GetModulesQueryVariables>(GetModulesDocument, options);
      }
export function useGetModulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModulesQuery, GetModulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModulesQuery, GetModulesQueryVariables>(GetModulesDocument, options);
        }
export function useGetModulesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetModulesQuery, GetModulesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetModulesQuery, GetModulesQueryVariables>(GetModulesDocument, options);
        }
export type GetModulesQueryHookResult = ReturnType<typeof useGetModulesQuery>;
export type GetModulesLazyQueryHookResult = ReturnType<typeof useGetModulesLazyQuery>;
export type GetModulesSuspenseQueryHookResult = ReturnType<typeof useGetModulesSuspenseQuery>;
export type GetModulesQueryResult = Apollo.QueryResult<GetModulesQuery, GetModulesQueryVariables>;
export const CreateServiceDocument = gql`
    mutation CreateService($companyId: Int!, $input: ServiceInput!) {
  createService(companyId: $companyId, input: $input) {
    name
    company {
      id
    }
    id
    deleted
    description
    duration
    image
    onlineRegistration
    price
    serviceCategoryNew {
      id
      name
      deleted
      parentCategory {
        id
        name
        deleted
      }
    }
    serviceCategory {
      id
      name
    }
    serviceSubcategory {
      id
      name
    }
  }
}
    `;
export type CreateServiceMutationFn = Apollo.MutationFunction<CreateServiceMutation, CreateServiceMutationVariables>;

/**
 * __useCreateServiceMutation__
 *
 * To run a mutation, you first call `useCreateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceMutation, { data, loading, error }] = useCreateServiceMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceMutation, CreateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceMutation, CreateServiceMutationVariables>(CreateServiceDocument, options);
      }
export type CreateServiceMutationHookResult = ReturnType<typeof useCreateServiceMutation>;
export type CreateServiceMutationResult = Apollo.MutationResult<CreateServiceMutation>;
export type CreateServiceMutationOptions = Apollo.BaseMutationOptions<CreateServiceMutation, CreateServiceMutationVariables>;
export const UpdateServiceDocument = gql`
    mutation UpdateService($id: Int!, $companyId: Int!, $input: ServiceInput!) {
  updateService(id: $id, input: $input, companyId: $companyId) {
    name
    company {
      id
    }
    id
    deleted
    description
    duration
    image
    onlineRegistration
    price
    serviceCategoryNew {
      id
      name
      deleted
      parentCategory {
        id
        name
        deleted
      }
    }
    serviceCategory {
      id
      name
    }
    serviceSubcategory {
      id
      name
    }
  }
}
    `;
export type UpdateServiceMutationFn = Apollo.MutationFunction<UpdateServiceMutation, UpdateServiceMutationVariables>;

/**
 * __useUpdateServiceMutation__
 *
 * To run a mutation, you first call `useUpdateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceMutation, { data, loading, error }] = useUpdateServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceMutation, UpdateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceMutation, UpdateServiceMutationVariables>(UpdateServiceDocument, options);
      }
export type UpdateServiceMutationHookResult = ReturnType<typeof useUpdateServiceMutation>;
export type UpdateServiceMutationResult = Apollo.MutationResult<UpdateServiceMutation>;
export type UpdateServiceMutationOptions = Apollo.BaseMutationOptions<UpdateServiceMutation, UpdateServiceMutationVariables>;
export const GetCategoryServDocument = gql`
    query GetCategoryServ($id: Int, $categoryName: String, $itemsPerPage: Int, $serviceDeleted: Boolean, $page: Int) {
  serviceCategories(
    id: $id
    categoryName: $categoryName
    itemsPerPage: $itemsPerPage
    page: $page
  ) {
    itemsPerPage
    currentPage
    pageCount
    totalCount
    items {
      id
      name
      comment
      deleted
      parentCategory {
        id
        name
        deleted
      }
      company {
        id
        name
        address
        phone
        website
        deleted
        services {
          pageCount
        }
      }
      services(deleted: $serviceDeleted) {
        id
        name
        onlineRegistration
        deleted
      }
      childCategories(name: $categoryName) {
        id
        name
        comment
        deleted
        services(deleted: $serviceDeleted) {
          id
          name
          onlineRegistration
          deleted
        }
        parentCategory {
          id
          name
          deleted
        }
      }
    }
  }
}
    `;

/**
 * __useGetCategoryServQuery__
 *
 * To run a query within a React component, call `useGetCategoryServQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryServQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryServQuery({
 *   variables: {
 *      id: // value for 'id'
 *      categoryName: // value for 'categoryName'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      serviceDeleted: // value for 'serviceDeleted'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetCategoryServQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoryServQuery, GetCategoryServQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryServQuery, GetCategoryServQueryVariables>(GetCategoryServDocument, options);
      }
export function useGetCategoryServLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryServQuery, GetCategoryServQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryServQuery, GetCategoryServQueryVariables>(GetCategoryServDocument, options);
        }
export function useGetCategoryServSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCategoryServQuery, GetCategoryServQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCategoryServQuery, GetCategoryServQueryVariables>(GetCategoryServDocument, options);
        }
export type GetCategoryServQueryHookResult = ReturnType<typeof useGetCategoryServQuery>;
export type GetCategoryServLazyQueryHookResult = ReturnType<typeof useGetCategoryServLazyQuery>;
export type GetCategoryServSuspenseQueryHookResult = ReturnType<typeof useGetCategoryServSuspenseQuery>;
export type GetCategoryServQueryResult = Apollo.QueryResult<GetCategoryServQuery, GetCategoryServQueryVariables>;
export const CreateServCategoryDocument = gql`
    mutation CreateServCategory($companyId: Int!, $input: ServiceCategoryNewInput!) {
  createServiceCategoryNew(companyId: $companyId, input: $input) {
    id
    name
    parentCategory {
      id
      name
    }
  }
}
    `;
export type CreateServCategoryMutationFn = Apollo.MutationFunction<CreateServCategoryMutation, CreateServCategoryMutationVariables>;

/**
 * __useCreateServCategoryMutation__
 *
 * To run a mutation, you first call `useCreateServCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServCategoryMutation, { data, loading, error }] = useCreateServCategoryMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateServCategoryMutation, CreateServCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServCategoryMutation, CreateServCategoryMutationVariables>(CreateServCategoryDocument, options);
      }
export type CreateServCategoryMutationHookResult = ReturnType<typeof useCreateServCategoryMutation>;
export type CreateServCategoryMutationResult = Apollo.MutationResult<CreateServCategoryMutation>;
export type CreateServCategoryMutationOptions = Apollo.BaseMutationOptions<CreateServCategoryMutation, CreateServCategoryMutationVariables>;
export const UpdateServCategoryDocument = gql`
    mutation UpdateServCategory($id: Int!, $companyId: Int!, $input: ServiceCategoryNewInput!) {
  updateServiceCategoryNew(id: $id, companyId: $companyId, input: $input) {
    id
    name
    parentCategory {
      id
      name
    }
  }
}
    `;
export type UpdateServCategoryMutationFn = Apollo.MutationFunction<UpdateServCategoryMutation, UpdateServCategoryMutationVariables>;

/**
 * __useUpdateServCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateServCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServCategoryMutation, { data, loading, error }] = useUpdateServCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServCategoryMutation, UpdateServCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServCategoryMutation, UpdateServCategoryMutationVariables>(UpdateServCategoryDocument, options);
      }
export type UpdateServCategoryMutationHookResult = ReturnType<typeof useUpdateServCategoryMutation>;
export type UpdateServCategoryMutationResult = Apollo.MutationResult<UpdateServCategoryMutation>;
export type UpdateServCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateServCategoryMutation, UpdateServCategoryMutationVariables>;
export type GetAllServicesQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  categoryId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  companyId: Types.Scalars['Int']['input'];
  employeeRelationsDeleted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetAllServicesQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, services?: { __typename?: 'ServicePaginated', currentPage?: number | null, itemsPerPage?: number | null, pageCount?: string | null, totalCount?: number | null, services?: Array<{ __typename?: 'ServiceProxy', id: number, deleted: boolean, image?: string | null, name: string, description?: string | null, price?: number | null, onlineRegistration: boolean, serviceCategoryNew?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null, serviceCategory?: { __typename?: 'ServiceCategoryProxy', id: number, name: string, deleted: boolean } | null, serviceSubcategory?: { __typename?: 'ServiceSubcategoryProxy', id: number, name: string, deleted: boolean, serviceCategory?: { __typename?: 'ServiceCategoryProxy', id: number, deleted: boolean, name: string } | null } | null, employeeServiceRelations?: Array<{ __typename?: 'EmployeeServiceRelationProxy', id: number, online: boolean, deleted: boolean }> | null, technologicalMapServiceRelations?: Array<{ __typename?: 'TechnologicalMapServiceRelationProxy', id: number, connect: boolean }> | null }> | null } | null } | null } | null };

export type GetCategoryQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetCategoryQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, serviceCategoriesNew?: Array<{ __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean } | null, childCategories?: Array<{ __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean } | null> | null }> | null } | null } | null };

export type GetOneServiceFromServicesListQueryVariables = Types.Exact<{
  employeeId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  categoryId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  deleted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetOneServiceFromServicesListQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, deleted: boolean, name: string, phone: string, address: string, bookingQrLink: string, servicesList?: Array<{ __typename?: 'ServiceProxy', id: number, name: string, image?: string | null, deleted: boolean, description?: string | null, onlineRegistration: boolean, duration?: string | null, price?: number | null, company?: { __typename?: 'CompanyProxy', id: number, address: string, bookingQrLink: string, deleted: boolean, isPremiumOn: boolean, name: string, phone: string, paidFeaturesPaginated: { __typename?: 'PaidFeaturePaginated', totalCount?: number | null } } | null, employeeServiceRelations?: Array<{ __typename?: 'EmployeeServiceRelationProxy', id: number, online: boolean, deleted: boolean }> | null, technologicalMapServiceRelations?: Array<{ __typename?: 'TechnologicalMapServiceRelationProxy', id: number, connect: boolean }> | null, serviceCategoryNew?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'ServiceCategoryNewProxy', name: string, id: number, deleted: boolean } | null } | null, bookings?: { __typename?: 'BookingPaginated', totalCount?: number | null, bookings?: Array<{ __typename?: 'BookingMainProxy', id: number, date?: string | null, uuid: any, needClientRecall: boolean, service?: { __typename?: 'ServiceProxy', id: number, name: string, deleted: boolean, onlineRegistration: boolean } | null }> | null } | null }> | null } | null } | null };

export type GetOneServiceQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetOneServiceQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', currency?: { __typename?: 'CurrencyProxy', currency?: string | null, code?: string | null, currencySign?: string | null } | null, services?: { __typename?: 'ServicePaginated', services?: Array<{ __typename?: 'ServiceProxy', name: string, duration?: string | null, price?: number | null, onlineRegistration: boolean, description?: string | null, id: number, deleted: boolean, image?: string | null, serviceCategoryNew?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null, employeeServiceRelations?: Array<{ __typename?: 'EmployeeServiceRelationProxy', id: number }> | null, company?: { __typename?: 'CompanyProxy', id: number } | null, serviceCategory?: { __typename?: 'ServiceCategoryProxy', id: number, name: string } | null, serviceSubcategory?: { __typename?: 'ServiceSubcategoryProxy', id: number, name: string } | null, bookings?: { __typename?: 'BookingPaginated', totalCount?: number | null, bookings?: Array<{ __typename?: 'BookingMainProxy', date?: string | null, service?: { __typename?: 'ServiceProxy', id: number } | null }> | null } | null }> | null } | null } | null } | null };

export type GetCurrencyQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetCurrencyQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', currency?: { __typename?: 'CurrencyProxy', currency?: string | null, code?: string | null, currencySign?: string | null } | null } | null } | null };

export type GetModulesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetModulesQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', modules?: Array<{ __typename?: 'ModuleProxy', id: number, name?: string | null }> | null } | null } | null };

export type CreateServiceMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.ServiceInput;
}>;


export type CreateServiceMutation = { __typename?: 'Mutation', createService: { __typename?: 'ServiceProxy', name: string, id: number, deleted: boolean, description?: string | null, duration?: string | null, image?: string | null, onlineRegistration: boolean, price?: number | null, company?: { __typename?: 'CompanyProxy', id: number } | null, serviceCategoryNew?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null, serviceCategory?: { __typename?: 'ServiceCategoryProxy', id: number, name: string } | null, serviceSubcategory?: { __typename?: 'ServiceSubcategoryProxy', id: number, name: string } | null } };

export type UpdateServiceMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  companyId: Types.Scalars['Int']['input'];
  input: Types.ServiceInput;
}>;


export type UpdateServiceMutation = { __typename?: 'Mutation', updateService: { __typename?: 'ServiceProxy', name: string, id: number, deleted: boolean, description?: string | null, duration?: string | null, image?: string | null, onlineRegistration: boolean, price?: number | null, company?: { __typename?: 'CompanyProxy', id: number } | null, serviceCategoryNew?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null, serviceCategory?: { __typename?: 'ServiceCategoryProxy', id: number, name: string } | null, serviceSubcategory?: { __typename?: 'ServiceSubcategoryProxy', id: number, name: string } | null } };

export type GetCategoryServQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  categoryName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  serviceDeleted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetCategoryServQuery = { __typename?: 'Query', serviceCategories?: { __typename?: 'ServiceCategoryNewPaginated', itemsPerPage?: number | null, currentPage?: number | null, pageCount?: string | null, totalCount?: number | null, items?: Array<{ __typename?: 'ServiceCategoryNewProxy', id: number, name: string, comment?: string | null, deleted: boolean, parentCategory?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean } | null, company?: { __typename?: 'CompanyProxy', id: number, name: string, address: string, phone: string, website?: string | null, deleted: boolean, services?: { __typename?: 'ServicePaginated', pageCount?: string | null } | null } | null, services?: Array<{ __typename?: 'ServiceProxy', id: number, name: string, onlineRegistration: boolean, deleted: boolean }> | null, childCategories?: Array<{ __typename?: 'ServiceCategoryNewProxy', id: number, name: string, comment?: string | null, deleted: boolean, services?: Array<{ __typename?: 'ServiceProxy', id: number, name: string, onlineRegistration: boolean, deleted: boolean }> | null, parentCategory?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null> | null }> | null } | null };

export type CreateServCategoryMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.ServiceCategoryNewInput;
}>;


export type CreateServCategoryMutation = { __typename?: 'Mutation', createServiceCategoryNew: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, parentCategory?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string } | null } };

export type UpdateServCategoryMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  companyId: Types.Scalars['Int']['input'];
  input: Types.ServiceCategoryNewInput;
}>;


export type UpdateServCategoryMutation = { __typename?: 'Mutation', updateServiceCategoryNew: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, parentCategory?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string } | null } };
