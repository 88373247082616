/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetServiceCategoriesDocument = gql`
    query getServiceCategories($id: Int, $categoryName: String, $itemsPerPage: Int, $serviceDeleted: Boolean, $page: Int) {
  serviceCategories(
    id: $id
    categoryName: $categoryName
    itemsPerPage: $itemsPerPage
    page: $page
  ) {
    itemsPerPage
    currentPage
    pageCount
    totalCount
    items {
      id
      name
      comment
      deleted
      company {
        id
        name
        address
        phone
        website
        deleted
        bookingQrLink
        services {
          pageCount
        }
        paidFeaturesPaginated {
          itemsPerPage
        }
      }
      services(deleted: $serviceDeleted) {
        id
        name
        onlineRegistration
        deleted
      }
      childCategories(name: $categoryName) {
        id
        name
        comment
        deleted
        company {
          id
          name
          address
          phone
          website
          deleted
          bookingQrLink
          services {
            pageCount
          }
          paidFeaturesPaginated {
            itemsPerPage
          }
        }
        services(deleted: $serviceDeleted) {
          id
          name
          onlineRegistration
          deleted
        }
        parentCategory {
          id
          name
          deleted
        }
      }
    }
  }
}
    `;

/**
 * __useGetServiceCategoriesQuery__
 *
 * To run a query within a React component, call `useGetServiceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceCategoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      categoryName: // value for 'categoryName'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      serviceDeleted: // value for 'serviceDeleted'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetServiceCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables>(GetServiceCategoriesDocument, options);
      }
export function useGetServiceCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables>(GetServiceCategoriesDocument, options);
        }
export function useGetServiceCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables>(GetServiceCategoriesDocument, options);
        }
export type GetServiceCategoriesQueryHookResult = ReturnType<typeof useGetServiceCategoriesQuery>;
export type GetServiceCategoriesLazyQueryHookResult = ReturnType<typeof useGetServiceCategoriesLazyQuery>;
export type GetServiceCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetServiceCategoriesSuspenseQuery>;
export type GetServiceCategoriesQueryResult = Apollo.QueryResult<GetServiceCategoriesQuery, GetServiceCategoriesQueryVariables>;
export const UpdateServiceCategoryDocument = gql`
    mutation UpdateServiceCategory($id: Int!, $input: ServiceCategoryNewInput!, $companyId: Int!) {
  updateServiceCategoryNew(id: $id, input: $input, companyId: $companyId) {
    id
    name
    deleted
  }
}
    `;
export type UpdateServiceCategoryMutationFn = Apollo.MutationFunction<UpdateServiceCategoryMutation, UpdateServiceCategoryMutationVariables>;

/**
 * __useUpdateServiceCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateServiceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceCategoryMutation, { data, loading, error }] = useUpdateServiceCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUpdateServiceCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceCategoryMutation, UpdateServiceCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceCategoryMutation, UpdateServiceCategoryMutationVariables>(UpdateServiceCategoryDocument, options);
      }
export type UpdateServiceCategoryMutationHookResult = ReturnType<typeof useUpdateServiceCategoryMutation>;
export type UpdateServiceCategoryMutationResult = Apollo.MutationResult<UpdateServiceCategoryMutation>;
export type UpdateServiceCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateServiceCategoryMutation, UpdateServiceCategoryMutationVariables>;
export const CreateServiceCategoryNewDocument = gql`
    mutation CreateServiceCategoryNew($companyId: Int!, $input: ServiceCategoryNewInput!) {
  createServiceCategoryNew(companyId: $companyId, input: $input) {
    id
    name
    deleted
  }
}
    `;
export type CreateServiceCategoryNewMutationFn = Apollo.MutationFunction<CreateServiceCategoryNewMutation, CreateServiceCategoryNewMutationVariables>;

/**
 * __useCreateServiceCategoryNewMutation__
 *
 * To run a mutation, you first call `useCreateServiceCategoryNewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceCategoryNewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceCategoryNewMutation, { data, loading, error }] = useCreateServiceCategoryNewMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceCategoryNewMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceCategoryNewMutation, CreateServiceCategoryNewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceCategoryNewMutation, CreateServiceCategoryNewMutationVariables>(CreateServiceCategoryNewDocument, options);
      }
export type CreateServiceCategoryNewMutationHookResult = ReturnType<typeof useCreateServiceCategoryNewMutation>;
export type CreateServiceCategoryNewMutationResult = Apollo.MutationResult<CreateServiceCategoryNewMutation>;
export type CreateServiceCategoryNewMutationOptions = Apollo.BaseMutationOptions<CreateServiceCategoryNewMutation, CreateServiceCategoryNewMutationVariables>;
export const GetRoleDocument = gql`
    query GetRole {
  user {
    role {
      id
      name
    }
  }
}
    `;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions?: Apollo.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
      }
export function useGetRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
        }
export function useGetRoleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleSuspenseQueryHookResult = ReturnType<typeof useGetRoleSuspenseQuery>;
export type GetRoleQueryResult = Apollo.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export type GetServiceCategoriesQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  categoryName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  serviceDeleted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetServiceCategoriesQuery = { __typename?: 'Query', serviceCategories?: { __typename?: 'ServiceCategoryNewPaginated', itemsPerPage?: number | null, currentPage?: number | null, pageCount?: string | null, totalCount?: number | null, items?: Array<{ __typename?: 'ServiceCategoryNewProxy', id: number, name: string, comment?: string | null, deleted: boolean, company?: { __typename?: 'CompanyProxy', id: number, name: string, address: string, phone: string, website?: string | null, deleted: boolean, bookingQrLink: string, services?: { __typename?: 'ServicePaginated', pageCount?: string | null } | null, paidFeaturesPaginated: { __typename?: 'PaidFeaturePaginated', itemsPerPage?: number | null } } | null, services?: Array<{ __typename?: 'ServiceProxy', id: number, name: string, onlineRegistration: boolean, deleted: boolean }> | null, childCategories?: Array<{ __typename?: 'ServiceCategoryNewProxy', id: number, name: string, comment?: string | null, deleted: boolean, company?: { __typename?: 'CompanyProxy', id: number, name: string, address: string, phone: string, website?: string | null, deleted: boolean, bookingQrLink: string, services?: { __typename?: 'ServicePaginated', pageCount?: string | null } | null, paidFeaturesPaginated: { __typename?: 'PaidFeaturePaginated', itemsPerPage?: number | null } } | null, services?: Array<{ __typename?: 'ServiceProxy', id: number, name: string, onlineRegistration: boolean, deleted: boolean }> | null, parentCategory?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null> | null }> | null } | null };

export type UpdateServiceCategoryMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.ServiceCategoryNewInput;
  companyId: Types.Scalars['Int']['input'];
}>;


export type UpdateServiceCategoryMutation = { __typename?: 'Mutation', updateServiceCategoryNew: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean } };

export type CreateServiceCategoryNewMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.ServiceCategoryNewInput;
}>;


export type CreateServiceCategoryNewMutation = { __typename?: 'Mutation', createServiceCategoryNew: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean } };

export type GetRoleQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetRoleQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', role?: { __typename?: 'RoleProxy', id: number, name: string } | null } | null };
