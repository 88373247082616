import React, {useEffect, useRef, useState} from "react";
import {Button} from "react-bootstrap";
import {BookingStatusProxy} from "@me-team/host/main/graphql/types";
import {StatusPickerInner} from "../../../../interfaces/interfaces";
import {DateTime} from "luxon";
import styles from './StatusPicker.module.scss'

type StatusPickerProps = {
    statusList: StatusPickerInner[]
    selectedDate : DateTime
    value : StatusPickerInner
    onChange : (statusId : number) => void
}
export const StatusPicker: React.FC<StatusPickerProps> = (props) => {

    const [openStatusPicker, setOpenStatusPicker] = useState(false);
    const btnRef = useRef<HTMLButtonElement>(null);
    const dropdownRef = useRef<HTMLUListElement>(null);


    const handleClickOutside = (event: MouseEvent) => {
        if (
            btnRef.current && !btnRef.current.contains(event.target as Node) &&
            dropdownRef.current && !dropdownRef.current.contains(event.target as Node)
        ) {
            setOpenStatusPicker(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const onStatusPickHandler = (pickedStatus : BookingStatusProxy) => {
        setOpenStatusPicker(false);
        props.onChange(pickedStatus.id);
    }

    return (
        <div className={'position-relative'}>
            <div className={'position-relative'}>
                <Button
                    id={'createNewBooking-recordStatus-field'}
                    onClick={() => setOpenStatusPicker(!openStatusPicker)}
                    ref={btnRef}
                    className={`form-control bg-white no-focus-outline select-border-hover 
                text-dark              
                text-start fs-7 fw-normal ${openStatusPicker ?
                        'rounded-bottom-0' : ''}`}
                >
                    {props.value.name}
                    <i className={`bi bi-chevron-down d-flex position-absolute top-50 
                end-0 me-2 pe-1 translate-middle-y transition ${openStatusPicker ? 'rotate-icon-180' : ''}`}></i>
                    <span className={`d-flex position-absolute top-50 
                end-0 me-5 translate-middle-y transition`}
                          style={{height: '16px', width: '16px', backgroundColor: `${props.value.colorCode}`}}></span>
                </Button>
            </div>
            {openStatusPicker && <>
                <ul ref={dropdownRef}
                    className={'border fs-7 z-1 list-unstyled border-top-0 rounded rounded-top-0 transition position-absolute w-100 bg-white'}>
                    {props.statusList.map((status: StatusPickerInner) => {
                        return !status.isHidden &&
                            <li className={`mb-0 py-2 px-6 border-bottom position-relative cursor-pointer`}
                                key={status.id} onClick={() => {onStatusPickHandler(status)}}>
                                <span className={`${styles.statusHover} d-block w-100 py-1 px-2 rounded`}>
                                    {status.name}
                                    <span className={`d-flex position-absolute top-50 
                                        end-0 me-5 translate-middle-y transition`}
                                          style={{height: '16px', width: '16px', backgroundColor: `${status.colorCode}`}}></span>
                                </span>
                            </li>
                    })}
                </ul>
            </>}
        </div>
    )
}