import React, {useState, useRef, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Row, Button } from "react-bootstrap";
import { Formik, Form as FormFormik } from 'formik';
import {FiltersButton, ReactSelect, TableHeader} from '@services/ui-components';
import VioletBadge from '@services/ui-components/src/VioletBadge/VioletBadge';
import EmployeeServicesTable from './EmployeeServicesTable/EmployeeServicesTable';
import CategoryInput from './EmployeeServicesTable/CategoryInput';
import { useGetEmployeeByIdQuery } from '@me-team/host/main/addEmployee/graphql/addEmployee.hooks';
import {useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';

const EmployeeServices: React.FC = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);

    const [currentId, setCurrentId] = useState({
        categoryId: null,
    });

    const inputRef = useRef(null);

    const [showFilters, setShowFilters] = useState(false)
    const [filter, setFilter] = useState({
        serviceName: null,
        categoryId: currentId.categoryId,
        accessibility: null,
        onlineBooking: null
    });

    const [currentFilter, setCurrentFilter] = useState({
        categoryId: currentId.categoryId,
        accessibility: null,
        onlineBooking: null
    })
    const [currentName, setCurrentName] = useState({
        categoryName: '',
        subCategoryName: '',
    });
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);
    const { data, loading, error, refetch } = useGetEmployeeByIdQuery({
        variables: { employeeId: +id, companyId: +currentCompanyId },
        skip: !currentCompanyId,
    })
    const employee = data?.user.company.employees.employees[0]

    const toggleFilters = () => setShowFilters(!showFilters);

    const handleAvailabilityChange = (selectedOption: { value: boolean; label: string } | null) => {
        setCurrentFilter(prevFilter => ({
            ...prevFilter,
            accessibility: selectedOption ? selectedOption.value : null
        }));
    };

    const handleOnlineBookingChange = (selectedOption: { value: boolean; label: string } | null) => {
        setCurrentFilter(prevFilter => ({
            ...prevFilter,
            onlineBooking: selectedOption ? selectedOption.value : null
        }));
    };

    const applyFilters = () => {
        setFilter({
            serviceName: inputRef.current.value,
            categoryId: currentId.categoryId,
            accessibility: currentFilter.accessibility,
            onlineBooking: currentFilter.onlineBooking
        });
    };

    const clearFilters = () => {
        setCurrentName({
            categoryName: '',
            subCategoryName: '',
        })
        setCurrentFilter({
            serviceName: null,
            categoryId: null,
            accessibility: null,
            onlineBooking: null
        });
        setFilter({
            serviceName: null,
            categoryId: null,
            accessibility: null,
            onlineBooking: null
        });
        if (inputRef.current) {
            inputRef.current.value = ''
        }
    };

    return (
        <>
            <VioletBadge
                text={t('On this page, you can select the services provided by this employee. You can specify different durations and prices for the services for this employee.')}
            />

            <TableHeader
                title={`${t('Employee\'s services')} «${employee?.name || ""}  ${employee?.surname || ""}»`}
                paths={[{ path: "/employees-list/employee-services/" + id, label: t('Personal') }, { path: "/employees-list",
                    label: t('Employee List') }, { path: "/employees-list/employee-services/" + id,
                    label: `${t('Employee\'s services')} «${employee?.name || ""}  ${employee?.surname || ""}»` }]}
                buttons={
                    <Row className="justify-content-end mt-3 mt-lg-0">
                        <Col sm={6} lg>
                            <FiltersButton filter={filter} showFilters={showFilters} onClick={toggleFilters}/>
                        </Col>
                    </Row>
                }
                showFilters={showFilters}
                colSize={10}
                btnColSize={2}
            >
                <Row className="mb-3 gx-3">
                    <Col xs={12} sm={6} xxl className="mb-3">
                        <input
                            type="text"
                            name="name"
                            className="form-control py-6"
                            placeholder={t('Search by service name')}
                            ref={inputRef}
                            id="employeeServices-search-field"
                        />
                    </Col>
                    <Col xs={12} sm={6} xxl className="mb-3">
                        <div className="position-relative">
                            <Formik initialValues={{ Name: '' }}>
                                {formik => (
                                    <FormFormik onSubmit={formik.handleSubmit}>
                                        <CategoryInput
                                            setCurrentId={setCurrentId}
                                            currentId={currentFilter}
                                            setCurrentName={setCurrentName}
                                            currentName={currentName}
                                        />
                                    </FormFormik>
                                )}
                            </Formik>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} xxl className="mb-3">
                        <ReactSelect
                            name="employeeServices-availability-field"
                            id="employeeServices-availability-field"
                            options={[
                                { value: true, label: t('Yes') },
                                { value: false, label: t('No') }
                            ]}
                            placeholder={t('Availability')}
                            onChange={handleAvailabilityChange}
                            isSearchable={false}
                            value={currentFilter.accessibility !== null
                                ? { value: currentFilter.accessibility, label: currentFilter.accessibility ? t('True') : t('False') }
                                : null}
                        />
                    </Col>
                    <Col xs={12} sm={6} xxl className="mb-3">
                        <ReactSelect
                            name="employeeServices-onlineBooking-field"
                            id="employeeServices-onlineBooking-field"
                            options={[
                                { value: true, label: t('True') },
                                { value: false, label: t('False') }
                            ]}
                            placeholder={t('Online Booking')}
                            onChange={handleOnlineBookingChange}
                            isSearchable={false}
                            value={currentFilter.onlineBooking !== null
                                ? { value: currentFilter.onlineBooking, label: currentFilter.onlineBooking ? t('True') : t('False') }
                                : null}
                        />
                    </Col>
                    <Col xs={12} xxl className="d-flex gap-3 mb-3">
                        <button className="btn btn-outline-dark border-grey fw-normal w-100" onClick={clearFilters}>
                            {t('clear')}
                        </button>
                        <button className="btn btn-outline-primary w-100" onClick={applyFilters}>
                            {t('Apply')}
                        </button>
                    </Col>

                </Row>
            </TableHeader >
            <EmployeeServicesTable filter={filter}  />
        </>
    );
};

export default EmployeeServices;


