/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetAllEmployeesDocument = gql`
    query GetAllEmployees($companyId: Int!) {
  user {
    id
    email
    name
    company(id: $companyId) {
      id
      employees {
        employees {
          id
          name
          surname
          position {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllEmployeesQuery__
 *
 * To run a query within a React component, call `useGetAllEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEmployeesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetAllEmployeesQuery(baseOptions: Apollo.QueryHookOptions<GetAllEmployeesQuery, GetAllEmployeesQueryVariables> & ({ variables: GetAllEmployeesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEmployeesQuery, GetAllEmployeesQueryVariables>(GetAllEmployeesDocument, options);
      }
export function useGetAllEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEmployeesQuery, GetAllEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEmployeesQuery, GetAllEmployeesQueryVariables>(GetAllEmployeesDocument, options);
        }
export function useGetAllEmployeesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllEmployeesQuery, GetAllEmployeesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllEmployeesQuery, GetAllEmployeesQueryVariables>(GetAllEmployeesDocument, options);
        }
export type GetAllEmployeesQueryHookResult = ReturnType<typeof useGetAllEmployeesQuery>;
export type GetAllEmployeesLazyQueryHookResult = ReturnType<typeof useGetAllEmployeesLazyQuery>;
export type GetAllEmployeesSuspenseQueryHookResult = ReturnType<typeof useGetAllEmployeesSuspenseQuery>;
export type GetAllEmployeesQueryResult = Apollo.QueryResult<GetAllEmployeesQuery, GetAllEmployeesQueryVariables>;
export const GetSalaryDocument = gql`
    query GetSalary($completedBookings: Boolean, $page: Int, $itemsPerPage: Int, $employeeId: Int, $startDate: String, $endDate: String, $filterByCompany: Boolean, $email: String, $companyId: Int!) {
  user {
    id
    company(id: $companyId) {
      id
      currency {
        id
        currency
        currencySign
        code
      }
      bookings(
        completedBookings: $completedBookings
        page: $page
        filterByCompany: $filterByCompany
        itemsPerPage: $itemsPerPage
        employeeId: $employeeId
        email: $email
        startDate: $startDate
        endDate: $endDate
      ) {
        bookings {
          id
          date
          time
          price
          uuid
          needClientRecall
          service {
            id
            name
            serviceCategoryNew {
              id
              name
            }
          }
          employee {
            id
            name
            surname
            position {
              id
              name
            }
            phone
            email
            manualBlocking
            tariffBlocking
          }
          bookingSalary {
            id
            salaryType
            salaryValue
          }
          salaryPayments(page: 1, itemsPerPage: 1000) {
            salaryPayments {
              id
              createdAt
              employee {
                id
              }
              periodStart
              periodEnd
              paymentValue
            }
            totalCount
          }
        }
        currentPage
        itemsPerPage
        pageCount
        totalCount
      }
    }
  }
}
    `;

/**
 * __useGetSalaryQuery__
 *
 * To run a query within a React component, call `useGetSalaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalaryQuery({
 *   variables: {
 *      completedBookings: // value for 'completedBookings'
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      employeeId: // value for 'employeeId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      filterByCompany: // value for 'filterByCompany'
 *      email: // value for 'email'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetSalaryQuery(baseOptions: Apollo.QueryHookOptions<GetSalaryQuery, GetSalaryQueryVariables> & ({ variables: GetSalaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalaryQuery, GetSalaryQueryVariables>(GetSalaryDocument, options);
      }
export function useGetSalaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalaryQuery, GetSalaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalaryQuery, GetSalaryQueryVariables>(GetSalaryDocument, options);
        }
export function useGetSalarySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSalaryQuery, GetSalaryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSalaryQuery, GetSalaryQueryVariables>(GetSalaryDocument, options);
        }
export type GetSalaryQueryHookResult = ReturnType<typeof useGetSalaryQuery>;
export type GetSalaryLazyQueryHookResult = ReturnType<typeof useGetSalaryLazyQuery>;
export type GetSalarySuspenseQueryHookResult = ReturnType<typeof useGetSalarySuspenseQuery>;
export type GetSalaryQueryResult = Apollo.QueryResult<GetSalaryQuery, GetSalaryQueryVariables>;
export const GetSalaryPaymentsDocument = gql`
    query GetSalaryPayments($page: Int, $itemsPerPage: Int, $employeeId: Int, $periodStart: String, $periodEnd: String, $companyId: Int!) {
  user {
    id
    company(id: $companyId) {
      id
      currency {
        id
        currency
        currencySign
        code
      }
      salaryPayments(
        page: $page
        itemsPerPage: $itemsPerPage
        employeeId: $employeeId
        periodStart: $periodStart
        periodEnd: $periodEnd
      ) {
        currentPage
        itemsPerPage
        pageCount
        totalCount
        salaryPayments {
          id
          createdAt
          employee {
            id
            name
            surname
            position {
              id
              name
            }
            phone
            email
            manualBlocking
            tariffBlocking
          }
          periodStart
          periodEnd
          paymentValue
          comment
        }
      }
    }
  }
}
    `;

/**
 * __useGetSalaryPaymentsQuery__
 *
 * To run a query within a React component, call `useGetSalaryPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalaryPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalaryPaymentsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      employeeId: // value for 'employeeId'
 *      periodStart: // value for 'periodStart'
 *      periodEnd: // value for 'periodEnd'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetSalaryPaymentsQuery(baseOptions: Apollo.QueryHookOptions<GetSalaryPaymentsQuery, GetSalaryPaymentsQueryVariables> & ({ variables: GetSalaryPaymentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalaryPaymentsQuery, GetSalaryPaymentsQueryVariables>(GetSalaryPaymentsDocument, options);
      }
export function useGetSalaryPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalaryPaymentsQuery, GetSalaryPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalaryPaymentsQuery, GetSalaryPaymentsQueryVariables>(GetSalaryPaymentsDocument, options);
        }
export function useGetSalaryPaymentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSalaryPaymentsQuery, GetSalaryPaymentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSalaryPaymentsQuery, GetSalaryPaymentsQueryVariables>(GetSalaryPaymentsDocument, options);
        }
export type GetSalaryPaymentsQueryHookResult = ReturnType<typeof useGetSalaryPaymentsQuery>;
export type GetSalaryPaymentsLazyQueryHookResult = ReturnType<typeof useGetSalaryPaymentsLazyQuery>;
export type GetSalaryPaymentsSuspenseQueryHookResult = ReturnType<typeof useGetSalaryPaymentsSuspenseQuery>;
export type GetSalaryPaymentsQueryResult = Apollo.QueryResult<GetSalaryPaymentsQuery, GetSalaryPaymentsQueryVariables>;
export const GetOneSalaryPaymentDocument = gql`
    query GetOneSalaryPayment($id: Int, $companyId: Int!) {
  user {
    id
    company(id: $companyId) {
      id
      currency {
        id
        currency
        currencySign
        code
      }
      salaryPayments(id: $id) {
        salaryPayments {
          id
          createdAt
          employee {
            id
            name
            surname
            position {
              id
              name
            }
          }
          periodStart
          periodEnd
          paymentValue
          comment
        }
      }
    }
  }
}
    `;

/**
 * __useGetOneSalaryPaymentQuery__
 *
 * To run a query within a React component, call `useGetOneSalaryPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneSalaryPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneSalaryPaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetOneSalaryPaymentQuery(baseOptions: Apollo.QueryHookOptions<GetOneSalaryPaymentQuery, GetOneSalaryPaymentQueryVariables> & ({ variables: GetOneSalaryPaymentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneSalaryPaymentQuery, GetOneSalaryPaymentQueryVariables>(GetOneSalaryPaymentDocument, options);
      }
export function useGetOneSalaryPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneSalaryPaymentQuery, GetOneSalaryPaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneSalaryPaymentQuery, GetOneSalaryPaymentQueryVariables>(GetOneSalaryPaymentDocument, options);
        }
export function useGetOneSalaryPaymentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOneSalaryPaymentQuery, GetOneSalaryPaymentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOneSalaryPaymentQuery, GetOneSalaryPaymentQueryVariables>(GetOneSalaryPaymentDocument, options);
        }
export type GetOneSalaryPaymentQueryHookResult = ReturnType<typeof useGetOneSalaryPaymentQuery>;
export type GetOneSalaryPaymentLazyQueryHookResult = ReturnType<typeof useGetOneSalaryPaymentLazyQuery>;
export type GetOneSalaryPaymentSuspenseQueryHookResult = ReturnType<typeof useGetOneSalaryPaymentSuspenseQuery>;
export type GetOneSalaryPaymentQueryResult = Apollo.QueryResult<GetOneSalaryPaymentQuery, GetOneSalaryPaymentQueryVariables>;
export const AddModuleDocument = gql`
    mutation AddModule($companyId: Int!, $input: ModuleInputType!) {
  updateCompanyModuleRelation(companyId: $companyId, input: $input) {
    id
    name
  }
}
    `;
export type AddModuleMutationFn = Apollo.MutationFunction<AddModuleMutation, AddModuleMutationVariables>;

/**
 * __useAddModuleMutation__
 *
 * To run a mutation, you first call `useAddModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addModuleMutation, { data, loading, error }] = useAddModuleMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddModuleMutation(baseOptions?: Apollo.MutationHookOptions<AddModuleMutation, AddModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddModuleMutation, AddModuleMutationVariables>(AddModuleDocument, options);
      }
export type AddModuleMutationHookResult = ReturnType<typeof useAddModuleMutation>;
export type AddModuleMutationResult = Apollo.MutationResult<AddModuleMutation>;
export type AddModuleMutationOptions = Apollo.BaseMutationOptions<AddModuleMutation, AddModuleMutationVariables>;
export const CreatePaymentDocument = gql`
    mutation CreatePayment($employeeId: Int!, $input: SalaryPaymentInput!, $companyId: Int!) {
  createSalaryPayment(
    employeeId: $employeeId
    input: $input
    companyId: $companyId
  ) {
    id
    paymentValue
    comment
    createdAt
    periodEnd
    periodStart
  }
}
    `;
export type CreatePaymentMutationFn = Apollo.MutationFunction<CreatePaymentMutation, CreatePaymentMutationVariables>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      input: // value for 'input'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCreatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, options);
      }
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMutation, CreatePaymentMutationVariables>;
export const DeletePaymentDocument = gql`
    mutation DeletePayment($salaryPaymentId: Int!, $companyId: Int!) {
  deleteSalaryPayment(salaryPaymentId: $salaryPaymentId, companyId: $companyId)
}
    `;
export type DeletePaymentMutationFn = Apollo.MutationFunction<DeletePaymentMutation, DeletePaymentMutationVariables>;

/**
 * __useDeletePaymentMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMutation, { data, loading, error }] = useDeletePaymentMutation({
 *   variables: {
 *      salaryPaymentId: // value for 'salaryPaymentId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDeletePaymentMutation(baseOptions?: Apollo.MutationHookOptions<DeletePaymentMutation, DeletePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePaymentMutation, DeletePaymentMutationVariables>(DeletePaymentDocument, options);
      }
export type DeletePaymentMutationHookResult = ReturnType<typeof useDeletePaymentMutation>;
export type DeletePaymentMutationResult = Apollo.MutationResult<DeletePaymentMutation>;
export type DeletePaymentMutationOptions = Apollo.BaseMutationOptions<DeletePaymentMutation, DeletePaymentMutationVariables>;
export const UpdatePaymentDocument = gql`
    mutation UpdatePayment($employeeId: Int!, $salaryPaymentId: Int!, $input: SalaryPaymentInput!, $companyId: Int!) {
  updateSalaryPayment(
    employeeId: $employeeId
    salaryPaymentId: $salaryPaymentId
    input: $input
    companyId: $companyId
  ) {
    id
    paymentValue
    comment
    createdAt
  }
}
    `;
export type UpdatePaymentMutationFn = Apollo.MutationFunction<UpdatePaymentMutation, UpdatePaymentMutationVariables>;

/**
 * __useUpdatePaymentMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMutation, { data, loading, error }] = useUpdatePaymentMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      salaryPaymentId: // value for 'salaryPaymentId'
 *      input: // value for 'input'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUpdatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentMutation, UpdatePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentMutation, UpdatePaymentMutationVariables>(UpdatePaymentDocument, options);
      }
export type UpdatePaymentMutationHookResult = ReturnType<typeof useUpdatePaymentMutation>;
export type UpdatePaymentMutationResult = Apollo.MutationResult<UpdatePaymentMutation>;
export type UpdatePaymentMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentMutation, UpdatePaymentMutationVariables>;
export type GetAllEmployeesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetAllEmployeesQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, email?: string | null, name?: string | null, company?: { __typename?: 'CompanyProxy', id: number, employees?: { __typename?: 'EmployeePaginated', employees?: Array<{ __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null, position?: { __typename?: 'EmployeePositionProxy', id: number, name: string } | null }> | null } | null } | null } | null };

export type GetSalaryQueryVariables = Types.Exact<{
  completedBookings?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  employeeId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  startDate?: Types.InputMaybe<Types.Scalars['String']['input']>;
  endDate?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filterByCompany?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  email?: Types.InputMaybe<Types.Scalars['String']['input']>;
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetSalaryQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, currency?: { __typename?: 'CurrencyProxy', id: number, currency?: string | null, currencySign?: string | null, code?: string | null } | null, bookings?: { __typename?: 'BookingPaginated', currentPage?: number | null, itemsPerPage?: number | null, pageCount?: string | null, totalCount?: number | null, bookings?: Array<{ __typename?: 'BookingMainProxy', id: number, date?: string | null, time?: string | null, price?: number | null, uuid: any, needClientRecall: boolean, service?: { __typename?: 'ServiceProxy', id: number, name: string, serviceCategoryNew?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string } | null } | null, employee?: { __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null, phone: string, email: string, manualBlocking: boolean, tariffBlocking: boolean, position?: { __typename?: 'EmployeePositionProxy', id: number, name: string } | null } | null, bookingSalary?: { __typename?: 'BookingSalaryProxy', id: number, salaryType?: number | null, salaryValue?: number | null } | null, salaryPayments?: { __typename?: 'SalaryPaymentPaginated', totalCount?: number | null, salaryPayments?: Array<{ __typename?: 'SalaryPaymentProxy', id: number, createdAt?: string | null, periodStart?: string | null, periodEnd?: string | null, paymentValue?: number | null, employee?: { __typename?: 'EmployeeProxy', id: number } | null }> | null } | null }> | null } | null } | null } | null };

export type GetSalaryPaymentsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  employeeId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  periodStart?: Types.InputMaybe<Types.Scalars['String']['input']>;
  periodEnd?: Types.InputMaybe<Types.Scalars['String']['input']>;
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetSalaryPaymentsQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, currency?: { __typename?: 'CurrencyProxy', id: number, currency?: string | null, currencySign?: string | null, code?: string | null } | null, salaryPayments?: { __typename?: 'SalaryPaymentPaginated', currentPage?: number | null, itemsPerPage?: number | null, pageCount?: string | null, totalCount?: number | null, salaryPayments?: Array<{ __typename?: 'SalaryPaymentProxy', id: number, createdAt?: string | null, periodStart?: string | null, periodEnd?: string | null, paymentValue?: number | null, comment?: string | null, employee?: { __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null, phone: string, email: string, manualBlocking: boolean, tariffBlocking: boolean, position?: { __typename?: 'EmployeePositionProxy', id: number, name: string } | null } | null }> | null } | null } | null } | null };

export type GetOneSalaryPaymentQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetOneSalaryPaymentQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, currency?: { __typename?: 'CurrencyProxy', id: number, currency?: string | null, currencySign?: string | null, code?: string | null } | null, salaryPayments?: { __typename?: 'SalaryPaymentPaginated', salaryPayments?: Array<{ __typename?: 'SalaryPaymentProxy', id: number, createdAt?: string | null, periodStart?: string | null, periodEnd?: string | null, paymentValue?: number | null, comment?: string | null, employee?: { __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null, position?: { __typename?: 'EmployeePositionProxy', id: number, name: string } | null } | null }> | null } | null } | null } | null };

export type AddModuleMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.ModuleInputType;
}>;


export type AddModuleMutation = { __typename?: 'Mutation', updateCompanyModuleRelation: { __typename?: 'CompanyProxy', id: number, name: string } };

export type CreatePaymentMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  input: Types.SalaryPaymentInput;
  companyId: Types.Scalars['Int']['input'];
}>;


export type CreatePaymentMutation = { __typename?: 'Mutation', createSalaryPayment: { __typename?: 'SalaryPaymentProxy', id: number, paymentValue?: number | null, comment?: string | null, createdAt?: string | null, periodEnd?: string | null, periodStart?: string | null } };

export type DeletePaymentMutationVariables = Types.Exact<{
  salaryPaymentId: Types.Scalars['Int']['input'];
  companyId: Types.Scalars['Int']['input'];
}>;


export type DeletePaymentMutation = { __typename?: 'Mutation', deleteSalaryPayment: boolean };

export type UpdatePaymentMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  salaryPaymentId: Types.Scalars['Int']['input'];
  input: Types.SalaryPaymentInput;
  companyId: Types.Scalars['Int']['input'];
}>;


export type UpdatePaymentMutation = { __typename?: 'Mutation', updateSalaryPayment: { __typename?: 'SalaryPaymentProxy', id: number, paymentValue?: number | null, comment?: string | null, createdAt?: string | null } };
