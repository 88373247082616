import { Modal, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import React from 'react';
import {useNavigate} from "react-router-dom";

interface ExistingBookingsModalProps {
    show?: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExistingBookingsModal: React.FC<ExistingBookingsModalProps> = ({
                                                                         show,
                                                                         setShow,
                                                                     }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleGoToLogbook = () => navigate('/work-schedule')
    const handleCancel = () => setShow(false)

    return (
        <Modal show={show} size="lg" centered onHide={handleCancel}>
            <Modal.Header closeButton className="border-0">
                <Modal.Title className="h5 fw-normal">
                    {t('There are bookings for selected employee providing current service')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                {t(
                    'There are bookings for selected employee providing current service. Go to logbook to edit or cancel bookings.'
                )}
            </Modal.Body>
            <Modal.Footer className="border-0 p-2">
                <Row className="gx-3 w-100">
                    <Col className="p-0">
                        <Button
                            variant="outline-primary"
                            className="w-100 me-1"
                            onClick={handleGoToLogbook}
                        >
                            {t('To Logbook')}
                        </Button>
                    </Col>
                    <Col className="p-0">
                        <Button
                            variant="primary"
                            className="w-100 text-truncate ms-1"
                            onClick={handleCancel}
                        >
                            {t('Cancel')}
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

export default ExistingBookingsModal;
