import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { beautySalonFormValidationSchema } from './constants';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import DropItem from "@services/ui-components/src/DropItem/DropItem";
import ImageCrop from '@services/ui-components/src/ImageCrop/ImageCrop';
import PhonePicker from '@services/ui-components/src/PhonePicker/PhonePicker';
import { useReactiveVar } from '@apollo/client';
import { currentCompanyIdVar } from '@me-team/host/src/apollo/globalVar/state';
import { ReactSelect, Spinner, ReactAsyncSelect, SpinnerLoader } from '@services/ui-components';
import BranchModalLimit from "../../../Branch/BranchModalLimit";
import MultipleImageCrop from "../../../ui-components/MultipleImageCrop/MultipleImageCrop";
import {
    useCreateBranchBeautySalonMutation,
    useGetCitiesQuery, useGetCompanyCreateBranchQuery,
    useGetCountriesQuery
} from '@me-team/host/main/branches/graphql/branches.hooks';
import { isRefetchBranches } from '@me-team/host/main/branches/state';

interface FormValues {
    name: string;
    type: string;
    description: string;
    country: string;
    city: any;
    address: string;
    phones: any;
    website: string;
    workSchedule: string;
    images: File | null;
    logo: File | null;
    customCity: any
}

interface SelectOption {
    label: string;
    value: string;
}

const BeautySalonForm: React.FC = () => {
    const { t } = useTranslation();
    const [countryId, setCountryId] = useState<number | null>(null);
    const navigate = useNavigate();
    const { data: countriesData } = useGetCountriesQuery();
    const { data: citiesData, loading: loadingCities } = useGetCitiesQuery({ variables: { countryId: countryId ?? 0 }, skip: !countryId });
    const [photos, setPhotos] = useState([null]);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const [createBranch] = useCreateBranchBeautySalonMutation();
    const [showCustomCityInput, setShowCustomCityInput] = useState<boolean>(false);
    const [showModalLimit, setShowModalLimit] = useState<boolean>(false);
    const { data: companyData } = useGetCompanyCreateBranchQuery({
        variables: { companyId: +currentCompanyId },
        skip: !currentCompanyId,
    });

    const countryOptions = countriesData?.relations.countries.map((country) => ({
        label: country.name,
        value: country.id.toString(),
    })) || [];

    const cityOptions = citiesData?.relations.cities.map((city) => ({
        label: city.name,
        value: city.id,
    })) || [];

    cityOptions.push({ label: t('Other'), value: -1 });

    const addPhotoToForm = (index: number, newPhoto: string): void => {
        setPhotos((prevPhotos: string[]) => {
            const updatedPhotos: string[] = [...prevPhotos];
            updatedPhotos[index] = newPhoto;
            return updatedPhotos;
        });
    }

    const initialValues: FormValues = {
        name: '',
        type: '',
        country: companyData?.user?.company?.country?.id.toString() || '',
        city: companyData?.user?.company?.country?.id ? companyData?.user?.company?.city?.id : '',
        address: companyData?.user?.company?.address || '',
        phones: [{
            phone: ''
        }],
        website: companyData?.user?.company?.website || '',
        workSchedule: '',
        description: '',
        images: null,
        logo: null,
        customCity: null
    };

    React.useEffect(() => {
        if (!countryId && companyData?.user?.company?.country?.id) {
            setCountryId(companyData.user.company.country.id);
        }
    }, [companyData, countryId]);

    const handleSubmit = async (values: FormValues, { setSubmitting, setFieldError }: {
        setSubmitting: (isSubmitting: boolean) => void,
        setFieldError: (field: string, message: string) => void
    }) => {
        const input = {
            category: "barber-shop",
            type: "barber-shop",
            name: values.name,
            country: parseInt(values.country, 10),
            city: showCustomCityInput ? null : parseInt(values.city, 10),
            otherCity: values?.customCity ? values?.customCity : null,
            address: values.address,
            phones: values.phones.map((phone: any) => ({ phone: phone.phone })),
            website: values.website  || values.website !== "" ? values.website : null,
            workSchedule: values.workSchedule,
            description: values.description ? values.description : null,
            images: photos,
            logo: values.logo
        };

        const validPhotos = photos.filter(photo => photo != null);
        input.images = validPhotos;

        try {
            await createBranch({
                variables: {
                    companyId: +currentCompanyId,
                    input
                },
            });
            navigate('/branches', { state:  {  text: t('Created'), toast: true } });
            isRefetchBranches(true);
            setSubmitting(false);
        } catch (error) {
            if (error?.graphQLErrors?.[0]?.extensions?.category === "branch.already_exists") {
                setFieldError('name', t("Branch with this name already exists!"));
            }
            if (error?.graphQLErrors?.[0]?.extensions?.category === "branch.tariff_error") {
                setShowModalLimit(true);
                isRefetchBranches(true);
            }
            setSubmitting(false);
        }
    };

    const handleCloseModalLimit = () => {
        setShowModalLimit(false);
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={beautySalonFormValidationSchema(t, true)}
                onSubmit={(values, actions) => handleSubmit(values, actions)}
                enableReinitialize
            >
                {({ setFieldValue, values, isSubmitting, errors, touched }) => {
                    const handleCityChange = (selectedOption: SelectOption | null) => {
                        if (selectedOption && selectedOption.value === -1 as any) {
                            setShowCustomCityInput(true);
                            setFieldValue('city', '');
                        } else {
                            setShowCustomCityInput(false);
                            setFieldValue('city', selectedOption ? selectedOption.value : '');
                        }
                    };
                    return (
                        <Form>
                            <Row className="mt-3">
                                <Col lg={9} className="pe-lg-5">
                                    <h5 className="fs-4 text-capitalize mb-4">{t("Basic information")}</h5>
                                    <div className="pe-lg-4">
                                        <div className="mb-4">
                                            <Row className="align-items-center">
                                                <Col xs={3} md={2}>
                                                    <label htmlFor="description" className="fs-7 fw-bold">{t('Branch name')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>
                                                </Col>
                                                <Col xs={9} md={10}>
                                                    <div className="position-relative">
                                                        <Field id="createBeautySalonBranch-name-field" name="name" placeholder={t('Branch name')} className={`form-control rounded-1 lh-22 pe-5 ${errors.name && touched.name ? "border-danger" : "border-secondary"}`} />
                                                        <div className="pt-1 position-absolute top-50 translate-middle-y end-0 me-3">
                                                            <OverlayTrigger
                                                                overlay={<Tooltip>{t('This data will be displayed in the online booking form')}</Tooltip>}>
                                                                <i className="bi bi-info-circle"></i>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Col xs={9} md={10} className="ms-auto">
                                                <ErrorMessage name="name">{msg => <p
                                                    className="fs-7 py-1 px-2 text-danger m-0">{msg}</p>}</ErrorMessage>
                                            </Col>
                                        </div>


                                        <Row className="mb-4">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="type" className="fs-7 fw-bold mt-2 pt-1">{t('country')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <ReactSelect
                                                    id="createBeautySalonBranch-country-field"
                                                    options={countryOptions}
                                                    value={countryOptions.find((option: any) => option.value === values.country)}
                                                    onChange={(selectedOption: SelectOption | null) => {
                                                        setFieldValue('country', selectedOption ? selectedOption.value : '');
                                                        setCountryId(selectedOption ? parseInt(selectedOption.value, 10) : null);
                                                        setFieldValue('city', '')
                                                    }}
                                                    placeholder={t('Select Country')}
                                                    isSearchable
                                                />
                                                <ErrorMessage name="country">{msg => <p
                                                    className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                            </Col>
                                        </Row>

                                        <Row className="mb-4">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="type" className="fs-7 fw-bold mt-2 pt-1">{t('city')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <ReactAsyncSelect
                                                    id="createBeautySalonBranch-city-field"
                                                    key={`city-${values.country}`}
                                                    options={cityOptions}
                                                    value={cityOptions.find((option: any) => option.value === values.city)}
                                                    onChange={handleCityChange}
                                                    placeholder={t('Select City')}
                                                    isSearchable
                                                    isLoading={loadingCities}
                                                />
                                                <ErrorMessage name="city">{msg => <p
                                                    className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                            </Col>
                                        </Row>

                                        {showCustomCityInput && (
                                            <Row className="mb-4">
                                                <Col xs={3} md={2}>
                                                    <label htmlFor="customCity" className="fs-7 fw-bold pt-2">{t('Add custom city name')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>
                                                </Col>
                                                <Col xs={9} md={10}>
                                                    <Field
                                                        id="createBeautySalonBranch-customCity-field"
                                                        name="customCity"
                                                        placeholder={t('Enter custom city name')}
                                                        className={`form-control ${errors.customCity && touched.customCity ? "border-danger" : "border-secondary"}`}
                                                        onChange={(e: any) => {
                                                            setFieldValue('customCity', e.target.value);
                                                        }}
                                                        maxLength={50}
                                                    />
                                                    <ErrorMessage name="customCity">{msg => <p className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                </Col>
                                            </Row>
                                        )}

                                        <Row className="mb-4">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="description" className="fs-7 fw-bold mt-2 pt-1">{t('address')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <div className="position-relative">
                                                    <Field id="createBeautySalonBranch-address-field" name="address" placeholder={t('Enter' +
                                                      ' your location')} className={`form-control pe-5 ${errors.address && touched.address ? "border-danger" : "border-secondary"}`} />
                                                    <div className="pt-1 position-absolute top-50 translate-middle-y end-0 me-3">
                                                        <OverlayTrigger
                                                            overlay={<Tooltip>{t('This data will be displayed in the online booking form')}</Tooltip>}>
                                                            <i className="bi bi-info-circle"></i>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="address">{msg => <p
                                                    className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                            </Col>
                                        </Row>

                                        <div className="form-group">
                                            <Row className="mb-2">
                                                <Col xs={3} md={2}>
                                                    <label className="fs-7 fw-bold mt-2 pt-1">{t('Phone')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>
                                                </Col>
                                                <Col xs={9} md={10}>
                                                    <FieldArray
                                                        name="phones"
                                                        render={({ remove, push, unshift }) => (
                                                            <>
                                                                {values.phones.map((phone: any, index: number) => {
                                                                    return (
                                                                        <div className="d-flex align-items-center w-100 mb-3" key={index}>
                                                                            <div className="w-100">
                                                                                <PhonePicker
                                                                                    id={`createBeautySalonBranch-phones.${index}.phone-field`}
                                                                                    key={`phones.${index}.phone`}
                                                                                    name={`phones.${index}.phone`}
                                                                                    value={phone.phone}
                                                                                />
                                                                                <ErrorMessage name={`phones.${index}.phone`}>{msg => <p
                                                                                    className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                                            </div>
                                                                            {index !== 0 && (
                                                                                <Button
                                                                                    type="button"
                                                                                    variant="outline-secondary"
                                                                                    className="h-100 ms-3 px-3 py-6 fs-normal rounded-1 align-self-start"
                                                                                    onClick={() => remove(index)}
                                                                                    disabled={isSubmitting}
                                                                                >
                                                                                    <i className="bi bi-trash3 text-dark"></i>
                                                                                </Button>
                                                                            )}

                                                                            {index === 0 && (
                                                                                <Button
                                                                                    type="button"
                                                                                    variant="outline-secondary"
                                                                                    className="h-100 ms-3 px-3 py-6 fs-normal rounded-1 align-self-start"
                                                                                    onClick={() => push('')}
                                                                                    disabled={isSubmitting}
                                                                                >
                                                                                    <i className="bi bi-plus-lg text-dark"></i>
                                                                                </Button>
                                                                            )}
                                                                        </div>
                                                                    )
                                                                })}
                                                            </>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>

                                        <Row className="mb-4">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="description" className="fs-7 fw-bold mt-2 pt-1">{t('website')}</label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <Field id="createBeautySalonBranch-website-field" name="website" placeholder={t('Site' +
                                                  ' address')} className="form-control rounded-1 lh-22" />
                                                <ErrorMessage name="website">{msg => <p
                                                    className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                            </Col>
                                        </Row>

                                        <div className="mb-4">
                                            <Row className="align-items-center">
                                                <Col xs={3} md={2}>
                                                    <label htmlFor="description" className="fs-7 fw-bold">{t('Work schedule')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>
                                                </Col>
                                                <Col xs={9} md={10}>
                                                    <div className="position-relative">
                                                        <Field id="createBeautySalonBranch-workSchedule-field" name="workSchedule" placeholder={t('Work schedule')}
                                                            className={`form-control rounded-1 lh-22 pe-5 ${errors.workSchedule && touched.workSchedule ? "border-danger" : "border-secondary"}`} />
                                                        <div className="pt-1 position-absolute top-50 translate-middle-y end-0 me-3">
                                                            <OverlayTrigger
                                                                overlay={<Tooltip>{t('This data will be displayed in the online booking form')}</Tooltip>}>
                                                                <i className="bi bi-info-circle"></i>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Col xs={9} md={10} className="ms-auto">
                                                <ErrorMessage name="workSchedule">{msg => <p
                                                    className="fs-7 py-1 px-2 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                <div className='mt-1 px-2'>
                                                    <small className="text-grey">
                                                        {t('for example, Mon-Sun : 11:00-22:00')}
                                                    </small>
                                                </div>
                                            </Col>
                                        </div>


                                        <Row className="mb-4">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="description"
                                                    className="fs-7 fw-bold pt-2">{t('Description')}</label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <div className="position-relative">
                                                    <Field id="createBeautySalonBranch-description-field" as="textarea" name="description" placeholder={t('Description branch')}
                                                        maxLength={200} className="form-control pe-5 rounded-1" />
                                                    <div className="pt-1 position-absolute top-0 end-0 mt-1 me-3">
                                                        <OverlayTrigger
                                                            overlay={<Tooltip>{t('This data will be displayed in the online booking form')}</Tooltip>}>
                                                            <i className="bi bi-info-circle"></i>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="description">{msg => <p
                                                    className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                <div className="fs-7 text-grey text-start mt-1 px-1">
                                                    {`${values.description.length}/200`}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mb-4">
                                            <Col xs={3} md={2}>
                                                <label htmlFor="description"
                                                    className="fs-7 fw-bold">{t('Photo of the branch')}</label>
                                            </Col>
                                            <Col xs={9} md={10}>
                                                <div className="mb-2">
                                                    <p className="fs-7 mb-0">
                                                        {t("Add jpeg images of the branch premises, interior, etc.")}
                                                        <OverlayTrigger
                                                            overlay={<Tooltip>{t('This data will be displayed in the online booking form')}</Tooltip>}>
                                                            <i className="bi bi-info-circle ms-1 fs-7 pt-1"></i>
                                                        </OverlayTrigger>
                                                    </p>
                                                </div>
                                                <Row className="gx-0">
                                                    <MultipleImageCrop
                                                        key={photos[0]}
                                                        photosArray={photos}
                                                        setPhotos={setPhotos}
                                                        setFieldValue={setFieldValue}
                                                    />
                                                </Row>
                                                <div className="mt-2">
                                                    <p className="fs-7 text-grey mb-6">{t("max-size")}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={3} className="ms-auto ps-lg-0">
                                    <h5 className="fs-4 mb-4">{t("logo")}</h5>
                                    <ImageCrop setFieldValue={setFieldValue} fieldName="logo" modalTitle={t('Branch logo')} />
                                </Col>
                            </Row>
                            <div className="d-sm-flex my-4 mt-lg-0">
                                <Button variant="outline-primary" className="fw-normal mb-3 mb-sm-0 me-3 custom-btn rounded-1" onClick={() => navigate(-1)}>
                                    {t("Cancel")}
                                </Button>
                                <Button type="submit" variant="primary" disabled={isSubmitting} className="fw-bold custom-btn rounded-1">
                                    {isSubmitting ? <SpinnerLoader variant="light" loadingText={t("Saving...")} /> : t('Save')}
                                </Button>
                            </div>
                        </Form>
                    )
                }}
            </Formik >
            <BranchModalLimit show={showModalLimit} handleClose={handleCloseModalLimit} />
        </>
    );
};

export default React.memo(BeautySalonForm);

