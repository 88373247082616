import {ErrorMessage, Field, FieldProps, useField} from 'formik';
import React, {useEffect, useState} from 'react';
import {Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

interface TextareaInputProps {
  label: string;
  placeholder: string;
  name: string;
  maxLength?: number;
  labelCol?: number;
  inputCol?: number;
  id: string;
}

const TextareaInputWithCounter: React.FC<TextareaInputProps> = ({
                                                                  label,
                                                                  placeholder,
                                                                  name,
                                                                  maxLength,
                                                                  labelCol = 2,
                                                                  inputCol,
                                                                  id
                                                                }) => {
  const {t} = useTranslation();
  const [field, meta] = useField(name);
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    setCharCount(field?.value?.length);
  }, [field.value]);

  return (
    <>
      <Col
        md={labelCol}
        className='mb-2 mb-md-0 text-start d-flex pt-6'>
        <label
          htmlFor={id}
          className='text-dark fs-7 fw-bold'>
          {label}
        </label>
      </Col>
      <Col md={inputCol} >
        <div className='position-relative text-start'>
          <Field name={name}>
            {({field, meta, form}: FieldProps) => (
              <textarea
                {...field}
                id={id}
                placeholder={placeholder}
                className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
                onBlur={() => form.setFieldTouched(field.name, true, true)}
                maxLength={maxLength}
              />
            )}
          </Field>
          <span
            id='countSymbols'
            className='fs-7 text-grey'>
            {charCount ? charCount : 0}/{maxLength}
          </span>
          <ErrorMessage name={name}>{msg => <div
            className='invalid-feedback'>{msg}</div>}</ErrorMessage>
        </div>
      </Col>
    </>
  );
};

export default TextareaInputWithCounter;
