import React from "react";
import {ServicePickSubcategoryInner} from "../../../../interfaces/interfaces";
import {DateTime} from "luxon";
import {useTranslation} from "react-i18next";

type ServiceItemProps = {
    service: ServicePickSubcategoryInner
    companyCurrencySign : string
    categoryId : number
    onServicePick : (categoryId : number, serviceId : number, isPicked : boolean) => void
    employeeId : number
    categoryName : string
}

export const ServiceItem: React.FC<ServiceItemProps> = (props) => {

    const {t: translate} = useTranslation();
    const onServicePickHandler = () => {
        props.onServicePick(props.categoryId, props.service.id, !props.service.isPicked);
    }


    return (
        <div
            className={`d-flex justify-content-between ps-3 p-2 
            mt-2 hover-bg-additional-violet transition rounded cursor-pointer
            ${props.service.isPicked ? 'bg-additional-violet' : ''}`}
            onClick={onServicePickHandler}
        >
            <div>
                <p className={'mb-0'}>{props.service.name} ({translate('category')}: {props.service.serviceCategoryNew.parentCategory ?
                props.service.serviceCategoryNew.parentCategory.name : props.categoryName})</p>
                <p className={'mb-0 fs-7 d-flex align-items-center'}>
                    <i className="bi bi-tag d-flex pe-2"></i>
                    <span>{props.employeeId ? props.service.employeeServiceRelations[0].price : props.service.price} {props.companyCurrencySign}</span>
                    <i className="bi bi-dot d-flex px-1 mx-1"></i>
                    <i className="bi bi-clock-history d-flex me-1 pe-1"></i>
                    <span>{DateTime.fromFormat(props.employeeId ? props.service.employeeServiceRelations[0].duration : props.service.duration, "HH:mm:ss").toFormat("HH:mm")}</span>
                </p>
            </div>
            {props.service.isPicked && <i className="bi bi-check-lg d-flex"></i>}
        </div>
    )
}