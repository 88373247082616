import { format } from 'date-fns';
import { uk, enUS } from 'date-fns/locale';
import {BookingHistoryDataProxy} from '@me-team/host/main/graphql/types';

interface Item {
    client?: {
        name?: string;
        surname?: string;
        email?: string;
        phone?: string;
    };
    user?: {
        name?: string;
        surname?: string;
    };
}
const customUkLocale = {
    ...uk,
    localize: {
        ...uk.localize,
        day: (n: number) => ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'][n],
    },
};
export const formatDate = (dateString: string, language: string) => {
    const date = new Date(dateString);
    const locale = language === 'uk' ? customUkLocale : enUS;
    return format(date, 'eee, dd MMMM yyyy', { locale });
};
export const getName = (i: Item): string | boolean => {
    return i?.client ? `${i.client?.name || ''} ${i.client?.surname || ''} ${i.client?.email || ''} ${i.client?.phone || ''}`.trim().replace(/\s+/g, ' ') :
           i?.user ? `${i.user?.name || ''} ${i.user?.surname || ''}`.trim().replace(/\s+/g, ' ') :
           true;
}

export const getTimeFromHistory = (historyDataArray: BookingHistoryDataProxy[]): string | null => {
    const timeData = historyDataArray.find(item => item.field === 'time');
    return timeData ? timeData.value : null;
};
export const calculateTimeDifference = (endTime: string, startTime: string): string => {
    const [endHours, endMinutes] = endTime.split(':').map(Number);
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    let hours = endHours - startHours;
    let minutes = endMinutes - startMinutes;
    if (minutes < 0) {
        minutes += 60;
        hours -= 1;
    }
    if (hours < 0) {
        hours += 24;
    }
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
};


const fieldOrder = [
    'status',
    'date',
    'time',
    'clientData',
    'service',
    'employee',
    'endTime',
    'price',
    'notificationTime',
    'notes'
] as const;

export function sortFields(a: BookingHistoryDataProxy, b: BookingHistoryDataProxy): number {
    return fieldOrder.indexOf(a.field as typeof fieldOrder[number]) - fieldOrder.indexOf(b.field as typeof fieldOrder[number]);
}