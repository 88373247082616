import {
  DateRangeCalendar,
  ReactSelect,
  InfoBanner,
  TableHeader, FiltersButton
} from '@services/ui-components';
import {Row, Col, Container} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import SalaryTable from './SalaryTable';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';
import ErrorService from '../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {UserRole} from '../../utils/enums';
import { useGetModulesListQuery } from '@me-team/host/main/modulesList/graphql/modulesList.hooks';
import { useUpdateCompanyModuleRelationMutation } from '@me-team/host/main/clients/graphql/clients.hooks';
import { useGetAllEmployeesQuery } from '@me-team/host/main/salary/graphql/salary.hooks';

interface Option {
  value: number;
  label: string;
}

const Salary: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);

  const {data: modulesList} = useGetModulesListQuery({
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })
  const salaryModuleId = modulesList?.relations?.modules.find(module => module.name === "Salary");
  const logbookModuleId = modulesList?.relations?.modules.find(module => module.name === "Logbook");

  const [updateCompanyModuleRelation] = useUpdateCompanyModuleRelationMutation()

  useEffect(() => {
    if (salaryModuleId && logbookModuleId && !isEmployeeRole) {
      updateCompanyModuleRelation({
        variables: {
          companyId: +currentCompanyId,
          input: {
            id: +logbookModuleId?.id,
            isActive: true
          }
        },
        context: {
          errorType: 'local'
        },
        onError: (error: ApolloError) => {
          setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
      })
      updateCompanyModuleRelation({
        variables: {
          companyId: +currentCompanyId,
          input: {
            id: +salaryModuleId?.id,
            isActive: true
          }
        },
        context: {
          errorType: 'local'
        },
        onError: (error: ApolloError) => {
          setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
      })
    }
  }, [salaryModuleId, currentCompanyId]);

  const initialFiltersRange = {
    startDate: moment().subtract(6, 'days').toDate(),
    endDate: new Date()
  }
  const [dateRangeFilter, setDateRangeFilter] = useState(initialFiltersRange);

  const [showFilters, setShowFilters] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState(null);

  const {data: employeesData} = useGetAllEmployeesQuery({
    variables: { companyId: +currentCompanyId },
    skip: !currentCompanyId,
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  });

  const employeesOptions = employeesData?.user?.company?.employees?.employees?.map((employee) => {
    return {
      value: employee.id,
      label: `${employee.name} ${employee.surname}`
    }
  }) || [];
  const [filter, setFilter] = useState({dateRangeFilter, employeeFilter: null});
  const toggleFilters = () => setShowFilters(!showFilters);

  const handleEmployeesChange = (selectedOption: Option) => {
    setSelectedEmployees(selectedOption);
  };

  const handleApplyFilter = () => {
    setFilter({
      dateRangeFilter: dateRangeFilter,
      employeeFilter: selectedEmployees?.value ? selectedEmployees?.value : null
    });
  };

  const handleClearFilter = () => {
    setFilter({
      dateRangeFilter: initialFiltersRange,
      employeeFilter: null
    });
    setSelectedEmployees(null)
    setDateRangeFilter(initialFiltersRange);
  };

  return (
    <div className='mb-9'>
      <InfoBanner>
        <p className='fs-7 mb-0 text-violet-hover'>{t('A section for managing employees\'' +
          ' salaries')}</p>
      </InfoBanner>

      <TableHeader
        title={t('Salary')}
        paths={[
          {path: '/employees-list', label: t('Personnel Management')},
          {path: `/personnel/${currentCompanyId}/salary`, label: t('Salary')},
        ]}
        colSize={4}
        btnColSize={8}
        buttons={
          <Row
            className='gx-3 justify-content-end align-items-center mt-4 mt-lg-0'
          >
            {!isEmployeeRole
              ?
              <Col
                xs={6}
                lg={4}
                xxl={3}
              >
                <Button
                  variant='primary'
                  className='w-100'
                  onClick={() => navigate(`/personnel/${currentCompanyId}/payment-history`)}
                >
                  {t('History of Payments')}
                </Button>
              </Col>
              : null
            }
            <Col
              xs={6}
              lg={4}
              xl={3}
              xxl={2}
            >
              <FiltersButton filter={filter} showFilters={showFilters} onClick={toggleFilters}/>
            </Col>
          </Row>
        }
        showFilters={showFilters}
      >
        <Row className='gx-3 pt-1 mb-4 pb-2 gap-3 gap-lg-0'>
          <Col lg={8} xl={9}>
            <Row className='gx-3'>
              <Col
                xs={12} sm={6}
                className='text-primary mb-3 mb-sm-0'>
                <DateRangeCalendar
                  dateRangeFilter={dateRangeFilter}
                  setDateRangeFilter={setDateRangeFilter}
                  id='salary-rangeDate-field'
                />
              </Col>
              <Col
                xs={12} sm={6}
                className='text-primary'>
                <ReactSelect
                  id='salary-employees-field'
                  name='employees'
                  value={selectedEmployees}
                  options={employeesOptions}
                  placeholder={t('employee')}
                  onChange={handleEmployeesChange}
                  isSearchable={true}
                  disabled={isEmployeeRole}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row className='gx-3 h-100'>
              <Col
                xs={6}>
                <Button
                  variant='outline-dark'
                  type='button'
                  className='border-grey w-100 h-100 fw-normal'
                  onClick={handleClearFilter}
                >
                  {t('clear')}
                </Button>
              </Col>
              <Col
                xs={6}>
                <Button
                  variant='primary'
                  className='w-100 h-100'
                  onClick={handleApplyFilter}
                >
                  {t('apply')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </TableHeader>

      <SalaryTable
        filter={filter}
      />
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </div>
  );
};

export default Salary;
