import {ServiceProxy} from '@me-team/host/main/graphql/types';
import {DateTime} from 'luxon';

export interface CalendarFilterI {
  statusId?: number,
  positionId?: number,
  employeeId?: number,
  serviceId?: number
}

export interface Option {
  value: number;
  label: string;
}

export const renderingOptionServices = (service: ServiceProxy) => {
  return (
    `${service?.name} - ${service?.serviceCategoryNew?.name}
      ${
      service.serviceCategoryNew?.parentCategory?.name
        ?
        `(${service.serviceCategoryNew?.parentCategory?.name})`
        :
        ''
    }`
  )
}

export const getLogbookDates = (branchId: string): { startDate: string; endDate: string } | null => {
  try {
    const logbookKey = `logbook${branchId}`;
    const logbookData = localStorage.getItem(logbookKey);

    if (!logbookData) {
      console.warn(`No data found for key: ${logbookKey}`);
      return null;
    }

    const parsedData = JSON.parse(logbookData);

    if (!parsedData.currentDateTime || !parsedData.timeState) {
      console.warn(`Invalid data format for key: ${logbookKey}`);
      return null;
    }

    const startDate = DateTime.fromISO(parsedData.currentDateTime).toFormat('yyyy-MM-dd');

    // Calculate the end date depending on timeState
    let endDate;
    switch (parsedData.timeState) {
      case 'month':
        endDate = DateTime.fromISO(parsedData.currentDateTime).plus({ months: 1 }).toFormat('yyyy-MM-dd');
        break;
      case 'RESOURCE_WEEK':
        endDate = DateTime.fromISO(parsedData.currentDateTime).plus({ weeks: 1 }).toFormat('yyyy-MM-dd');
        break;
      case 'RESOURCE_DAY':
        endDate = startDate;
        break;
      default:
        console.warn(`Unknown timeState: ${parsedData.timeState}`);
        return null;
    }

    return { startDate, endDate };
  } catch (error) {
    console.error('Error retrieving or processing logbook data:', error);
    return null;
  }
};
