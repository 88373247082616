import { useEffect } from "react";

const useScrollToTop = (pathname: string) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"

        });
    }, [pathname]);
};

export default useScrollToTop;
