import React from 'react';
import { Table } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TableHeader } from '@services/ui-components';
import Spinner from "@services/ui-components/src/Spinner/Spinner";
import VioletBadge from '@services/ui-components/src/VioletBadge/VioletBadge';
import BookingHistoryDataItem from './components/BookingHistoryDataItem';
import { formatDate, getName, sortFields  } from './utils/utils';
import {useReactiveVar} from "@apollo/client";
import {currentBranchIdVar, currentCompanyIdVar} from "@me-team/host/src/apollo/globalVar/state";
import {
    useGetCurrencySignQuery,
    useGetHistoryAppointmentQuery
} from '@me-team/host/main/appointment/graphql/appointment.hooks';

const HistoryChangeAppointment: React.FC = () => {
    const { t, i18n } = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);

  const { bookingId } = useParams();
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const { data, loading } = useGetHistoryAppointmentQuery({
        variables: { id: bookingId,  idBranch: +currentBranchId, companyId: +currentCompanyId  },
        skip: !currentBranchId,
    });
    const { data: currency, loading: currencyLoading } = useGetCurrencySignQuery({
      variables: { companyId: +currentCompanyId }
    });

    const appointment = data?.user?.company?.branchesPaginated?.branches[0]?.bookings?.bookings[0]?.bookingHistories?.slice().reverse();
    const currencySign = currency?.user?.company?.currency?.currencySign;

    if (loading || currencyLoading) return <Spinner />;
    return (
        <>
            <VioletBadge text={t("This page displays the history of all changes to the appointment, including the date, time, user who made the changes, and details of the modifications.")} />
            <TableHeader
                title={t('History of Changes')}
                paths={[
                    { path: `/logbook/journal/${currentBranchId}`, label: t('Logbook') },
                    { path: `/logbook/edit-record/${bookingId}`, label: t('Edit Record') },
                    { path: `/logbook/edit-record/${bookingId}/change-history`, label: t('History of Changes') }
                ]}
                showFilters={false}
            />

            <Table responsive>
                <tbody>
                {appointment?.map((i, index) => (
                    <tr key={i?.id}>
                        <td>
                            <div className='fs-7'>
                                {getName(i)}{' - '}
                                <span className="text-secondary">
                                        {index === 0 ? t('Create Record') : (i?.exists ? t('Edit Record') : t('Change Status'))}
                                    </span>{' - '}
                                {formatDate(i?.changeTime, i18n.language)}
                            </div>
                        </td>
                        <td>
                            {Array.from(i?.bookingHistoryData)?.sort(sortFields).map((data, idx) => (
                                <BookingHistoryDataItem key={idx} data={data} currency={currencySign} historyDataArray={i?.bookingHistoryData} />
                            ))}
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    );
};

export default HistoryChangeAppointment;
