import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  AddEditModal, EmptyList,
  ModalDelete, Pagination,
  PeriodSummaryRow,
  Spinner,
  TooltipCustom
} from '@services/ui-components';
import Button from 'react-bootstrap/Button';
import EditPaymentForm from './EditPaymentForm';
import {Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {ApolloError, useReactiveVar} from '@apollo/client';
import ErrorService from '../../services/ErrorService';
import {convertDateFormat, formatDate} from '@me-team/host/src/utils/utils';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {currencySingVar, currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import { useDeletePaymentMutation, useGetSalaryPaymentsQuery } from '@me-team/host/main/salary/graphql/salary.hooks';
import { SalaryPaymentProxy } from '@me-team/host/main/graphql/types';

interface filterType {
  employeeFilter: number | null,
  dateRangeFilter: {
    startDate: string,
    endDate: string,
  },
}

interface PaymentHistoryTableProps {
  filter: filterType,
}

const PaymentHistoryTable: React.FC<PaymentHistoryTableProps> = ({filter}) => {
  const {t} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const currencySing = useReactiveVar(currencySingVar);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [isEditPaymentModalOpened, setIsEditPaymentModalOpened] = useState(false);
  const [deletePaymentId, setDeletePaymentId] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [isAddPaymentModalOpened, setIsAddPaymentModalOpened] = useState(false);

  const {
    data: getSalaryPaymentsData,
    loading,
    refetch: refetchSalaryPayment
  } = useGetSalaryPaymentsQuery({
    variables: {
      page: currentPage,
      itemsPerPage: itemsPerPage,
      employeeId: filter.employeeFilter,
      periodStart: filter.dateRangeFilter.startDate,
      periodEnd: filter.dateRangeFilter.endDate,
      companyId: +currentCompanyId,
    },
    skip: !currentCompanyId,
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })

  const currency = getSalaryPaymentsData?.user?.company?.currency

  const [deleteSalaryPayment] = useDeletePaymentMutation()

  const handleShowEditPaymentModal = (id: number) => {
    setPaymentId(id)
    setIsEditPaymentModalOpened(true)
  }

  const handleEditPaymentModalClose = () => setIsEditPaymentModalOpened(false)

  const handleModalDeleteClose = () => setShowModalDelete(false);

  const handleShowModalDelete = (id: number) => {
    setDeletePaymentId(id)
    setShowModalDelete(true)
  };

  const handleDelete = () => {
    deleteSalaryPayment({
      variables: {salaryPaymentId: deletePaymentId, companyId: +currentCompanyId},
      context: {
        errorType: 'local'
      },
      onCompleted: (data) => {
        refetchSalaryPayment()
        data && handleModalDeleteClose();
      },
      onError: (error: ApolloError) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error))
      }
    })
  };

  const handleOpenAddPaymentModal = () => setIsAddPaymentModalOpened(true)

  const payments = getSalaryPaymentsData && getSalaryPaymentsData?.user?.company?.salaryPayments?.salaryPayments
  const pageCount = parseInt(getSalaryPaymentsData?.user?.company?.salaryPayments?.pageCount || '0');
  const totalItems = getSalaryPaymentsData?.user?.company?.salaryPayments?.totalCount || 0;
  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  function calculateTotalPayment(payments: SalaryPaymentProxy[]) {
    let totalPayment = 0;

    payments.forEach(payment => {
      totalPayment += payment.paymentValue;
    });
    return totalPayment;
  }

  const totalCost = payments && calculateTotalPayment(payments);

  if (loading) return <Spinner/>;

  return (
    <>
      <Container fluid className='table-responsive scrollbar position-relative px-0 pt-1'>
        <PeriodSummaryRow
          isButton={false}
          totalCost={totalCost}
          currency={currency?.code}
          totalItems={totalItems && totalItems}
          isAddPaymentModalOpened={isAddPaymentModalOpened}
        />
        {!!payments?.length ? (
          <table className='table table-borderless'>
            <thead>
            <tr className='fs-7'>
              <th className='py-3 col-2 ps-4 pe-2 align-middle'>{t('Date and Time of Payment')}</th>
              <th className='py-3 px-2 col-2 align-middle'>{t('Employee')}</th>
              <th className='py-3 px-2 col-2 align-middle'>{t('period')}</th>
              <th className='py-3 px-2 col-1 align-middle'>{t('Sum')}</th>
              <th className='py-3 px-2 col-2 align-middle'>{t('Comment')}</th>
              <th className='py-3 px-2 col-1 align-middle'></th>
            </tr>
            </thead>
            <tbody>
            {payments?.map((payment: SalaryPaymentProxy) => (
              <tr
                key={payment?.id}
                className='border-bottom'
              >
                <td className='align-middle py-4 ps-4 pe-2'>
                  {convertDateFormat(payment?.createdAt)}
                </td>
                <td className='align-middle py-4 px-2'>
                  <div className='d-flex flex-column'>
                    <Link
                      to={`/employees-list/edit-employee/${payment?.employee?.id}`}
                      className='text-decoration-none'
                    >
                      <span>
                        {payment?.employee?.name} {payment?.employee?.surname}
                      </span>
                    </Link>
                    <span className='fs-7 text-grey'>
                      {payment?.employee?.position?.name}
                    </span>
                  </div>
                </td>
                <td className='align-middle py-4 px-2'>
                  <div className='d-flex gap-1'>
                    <span>
                      {formatDate(payment?.periodStart)}
                    </span>
                    -
                    <span>
                       {formatDate(payment?.periodEnd)}
                    </span>
                  </div>
                </td>
                <td className='align-middle py-4 px-2'>
                  {payment?.paymentValue} {currencySing}
                </td>
                <td className='align-middle py-4 px-2 word-break'>
                  {payment?.comment}
                </td>
                <td className='align-middle text-end d-flex flex-nowrap justify-content-end px-0'>
                  <TooltipCustom placement='bottom' text={t('Edit Payout')}>
                    <Button
                      className='btn-square btn-sm p-2'
                      style={{width: '39px'}}
                      variant='outline-secondary'
                      onClick={() => handleShowEditPaymentModal(payment?.id)}
                    >
                      <i className='bi bi-pencil text-dark'></i>
                    </Button>
                  </TooltipCustom>
                  <TooltipCustom placement='bottom' text={t('Deletion of payment')}>
                    <Button
                      className='btn-sm btn-square p-2 ms-2'
                      style={{width: '39px'}}
                      variant='outline-secondary'
                      onClick={() => handleShowModalDelete(payment?.id)}
                    >
                      <i className='bi bi-trash3 text-dark'></i>
                    </Button>
                  </TooltipCustom>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        ) : (
          <EmptyList title='Salary payment list is empty'>
            <Button
              variant='primary'
              className='w-100 my-2 mb-lg-0'
              onClick={handleOpenAddPaymentModal}
            >
              <i className='bi bi-plus-lg fw-bold me-1 w-100'></i>
              {t('Add Payment')}
            </Button>
          </EmptyList>
        )}
      </Container>

      {getSalaryPaymentsData?.user?.company?.salaryPayments?.totalCount > 0 && (
        <div className='d-flex justify-content-between align-items-center mt-4 mb-5'>
          <Pagination
            t={t}
            pages={Number(pageCount)}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            firstItemIndex={firstItemIndex}
            lastItemIndex={lastItemIndex}
            totalItems={totalItems}
          />
        </div>
      )}
      <AddEditModal
        title={t('Edit Payout')}
        isModalOpened={isEditPaymentModalOpened}
        setIsModalOpened={setIsEditPaymentModalOpened}
      >
        <EditPaymentForm
          handleModalClose={handleEditPaymentModalClose}
          paymentId={paymentId}
        />
      </AddEditModal>
      <ModalDelete
        show={showModalDelete}
        handleClose={handleModalDeleteClose}
        title={t('Deletion of payment')}
        body={t('Are you sure you want to delete the payment?')}
        buttons={[
          {
            text: t('cancel'),
            onClick: handleModalDeleteClose,
            variant: 'btn btn-outline-violet border-primary text-primary fw-normal',
            className: 'd-flex justify-content-center align-items-center w-100',
          },
          {
            text: t('Delete payment'),
            onClick: handleDelete,
            variant: 'primary',
            className: 'w-100',
          },
        ]}
      />

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  )
    ;
};

export default PaymentHistoryTable
