import React, {useEffect, useState} from 'react';
import {
  CreateReviewModal,
  EditReviewModal,
  ModalDelete,
  Pagination,
  RenderEmptyList,
  TooltipCustom,
  TooltipPopover
} from '@services/ui-components';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import TooltipTextForName from './TooltipTextForName';
import TooltipTextForType from './TooltipTextForType';
import LabelType from './LabelType';
import EmployeeInfo from '@services/ui-components/src/EmployeeInfo/EmployeeInfo';
import StarRating from '@services/ui-components/src/StarRating/StarRating';
import {formatDateTime} from '@me-team/host/src/utils/utils';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {ApolloError, useReactiveVar} from '@apollo/client';
import ErrorService from '../../services/ErrorService';
import {Filter, initialValueFilter} from './constants';
import ToastComponent from "../ui-components/ToastComponent/ToastComponent";
import {useToast} from "../../hooks/useToast";
import RenderClientName from './RenderClientName';
import {useParams} from "react-router-dom";
import {
  currentCompanyIdVar,
  currentUserEmailVar,
  currentUserRoleVar,
  userIdVar
} from '@me-team/host/src/apollo/globalVar/state';
import {UserRole} from '../../utils/enums';
import { useDeleteReviewsMutation, useGetReviewsQuery } from '@me-team/host/main/reviews/graphql/reviews.hooks';
import {ReviewProxy} from '@me-team/host/main/graphql/types';

interface filterType {
  employeeId: number | null,
  reviewType: string | null,
  rating: number | null,
  reviewSortField: string,
  order: string
}

interface ReviewsTable {
  filter: Filter,
  showCreateModal: boolean,
  setShowCreateModal: (showCreateModal: boolean) => void,
}

const ReviewsTable: React.FC<ReviewsTable> = ({filter, showCreateModal, setShowCreateModal}) => {
  const {t} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const { userId } = useParams();
  const currentUserEmail = useReactiveVar(currentUserEmailVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [nameIdDelete, setNameIdDelete] = useState({name: '', id: null})
  const [idReview, setIdReview] = useState(null)
  const [showCreateEditModal, setShowCreateEditModal] = useState(false);
  const { showToast, toastText, toggleShowToast, setShowToast } = useToast();

  const resetCurrentPage = () => {
    setCurrentPage(1);
  };

  useEffect(() => {
    resetCurrentPage();
  }, [filter?.employeeId, filter?.reviewType, filter?.rating, filter?.reviewSortField, filter?.order]);
  const {
    data: reviewsData,
    loading,
    error,
    refetch,
  } = useGetReviewsQuery({
    variables: {
      page: currentPage,
      itemsPerPage: itemsPerPage,
      employeeId: !isEmployeeRole ? +userId || filter?.employeeId : null,
      employeeEmail: isEmployeeRole ? currentUserEmail : null,
      reviewSortField: filter?.reviewSortField,
      order: filter?.order,
      reviewType: filter?.reviewType,
      rating: filter?.rating,
      companyId: +currentCompanyId,
    },
    skip: !currentCompanyId || !currentUserEmail,
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  });

  const [deleteReview] = useDeleteReviewsMutation()

  const handleModalDeleteClose = () => setShowModalDelete(false);

  const handleEditReview = (id: number) => {
    setIdReview(id)
    setShowCreateEditModal(true)
    refetch();
  }

  const handleShowModalDelete = async (id: number, name: string) => {
    setNameIdDelete({name: name, id: id})
    setShowModalDelete(true)
  };

  const handleDelete = async () => {

    await deleteReview({
      variables: {id: nameIdDelete?.id, companyId: +currentCompanyId},
      context: {
        errorType: 'local'
      },
      onCompleted: (data) => {
        data && refetch();
        setShowModalDelete(false)
        toggleShowToast(t('Deleted'))
      },
      onError: (error: ApolloError) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error))
      }
    });
  };
  const reviews = reviewsData?.user?.company?.reviews?.reviews;
  const timeZone = reviewsData?.user?.company?.timeZone?.name;
  const pageCount = parseInt(reviewsData?.user?.company?.reviews?.pageCount || '0');
  const totalItems = reviewsData?.user?.company?.reviews?.totalCount || 0;
  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);
  const isFilterNotEmpty = !Object.keys(initialValueFilter).every(key => (
    filter[key as keyof Filter] === initialValueFilter[key as keyof Filter])
  );

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (loading) return <Spinner/>;

  return (
    <>
      <div className='col-12 table-responsive scrollbar position-relative'>
        {!!reviews?.length ? (
            <table className='table table-borderless'>
              <thead>
              <tr className='fs-7'>
                <th
                  className='py-3 col-2 ps-4 pe-2 align-middle'>{t('Review modal and table.Employee')}</th>
                <th className='py-3 px-2 col-1 align-middle'>{t('Date and Time')}</th>
                <th className='py-3 px-2 col-1 align-middle'>{t('Review modal and table.Rating')}</th>
                <th className='py-3 px-2 col-1 align-middle'>
                  <div className='d-flex align-items-center'>
                    <span>{t('Client name')}</span>
                    <TooltipPopover
                      placement='bottom'
                      text={
                        <TooltipTextForName/>
                      }
                    >
                      <i className='bi bi-info-circle text-dark ms-2' style={{fontSize: '16px'}}></i>
                    </TooltipPopover>
                  </div>
                </th>
                <th className='py-3 px-2 col-3 align-middle'>{t('Review')}</th>
                <th className='py-3 px-2 col-1 align-middle text-start'>
                  <span>
                    {t('Type')}
                  </span>
                  <TooltipPopover
                    placement='bottom'
                    text={<TooltipTextForType/>}
                  >
                    <i className='bi bi-info-circle text-dark ms-2' style={{fontSize: '16px'}}></i>
                  </TooltipPopover>
                </th>
                {!isEmployeeRole
                  ?
                  <th className='py-3 px-2 col-1 align-middle'></th>
                  : null
                }
              </tr>
              </thead>
              <tbody>
              {reviews.map((review: ReviewProxy) => (
                <tr key={review?.id} className='border-bottom'>
                  <td className='align-middle ps-4 py-4 pe-2'>
                    <EmployeeInfo
                      name={review?.employee?.name}
                      surname={review?.employee?.surname}
                      position={review?.employee?.position?.name}
                      image={review?.employee?.image}
                    />
                  </td>
                  <td className='align-middle py-4 px-2'>
                    {formatDateTime(review?.createdAt, timeZone)}
                  </td>
                  <td className='align-middle py-4 px-2'>
                    <StarRating rating={review?.rating}/>
                  </td>
                  <td className='align-middle py-4 px-2'>
                    {RenderClientName(review)}
                  </td>
                  <td className='align-middle py-4 px-2'>
                    {review?.text ? review?.text : '-'}
                  </td>
                  <td className='align-middle py-4 px-2'>
                    <LabelType status={review?.type}/>
                  </td>
                  {!isEmployeeRole
                    ?
                    <td className='align-middle ps-2 pe-0 py-4'>
                      <div className='d-flex align-items-center justify-content-end gap-3'>
                        <TooltipCustom placement='bottom' text={t('Edit Review(btn)')}>
                          <Button
                            variant='outline-secondary'
                            className='btn-square p-2'
                            style={{width: '39px', height: '39px'}}
                            onClick={() => handleEditReview(review?.id)}
                          >
                            <i className='bi bi-pencil text-dark'></i>
                          </Button>
                        </TooltipCustom>
                        <TooltipCustom placement='bottom' text={t('Delete Review(btn)')}>
                          <Button
                            variant='outline-secondary'
                            className='btn-square p-2'
                            style={{width: '39px', height: '39px'}}
                            onClick={() => handleShowModalDelete(review?.id, review?.name)}
                          >
                            <i className='bi bi-trash3 text-dark'></i>
                          </Button>
                        </TooltipCustom>
                      </div>
                    </td>
                    : null
                  }
                </tr>
              ))}
              </tbody>
            </table>
          ) :
          <RenderEmptyList
            isFilterNotEmpty={isFilterNotEmpty}
            emptyFilterTitle='No data found for the given values'
            emptyListTitle='The list of reviews is empty'
            onClickButton={() => setShowCreateModal(true)}
            isEmployeeRole={isEmployeeRole}
            buttonName={
              <span>
                <i className='bi bi-plus-lg fw-bold me-1 w-100'></i>
                {t('Add Review')}
              </span>
            }
          />
        }
      </div>

      {!!totalItems ? (
        <div className='d-flex justify-content-between align-items-center mt-4 mb-5'>
          <Pagination
            t={t}
            pages={Number(pageCount)}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            firstItemIndex={firstItemIndex}
            lastItemIndex={lastItemIndex}
            totalItems={totalItems}
          />
        </div>
      ) : null }

      <ModalDelete
        show={showModalDelete}
        handleClose={handleModalDeleteClose}
        title={t('Delete Feedback')}
        body={`${t('Do you really want to delete the review')} ${nameIdDelete?.name}?`}
        buttons={[
          {
            text: t('cancel'),
            onClick: handleModalDeleteClose,
            variant: 'btn btn-outline-violet border-primary text-primary fw-normal',
            className: 'd-flex justify-content-center align-items-center w-100',
          },
          {
            text: t('delete'),
            onClick: handleDelete,
            variant: 'primary',
            className: 'w-100',
          },
        ]}
      />

      <EditReviewModal
        isModalOpened={showCreateEditModal}
        setIsModalOpened={setShowCreateEditModal}
        reviewId={idReview}
        onSuccess={refetch}
        toggleShowToast={toggleShowToast}
      />

      <CreateReviewModal
        isModalOpened={showCreateModal}
        setIsModalOpened={setShowCreateModal}
        onSuccess={refetch}
        toggleShowToast={toggleShowToast}
      />
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
      { showToast &&  <ToastComponent show={showToast} setShow={setShowToast}  text={toastText}/>}
    </>
  )
};

export default ReviewsTable
