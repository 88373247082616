import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { restaurantFormValidationSchema } from './constants';
import { useBranchTypes } from '../../../../hooks/useBranchTypes';
import { useNavigate } from 'react-router-dom';
import {Col, Row, Button} from "react-bootstrap";
import {
    useCreateBranchRestaurantMutation,
    useGetCitiesQuery,
    useGetCountriesQuery
} from '@me-team/host/main/branches/graphql/branches.hooks';
import { SelectOption } from '../../types';
import {useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import {BranchPhoneInput} from '@me-team/host/main/graphql/types';

interface FormValues {
    name: string;
    type: string;
    description: string;
    country: string;
    city: string;
    address: string;
    phones: BranchPhoneInput[];
    email: string;
    website: string;
    workSchedule: string;
    image: File | null;
    instagram: string;
    facebook: string;
    youtube: string;
    whatsapp: string;
}

const RestaurantForm: React.FC = () => {
    const { t } = useTranslation();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const [countryId, setCountryId] = useState<number | null>(null);
    const navigate = useNavigate();

    const { data: countriesData } = useGetCountriesQuery();
    const { data: citiesData } = useGetCitiesQuery({ variables: { countryId: countryId ?? 0 }, skip: !countryId });
    const {data: branchTypesData} = useBranchTypes();
    const [createBranch] = useCreateBranchRestaurantMutation();


    const branchOptions = branchTypesData?.branchTypes.map((type) => ({
        label: type.name,
        value: type.id.toString(),
    })) || [];

    const countryOptions = countriesData?.relations.countries.map((country) => ({
        label: country.name,
        value: country.id.toString(),
    })) || [];

    const cityOptions = citiesData?.relations.cities.map((city) => ({
        label: city.name,
        value: city.id.toString(),
    })) || [];

    const initialValues: FormValues = {
        name: '',
        type: '',
        description: '',
        country: '',
        city: '',
        address: '',
        phones: [],
        email: '',
        website: '',
        workSchedule: '',
        image: null,
        instagram: '',
        facebook: '',
        whatsapp: '',
        youtube: '',
    };

    const handleSubmit = async (values: FormValues, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        const input = {
            companyId: +currentCompanyId,
            category: "restaurant",
            name: values.name,
            type: values.type,
            description: values.description,
            country: parseInt(values.country, 10),
            city: parseInt(values.city, 10),
            address: values.address,
            email: values.email,
            phones: values.phones.map((phone) =>  phone ),
            website: values.website,
            instagram: values.instagram,
            facebook: values.facebook,
            youtube: values.youtube,
            whatsapp: values.whatsapp,
        };

        try {
            await createBranch({
                variables: input,
            });
            navigate('/branches')
            setSubmitting(false);
        } catch (error) {
            console.error('Error creating branch:', error);
            setSubmitting(false);
        }
    };
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={restaurantFormValidationSchema(t)}
            onSubmit={handleSubmit}
        >
            {({ setFieldValue, values, handleChange, handleBlur, isSubmitting }) => {
                return (
                <Form>
                    <h5 className="fs-4 text-capitalize mb-4">{t("Basic information")}</h5>
                    <Row className="mb-4">
                        <Col xs={3} md={2}>
                            <label htmlFor="createRestaurantBranch-name-field" className="fs-7 fw-bold pt-2">{t('Branch name')}
                                <span className="text-danger ms-1">*</span>
                            </label>
                        </Col>
                        <Col xs={9} md={10}>
                            <div className="position-relative">
                                <Field id="createRestaurantBranch-name-field" name="name" placeholder={t('Name')} className="form-control" />
                                <div className="pt-1 position-absolute top-50 translate-middle-y end-0 me-3">
                                    <i className="bi bi-info-circle"></i>
                                </div>
                            </div>
                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col xs={3} md={2}>
                            <label htmlFor="type" className="fs-7 fw-bold pt-2">{t('Type')}
                                <span className="text-danger ms-1">*</span>
                            </label>
                        </Col>
                        <Col xs={9} md={10}>
                            <Select
                                className="select-type"
                                classNamePrefix="select"
                                options={branchOptions}
                                value={branchOptions.find(option => option.value === values.type)}
                                onChange={(selectedOption: SelectOption | null) => setFieldValue('type', selectedOption ? selectedOption.value : '')}
                                placeholder={t('Add type')}
                                isClearable
                            />
                            <ErrorMessage name="type" component="div" className="invalid-feedback" />
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col xs={3} md={2}>
                            <label htmlFor="createRestaurantBranch-description-field" className="fs-7 fw-bold pt-2">{t('Description')}</label>
                        </Col>
                        <Col xs={9} md={10}>
                            <Field as="textarea" id="createRestaurantBranch-description-field" name="description" placeholder={t('Description branch')} maxLength={600} className="form-control text-capitalize pe-4" />
                            <ErrorMessage name="description" component="div" className="invalid-feedback" />
                            <div className="fs-7 text-grey text-start mt-1 px-1">
                                {`${values.description.length}/600`}
                            </div>
                        </Col>
                    </Row>

                    <h5 className="fs-4 text-capitalize mt-5 mb-4">{t("Contact information")}</h5>

                    <Row className="mb-4">
                        <Col xs={3} md={2}>
                            <label htmlFor="type" className="fs-7 fw-bold pt-2">{t('country')}
                                <span className="text-danger ms-1">*</span>
                            </label>
                        </Col>
                        <Col xs={9} md={10}>
                            <Select
                                className="select-country"
                                classNamePrefix="select"
                                options={countryOptions}
                                value={countryOptions.find((option: any) => option.value === values.country)}
                                onChange={(selectedOption: SelectOption | null) => {
                                    setFieldValue('country', selectedOption ? selectedOption.value : '');
                                    setCountryId(selectedOption ? parseInt(selectedOption.value, 10) : null);
                                }}
                                placeholder={t('Select country')}
                                isClearable
                            />
                            <ErrorMessage name="country" component="div" className="invalid-feedback" />
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col xs={3} md={2}>
                            <label htmlFor="type" className="fs-7 fw-bold pt-2">{t('city')}
                                <span className="text-danger ms-1">*</span>
                            </label>
                        </Col>
                        <Col xs={9} md={10}>
                                <Select
                                    className="select-city"
                                    classNamePrefix="select"
                                    options={cityOptions}
                                    value={cityOptions.find((option: any) => option.value === values.city)}
                                    onChange={(selectedOption: SelectOption | null) => {
                                        setFieldValue('city', selectedOption ? selectedOption.value : '');
                                    }}
                                    placeholder={t('Select City')}
                                    isClearable
                                />
                                <ErrorMessage name="city" component="div" className="invalid-feedback" />
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col xs={3} md={2}>
                            <label htmlFor="createRestaurantBranch-address-field" className="fs-7 fw-bold pt-2">{t('address')}
                                <span className="text-danger ms-1">*</span>
                            </label>
                        </Col>
                        <Col xs={9} md={10}>
                            <div className="position-relative">
                                <Field id="createRestaurantBranch-address-field" name="address" placeholder={t('Enter your location')} className="form-control" />
                                <div className="pt-1 position-absolute top-50 translate-middle-y end-0 me-3">
                                    <i className="bi bi-info-circle"></i>
                                </div>
                            </div>
                            <ErrorMessage name="address" component="div" className="invalid-feedback" />
                        </Col>
                    </Row>

                    <div className="form-group mb-3">
                        <Row className="mb-4">
                            <Col xs={3} md={2}>
                                <label className="fs-7 fw-bold pt-2">{t('Phone')}
                                    <span className="text-danger ms-1">*</span>
                                </label>
                            </Col>
                            <Col xs={9} md={10}>
                                <FieldArray
                                    name="phones"
                                    render={({ remove, push }) => (
                                        <>
                                            {values.phones.map((phone, index) => (
                                                <Row className="mb-3" key={index}>
                                                    <Col md={10}>
                                                        <Field
                                                            id={`createRestaurantBranch-phones.${index}-field`}
                                                            name={`phones.${index}`}
                                                            placeholder={t('Phone Number')}
                                                            className="form-control"
                                                        />
                                                    </Col>
                                                    <Col md={2} className="d-flex gap-2">
                                                        {
                                                            index !== 0 && (
                                                                <Button
                                                                    type="button"
                                                                    variant="outline-secondary"
                                                                    className="h-100 w-100"
                                                                    onClick={() => remove(index)}
                                                                    disabled={isSubmitting}
                                                                >
                                                                    <i className="bi bi-trash3 text-dark"></i>
                                                                </Button>
                                                            )
                                                        }
                                                        {index === 0 && (
                                                            <Button
                                                                type="button"
                                                                variant="outline-secondary"
                                                                className="h-100 w-100"
                                                                onClick={() => push('')}
                                                                disabled={isSubmitting}
                                                            >
                                                                <i className="bi bi-plus-lg text-dark"></i>
                                                            </Button>
                                                        )}
                                                    </Col>
                                                    <ErrorMessage name={`phones.${index}`} component="div" className="invalid-feedback" />
                                                </Row>
                                            ))}
                                        </>
                                    )}
                                />
                            </Col>
                        </Row>
                    </div>

                    <Row className="mb-4">
                        <Col xs={3} md={2}>
                            <label htmlFor="createRestaurantBranch-email-field" className="fs-7 fw-bold pt-2">{t('email')}</label>
                        </Col>
                        <Col xs={9} md={10}>
                            <Field name="email" id="createRestaurantBranch-email-field" placeholder={t('Add E-mail')} className="form-control" />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col xs={3} md={2}>
                            <label htmlFor="createRestaurantBranch-website-field" className="fs-7 fw-bold pt-2">{t('website')}</label>
                        </Col>
                        <Col xs={9} md={10}>
                            <Field  name="website" id="createRestaurantBranch-website-field" placeholder={t('Site address')} className="form-control" />
                            <ErrorMessage name="website" component="div" className="invalid-feedback" />
                        </Col>
                    </Row>

                    <Row className="mb-5">
                        <Col xs={3} md={2}>
                            <label htmlFor="description" className="fs-7 fw-bold">{t('Photo of the branch')}</label>
                        </Col>
                        <Col xs={9} md={10}>
                            <div className="mb-2">
                                <p className="fs-7 mb-0">
                                    {t("Add jpeg images of the branch premises, interior, etc.")}
                                    <i className="bi bi-info-circle ms-1 fs-7 pt-1"></i>
                                </p>
                            </div>
                            <Row>
                                {/* add photos branch */}
                            </Row>
                            <div className="mt-2">
                                <p className="fs-7 text-grey">{t("max-size")}</p>
                            </div>
                        </Col>
                    </Row>

                    {/*<div className="form-group mb-3">*/}
                    {/*    <input*/}
                    {/*        id="image"*/}
                    {/*        name="image"*/}
                    {/*        type="file"*/}
                    {/*        className="form-control-file"*/}
                    {/*        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
                    {/*            setFieldValue('image', event.currentTarget.files ? event.currentTarget.files[0] : null);*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*    <ErrorMessage name="image" component="div" className="invalid-feedback" />*/}
                    {/*</div>*/}

                    <div className="d-flex gap-2 mb-4">
                        <h5 className="fs-4 text-capitalize">{t("Social Media")}</h5>
                        <i className="bi bi-info-circle pt-1"></i>
                    </div>

                    <Row className="mb-4">
                        <Col xs={3} md={2}>
                            <label htmlFor="createRestaurantBranch-instagram-field" className="fs-7 fw-bold pt-2">{t('Instagram')}</label>
                        </Col>
                        <Col xs={9} md={10}>
                            <Field  name="instagram" id="createRestaurantBranch-instagram-field" placeholder={t('Add link')} className="form-control" />
                            <ErrorMessage name="instagram" component="div" className="invalid-feedback" />
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col xs={3} md={2}>
                            <label htmlFor="createRestaurantBranch-facebook-field" className="fs-7 fw-bold pt-2">{t('Facebook')}</label>
                        </Col>
                        <Col xs={9} md={10}>
                            <Field  name="facebook" id="createRestaurantBranch-facebook-field" placeholder={t('Add link')} className="form-control" />
                            <ErrorMessage name="facebook" component="div" className="invalid-feedback" />
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col xs={3} md={2}>
                            <label htmlFor="createRestaurantBranch-youtube-field" className="fs-7 fw-bold pt-2">{t('Youtube')}</label>
                        </Col>
                        <Col xs={9} md={10}>
                            <Field  name="youtube" id="createRestaurantBranch-youtube-field" placeholder={t('Add link')} className="form-control" />
                            <ErrorMessage name="youtube" component="div" className="invalid-feedback" />
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col xs={3} md={2}>
                            <label htmlFor="createRestaurantBranch-whatsapp-field" className="fs-7 fw-bold pt-2">{t('Whatsapp')}</label>
                        </Col>
                        <Col xs={9} md={10}>
                            <Field  name="whatsapp" id="createRestaurantBranch-whatsapp-field" placeholder={t('Add link')} className="form-control" />
                            <ErrorMessage name="whatsapp" component="div" className="invalid-feedback" />
                        </Col>
                    </Row>

                    <div className="d-flex gap-2 mt-5 mb-4">
                        <h5 className="fs-4 text-capitalize">{t("Work schedule")}</h5>
                        <i className="bi bi-info-circle pt-1"></i>
                    </div>

                    <Row className="mb-4">
                        <Col md={2}>
                            <p className="fs-7 fw-bold mb-0">{t("Work hours for branch")}</p>
                        </Col>
                        <Col md={10}>
                            <Button variant="outline-primary" className="py-2 px-4 fw-normal">{t("Select hours")}</Button>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col md={2}>
                            <p className="fs-7 fw-bold mb-0">{t("Work hours for kitchen")}</p>
                        </Col>
                        <Col md={10}>
                            <Button variant="outline-primary" className="py-2 px-4 fw-normal">{t("Select hours")}</Button>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col md={2}>
                            <p className="fs-7 fw-bold mb-0">{t("Work hours for delivery")}</p>
                        </Col>
                        <Col md={10}>
                            <Button variant="outline-primary" className="py-2 px-4 fw-normal">{t("Select hours")}</Button>
                        </Col>
                    </Row>

                    <Row className="my-5">
                        <Col xs={6} sm={4} xl={3}>
                            <Button variant="outline-primary w-100 fw-normal">
                                {t("Cancel")}
                            </Button>
                        </Col>
                        <Col xs={6} sm={4} xl={3}>
                            <Button type="submit" variant="primary" disabled={isSubmitting} className="w-100 fw-normal">
                                {isSubmitting ? t('Saving...') : t('Save')}
                            </Button>
                        </Col>
                    </Row>

                </Form>
            )}}
        </Formik>
    );
};

export default React.memo(RestaurantForm);

