import React, {useState} from "react";
import {BranchProxy} from "@me-team/host/main/graphql/types";
import {useTranslation} from "react-i18next";
import {
    useGetBranchJsConfigListQuery,
    useUpdateBookingBranchConfigMutation,
} from "@me-team/host/main/facebookPixel/graphql/facebookPixel.hooks";
import {Accordion, Button, Col, Form, Modal, Row} from "react-bootstrap";
import {Field, Form as FormikForm, Formik} from "formik";
import {FacebookPixelService} from "../../../services/FacebookPixel/FacebookPixel";
import {useToast} from "../../../hooks/useToast";
import ToastComponent from "../../ui-components/ToastComponent/ToastComponent";
import {useParams} from "react-router-dom";

type FacebookPixelProps = {
    isPixelModalOpen: boolean;
    onClose: () => void;
};

const FacebookPixel: React.FC<FacebookPixelProps> = (props) => {
    const [branchList, setBranchList] = useState<BranchProxy[]>([]);
    const {t: translate} = useTranslation();
    const {currentCompanyId} = useParams();
    const {showToast, toastText, toggleShowToast, setShowToast} = useToast();

    const {loading} = useGetBranchJsConfigListQuery({
        variables: {companyId: parseInt(currentCompanyId)},
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setBranchList(data.user.company.branches.filter((branch) => !branch.deleted));
        },
    });

    const [updateConfig] = useUpdateBookingBranchConfigMutation({
        fetchPolicy: "network-only"
    });

    const closePixelModalHandler = () => {
        props.onClose();
    };

    return (
        !loading && branchList.length !==0 &&
        <>
            <Modal
                show={props.isPixelModalOpen}
                onHide={closePixelModalHandler}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Formik
                    validationSchema={FacebookPixelService.FacebookPixelValidation(translate, branchList)}
                    initialValues={{
                        branches: branchList.reduce(
                            (acc, branch) => ({
                                ...acc,
                                [branch.id]: (branch.meBookingConfig && branch.meBookingConfig.jsConfig) ? branch.meBookingConfig.jsConfig : "",
                            }),
                            {}
                        ),
                    }}
                    onSubmit={async (values) => {
                        await updateConfig({
                            variables: {
                                companyId: parseInt(currentCompanyId),
                                configs: FacebookPixelService.mutationConfigService(values.branches)
                            }, onCompleted: () => {
                                toggleShowToast(translate('Created'))
                                closePixelModalHandler();
                            }
                        })
                    }}
                >
                    {({
                          handleSubmit,
                          errors,
                          touched,
                      }: {
                        handleSubmit: () => void;
                        errors: { branches?: Record<string, string> };
                        touched: { branches?: Record<string, boolean> };
                    }) => (
                        <FormikForm onSubmit={handleSubmit}>
                            <Modal.Header closeButton className="border-bottom-0">
                                <Modal.Title className="fs-5">
                                    {translate("Facebook Pixel Code")}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Accordion defaultActiveKey="0" className="pixel-accordion">
                                    {branchList.map((branch: BranchProxy, index: number) => {
                                        const hasError =
                                            errors.branches?.[branch.id.toString()] &&
                                            touched.branches?.[branch.id.toString()];
                                        return (
                                            <Accordion.Item eventKey={index.toString()} key={branch.id}>
                                                <Accordion.Header>
                                                <span className={`${
                                                    hasError ? "text-danger" : ""
                                                }`}>
                                                    {branch.name}
                                                </span>
                                                </Accordion.Header>
                                                <Accordion.Body className="px-0">
                                                    <Form.Group
                                                        controlId={`textarea-${branch.id}`}
                                                        className="form-floating"
                                                    >
                                                        <Field
                                                            as="textarea"
                                                            name={`branches.${branch.id}`}
                                                            rows={3}
                                                            className={`form-control fs-normal ${
                                                                hasError ? "is-invalid" : ""
                                                            }`}
                                                            placeholder={translate("Facebook Pixel Code")}
                                                            style={{maxHeight: "499px", minHeight: "250px"}}
                                                        />
                                                        <Form.Label
                                                            className={"text-secondary"}>{translate("Facebook Pixel Code")}</Form.Label>
                                                        {hasError && (
                                                            <div className="invalid-feedback">
                                                                {errors.branches?.[branch.id.toString()]}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        );
                                    })}
                                </Accordion>
                            </Modal.Body>
                            <Modal.Footer className="border-top-0">
                                <Row className="w-100">
                                    <Col xs={6} className="ps-0 pe-2">
                                        <Button
                                            className="w-100 border-primary text-primary"
                                            variant="outline-violet"
                                            onClick={closePixelModalHandler}
                                        >
                                            {translate("Cancel")}
                                        </Button>
                                    </Col>
                                    <Col xs={6} className="px-0">
                                        <Button className="w-100" variant="primary" type="submit">
                                            {translate("Save")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
            {showToast && <ToastComponent show={showToast} setShow={setShowToast} text={toastText}/>}
        </>

    );
};

export default React.memo(FacebookPixel);
