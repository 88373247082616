import React, { useState, useRef, useEffect } from 'react';
import {useTranslation} from 'react-i18next';
import i18next from "i18next";
import { Col, Row, Button } from "react-bootstrap";
import { DateRangePicker } from 'react-date-range';
import moment from 'moment/moment';
import {InfoBanner, Pagination, ReactSelect, TableHeader, Spinner, FiltersButton} from "@services/ui-components";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { hexToRgbaWithOpacity, formatDate } from "../../hooks/helpers";
import ClientsEditReviewModal from "./ClientsEditReviewModal";
import { useReactiveVar } from "@apollo/client";
import {
    currentBranchIdVar,
    currencySingVar,
    currentCompanyIdVar
} from "@me-team/host/src/apollo/globalVar/state";
import { ErrorModalComponent } from '@me-pos/error-modal';
import ErrorService from "../../services/ErrorService";
import { ApolloError } from '@apollo/client';
import {useLocation, useNavigate} from 'react-router-dom';
import {removeClientId} from './constants';
import {
    BookingMainProxy,
    BookingStatusProxy,
    EmployeeProxy,
    EmployeeServiceRelationProxy,
    ServiceProxy
} from '@me-team/host/main/graphql/types';
import {
    useGetAllClientsHistoryVisitQuery, useGetServicesForClientsQuery, useGetEmployeesForClientsQuery
} from '@me-team/host/main/clientsHistoryVisit/graphql/clientsHistoryVisit.hooks';
import { useGetBookingStatusQuery } from '@me-team/host/main/appointment/graphql/appointment.hooks';

type selectOptionsType = {
    value: number,
    label: string,
    employeeServiceRelations?: EmployeeServiceRelationProxy[]
};
const ClientsHistoryVisit = () => {
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const currencySing = useReactiveVar(currencySingVar);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [stateClientId, setStateClientId] = useState<number | null>(null);
    const { t, i18n } = useTranslation();
    moment.locale(i18n.language);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const clientId = queryParams.get('clientId');

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setStateClientId(+clientId);
        removeClientId(navigate, location);
    }, []);

    const initialFilters: {statusId: number | null, search: string, serviceId: number | null, employeeId: number | null, startDate: string, endDate: string } = {
        statusId: null,
        search: '',
        serviceId: null,
        employeeId: null,
        startDate: null,
        endDate: null,
    }

    const dateRangeRef = useRef(null)
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage] = useState<number>(20);
    const [currentFilter, setCurrentFilter] = useState(initialFilters);
    const [filter, setFilter] = useState(initialFilters);
    const [isDateRangePickerOpened, setIsDateRangePickerOpened] = useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
    const [currentReviewId, setCurrentReviewId] = useState(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
    const [inputDateRange, setInputDateRange] = useState({
        startDate: initialFilters.startDate,
        endDate: initialFilters.endDate
    });
    const {data: clientsHistoryVisitData, loading, error} = useGetAllClientsHistoryVisitQuery({
        variables: {
            id: +currentBranchId,
            itemsPerPage: itemsPerPage,
            page: currentPage,
            statusId: filter?.statusId,
            serviceId:  filter?.serviceId,
            employeeId: filter?.employeeId,
            search: filter?.search,
            startDate: !stateClientId ? filter?.startDate : null,
            endDate: !stateClientId ? filter?.endDate : null,
            clientId: stateClientId ? stateClientId : null,
            companyId: +currentCompanyId,
        },
        skip: !currentCompanyId || !currentBranchId,
        context: {
            errorType: "local",
        },
        onError: (error) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error));
        }
    });
    const {data: bookingStatusesData} = useGetBookingStatusQuery({
        context: {
            errorType: "local",
        },
        onError: (error: ApolloError) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error));
        }
    });
    const {data: servicesData} = useGetServicesForClientsQuery({
        variables: {
            onlyFromBookings: true,
            itemsPerPage: 1000,
            companyId: +currentCompanyId,
        },
        skip: !currentCompanyId,
        context: {
            errorType: "local",
        },
        onError: (error: ApolloError) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error));
        }
    });
    const {data: employeesData} = useGetEmployeesForClientsQuery({
        variables: {
            onlyActive: true,
            itemsPerPage: 1000,
            companyId: +currentCompanyId,
        },
        skip: !currentCompanyId,
        context: {
            errorType: "local",
        },
        onError: (error: ApolloError) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error));
        }
    });

    const servicesOptions = servicesData?.user?.company?.services?.services?.map((service: ServiceProxy) => {
        return {
            value: service.id,
            label: service.name,
            employeeServiceRelations: service.employeeServiceRelations
        }
    }) || [];

    const employeesOptions = employeesData?.user?.company?.employees?.employees?.map((employee: EmployeeProxy) => {
        return {
            value: employee.id,
            label: `${employee.name} ${employee.surname}`,
            employeeServiceRelations: employee.employeeServiceRelations
        }
    }) || [];


    const statusesOptions = bookingStatusesData?.bookingStatus.slice(0, -1).map((status: BookingStatusProxy) => ({
        label: t(status.name),
        value: status.id,
    })) || [];

    const toggleFilters = () => setShowFilters(!showFilters);

    const servicesByEmployeeId = (id: number) => {
        if (!id) return servicesOptions
        return servicesOptions.filter((servicesOption) => {
            return servicesOption.employeeServiceRelations.some((relation) => relation.employee.id === id)
        })
    }

    const employeesByServiceId = (id: number) => {
        if (!id) return employeesOptions
        return employeesOptions.filter((employeesOption) => {
            return employeesOption.employeeServiceRelations.some((relation) => relation.service.id === id)
        })
    }

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentFilter({...currentFilter, search: e.target.value})
    }

    const handleStatusOptionChange = (option: {label: string, value: string}) => {
        setCurrentFilter({...currentFilter, statusId: +option.value})
    }

    const handleServiceChange = (service: {value: string, label: string }) => {
        setCurrentFilter({...currentFilter, serviceId: +service.value})
    }

    const handleEmployeeChange = (employee: {value: string, label: string }) => {
        setCurrentFilter({...currentFilter, employeeId: +employee.value})
    }

    const handleApplyFilter = () => {
        setStateClientId(null)
        setFilter({
            statusId: currentFilter.statusId,
            search: currentFilter.search,
            serviceId: currentFilter.serviceId,
            employeeId: currentFilter.employeeId,
            startDate: inputDateRange.startDate ? moment(inputDateRange.startDate).format('YYYY-MM-DD') : null,
            endDate: inputDateRange.endDate ? moment(inputDateRange.endDate).format('YYYY-MM-DD') : null,
        })
        setCurrentPage(1);
        setIsDateRangePickerOpened(false);
    }

    const handleClearFilter = () => {
        setStateClientId(null)
        setCurrentFilter(initialFilters);
        setInputDateRange({
            startDate: initialFilters.startDate,
            endDate: initialFilters.endDate
        });
        setFilter(initialFilters);
        setCurrentPage(1);
        setIsDateRangePickerOpened(false);
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const selectionRange = {
        startDate: new Date(currentFilter.startDate),
        endDate: new Date(currentFilter.endDate),
        key: 'selection',
    };

    const handleCancelDateRange = () => {
        setCurrentFilter({...currentFilter, startDate: formatDate(moment().subtract(1, 'week')), endDate: formatDate(moment())});
        setIsDateRangePickerOpened(false);
    };

    const handleSetDateRange = () => {
        setInputDateRange({...inputDateRange, startDate: currentFilter.startDate, endDate: currentFilter.endDate})
        setIsDateRangePickerOpened(false);
    };

    const handleDateRangeChange = (ranges: any) => {
        const { selection: { startDate, endDate } } = ranges
        setCurrentFilter({...currentFilter, startDate: formatDate(startDate), endDate: formatDate(endDate)})
    };

    const toggleDateRangePicker = () => {
        if (!isDateRangePickerOpened && !inputDateRange.startDate && !inputDateRange.endDate) {
            setCurrentFilter({
                ...currentFilter,
                startDate: formatDate(moment().subtract(1, 'week')),
                endDate: formatDate(moment())
            })
        }
        setIsDateRangePickerOpened(!isDateRangePickerOpened)
    };

    const handleReviewModalOpen = async (id: number | string) => {
        setIsModalOpened(true);
        setCurrentReviewId(id);
    };

    const findElementById = (array: selectOptionsType[], id: number | string) => {
        return array.find((e: selectOptionsType) => e.value === id) || null;
    };

    const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleApplyFilter();
        }
    };

    useOnClickOutside(dateRangeRef, () => setIsDateRangePickerOpened(false));

    const branches = clientsHistoryVisitData?.user?.company?.branchesPaginated?.branches[0];
    const bookings = branches?.bookings?.bookings
    const pageCount = parseInt(branches?.bookings.pageCount || '0');
    const totalItems = branches?.bookings.totalCount || 0;
    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

    if (loading) return <Spinner/>;

    return (
        <div className="mb-4">
            <InfoBanner>
                <p className="mb-0">{t("All visits for a some client or master within a certain period.")}</p>
            </InfoBanner>

            <TableHeader
                title={t("History of client visits")}
                paths={[{ path: "/clients-history-visit", label: t("clients")},
                    { path: "/clients-history-visit", label: t("History of client visits") }]}
                buttons={
                    <div className="d-flex justify-content-end mt-4 mt-lg-0">
                        <div className="col-12 col-sm-5 col-md-4 col-xl-4 col-xxl-3">
                            <FiltersButton filter={filter} showFilters={showFilters} onClick={toggleFilters}/>
                        </div>
                    </div>
                }
                showFilters={showFilters}
                colSize={7}
                btnColSize={5}
            >
                <Row className='gx-3'>
                    <Col lg={12}>
                        <div className='position-relative mb-3'>
                            <input
                                id="historyOfClientVisits-search-field"
                                type="text"
                                className="form-control ps-6 pe-5 rounded-1 text-truncate"
                                placeholder={t('Search by name, surname, phone or e-mail')}
                                value={currentFilter.search}
                                onChange={handleSearchChange}
                                onKeyDown={handleSearchKeyDown}
                            />
                            <i className='bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-6'></i>
                        </div>
                    </Col>
                    <Col xl={9}>
                        <Row className='gx-3'>
                            <Col sm={6} lg={3} className="mb-3 mb-xl-0">
                                <div className="position-relative px-1 h-100 border rounded-1">
                                    <small className="position-absolute top-0 fs-8 text-grey pt-1 ps-2">{t("period")}</small>
                                    <div className="h-100">
                                        <button type="button"
                                                onClick={toggleDateRangePicker}
                                                className="button d-flex justify-content-between align-items-center w-100 fs-7 border-0 bg-white px-2 pb-1 pt-3"
                                        >
                                            {inputDateRange.startDate && inputDateRange.endDate
                                                ? <span className="text-dark pt-1">{moment(inputDateRange.startDate).format("DD.MM.YY")} - {moment(inputDateRange.endDate).format("DD.MM.YY")}</span>
                                                : <span className="fs-7 text-grey pt-1">{t("Select date range")}</span>}
                                            <i className="bi-chevron-down position-absolute top-50 end-0 translate-middle-y me-6"></i>
                                        </button>
                                    </div>

                                    {isDateRangePickerOpened &&
                                        <div ref={dateRangeRef} className="shadow bg-white position-absolute start-0 border border-1 rounded-1 mt-2 py-2 z-3">

                                            <DateRangePicker
                                                onChange={handleDateRangeChange}
                                                className="d-flex flex-column flex-xl-row px-1 h-100"
                                                ranges={[selectionRange]}
                                                rangeColors={['#5842BE']}
                                                showDateDisplay={false}
                                                months={2}
                                                direction={screenWidth > 991 ? 'horizontal' : 'vertical'}
                                                inputRanges={[]}
                                            />
                                            <div className="calendar-range-text d-flex justify-content-center flex-column flex-lg-row align-items-center justify-content-center justify-content-xl-start px-3 pb-2 border-top border-additional-violet py-2 ms-xl-auto">
                                                <small className="d-block">
                                                    {`${moment(currentFilter.startDate).format(
                                                        'MMMM DD, YYYY'
                                                    )} - ${moment(currentFilter.endDate).format(
                                                        'MMMM DD, YYYY'
                                                    )}`}
                                                </small>
                                            </div>
                                            <div className="border-top border-additional-violet p-3 pb-2">
                                                <Row className="justify-content-end g-3">
                                                    <Col xs={6} lg={3}>
                                                        <Button variant="outline-dark"
                                                                className="fs-7 fw-normal border-grey w-100 d-flex justify-content-center rounded-1"
                                                                onClick={handleCancelDateRange}
                                                        >
                                                            <small>{t("cancel")}</small>
                                                        </Button>
                                                    </Col>
                                                    <Col xs={6} lg={3}>
                                                        <Button variant="primary"
                                                                className="fs-7 text-white w-100 d-flex justify-content-center rounded-1"
                                                                onClick={handleSetDateRange}
                                                        >
                                                            <small>{t("set")}</small>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Col>
                            <Col sm={6} lg={3} className="mb-3 mb-xl-0">
                                <ReactSelect id="historyOfClientVisits-service-field"
                                             value={findElementById(servicesOptions, currentFilter.serviceId)}
                                             options={servicesByEmployeeId(currentFilter.employeeId)}
                                             placeholder={t('service')}
                                             onChange={handleServiceChange}
                                             isSearchable={true}
                                             onKeyDown={handleSearchKeyDown}
                                />
                            </Col>
                            <Col sm={6} lg={3} className="mb-3 mb-xl-0">
                                <ReactSelect id="historyOfClientVisits-employee-field"
                                             value={findElementById(employeesOptions, currentFilter.employeeId)}
                                             options={employeesByServiceId(currentFilter.serviceId)}
                                             placeholder={t("employee")}
                                             onChange={handleEmployeeChange}
                                             isSearchable={true}
                                             onKeyDown={handleSearchKeyDown}
                                />
                            </Col>
                            <Col sm={6} lg={3} className="mb-3 mb-xl-0">
                                <ReactSelect id="historyOfClientVisits-status-field"
                                             value={findElementById(statusesOptions, currentFilter.statusId)}
                                             options={statusesOptions}
                                             placeholder={t("status")}
                                             onChange={handleStatusOptionChange}
                                             isSearchable={true}
                                             onKeyDown={handleSearchKeyDown}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={3} lg={6} className="mt-2 mt-sm-0 ms-lg-auto">
                        <Row className='gx-3 h-100'>
                            <Col xs={6}>
                                <button className="btn btn-outline-dark border-grey w-100 h-100 fw-normal rounded-1"
                                        onClick={handleClearFilter}
                                >{t("clear")}</button>
                            </Col>
                            <Col xs={6}>
                                <button className="btn btn-primary w-100 h-100 fw-normal px-1 rounded-1"
                                        onClick={handleApplyFilter}
                                >{t("apply")}</button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </TableHeader>

            {bookings?.length ? (
                <div className='col-12 table-responsive scrollbar mb-4'>
                    <table className="table mt-4 mb-2">
                        <thead>
                        <tr className='fs-7'>
                            <th className="px-3">{t('dateAndTime')}</th>
                            <th>{t("service")}</th>
                            <th>{t("client")}</th>
                            <th>{t("contacts")}</th>
                            <th>{t("employee")}</th>
                            <th>{t("price")}</th>
                            <th>{t("review")}</th>
                            <th>{t("statusBooking")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {bookings?.map((booking: BookingMainProxy) => (
                            <tr key={booking?.id}>
                                <td className="px-3">
                                    <span>{moment(booking?.date).format('DD.MM.YYYY')} {moment(booking?.time, 'HH:mm:ss').format(('HH:mm'))}</span>
                                </td>
                                <td>{booking?.service?.name}</td>
                                <td>
                                    <span>{booking?.client?.name} {booking?.client?.surname}</span>
                                </td>
                                <td>
                                    <p className="mb-2">{booking?.client?.phone}</p>
                                    <p className="mb-0 text-break">{booking?.client?.email}</p>
                                </td>
                                <td>
                                    <span>{booking?.employee?.name} {booking?.employee?.surname}</span>
                                </td>
                                <td>
                                    <p className="text-nowrap mb-0">{booking?.status?.id === 6 ? `${booking?.service?.price} ${currencySing}` : "-"}</p>
                                </td>
                                <td>{booking?.review?.rating ? (
                                    <div>
                                        <i className="bi bi-star-fill text-orange"></i>
                                        <button
                                            className="text-orange text-decoration-underline border-0 bg-transparent"
                                            onClick={() => handleReviewModalOpen(booking?.review?.id)}
                                        >
                                            {booking?.review?.rating}
                                        </button>
                                    </div>
                                ) : "-"}</td>
                                <td>
                                    <div className="d-inline-block py-2 px-3 rounded"
                                         style={{backgroundColor: hexToRgbaWithOpacity(booking?.status?.colorCode), color: booking?.status?.colorCode}}
                                    >
                                        <span className="fs-7 text-nowrap">{t(booking?.status?.name)}</span>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className='w-100 justify-content-md-center'>
                    <Col
                        md={10}
                        className='mt-5 pt-5 mx-auto'>
                        <h5 className='text-center font-weight-bold'>{t("noDataWasFoundForTheSpecifiedValuesInTheFiltersOrSearch.")}</h5>
                    </Col>
                </div>
            )}

            {bookings?.length ? (
                <div className="d-flex justify-content-between align-items-center">
                    <Pagination
                        t={t}
                        pages={Number(pageCount)}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        firstItemIndex={firstItemIndex}
                        lastItemIndex={lastItemIndex}
                        totalItems={totalItems}
                    />
                </div>
            ) : null}

            <ClientsEditReviewModal
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                reviewId={currentReviewId}
                setIsErrorModalOpen={setIsErrorModalOpen}
            />

            {isErrorModalOpen &&
                <ErrorModalComponent
                    i18n={i18next}
                    onClose={() => {
                        setIsErrorModalOpen(null)
                    }}
                    isOpen={!!isErrorModalOpen}
                    currentError={isErrorModalOpen}
                />
            }
        </div>
    )
}

export default ClientsHistoryVisit;
