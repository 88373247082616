import React, { forwardRef, memo } from "react";
import { useField } from "formik";
import { DateTime, Interval } from "luxon";
import { TimePicker } from "@me-pos/time-picker";
import i18next from "i18next";
import { BookingTime } from "@me-team/host/main/graphql/types";

type TimePickerWrapperProps = {
    name: string;
    startValue: string;
    onChange: (newDateTime: DateTime) => any;
    isDisabled: boolean;
    inactiveIntervals?: BookingTime[];
    step?: number;
    id?: string;
};

export const TimePickerWrapper = memo(
    forwardRef<HTMLDivElement, TimePickerWrapperProps>((props, ref) => {
        const [, meta] = useField(props.name);

        const interval = props.step ? props.step : 15;
        const currentDate = DateTime.now();
        const startDateTime = currentDate.startOf("day");
        const endDateTime = currentDate.endOf("day");

        const startValue = props.startValue ? props.startValue : "";

        let activeIntervals: Interval[] = props.inactiveIntervals?.map((timeSpan) =>
            Interval.fromDateTimes(
                DateTime.fromISO(timeSpan.start).set({
                    year: currentDate.year,
                    month: currentDate.month,
                    day: currentDate.day,
                }),
                DateTime.fromISO(timeSpan.end).set({
                    year: currentDate.year,
                    month: currentDate.month,
                    day: currentDate.day,
                }).plus({ minute: 15 })
            )
        ) || [];

        let inactiveIntervals: Interval[] = [];

        if (activeIntervals.length > 0) {
            const firstActive = activeIntervals[0];
            if (firstActive.start > startDateTime) {
                inactiveIntervals.push(
                    Interval.fromDateTimes(startDateTime, firstActive.start)
                );
            }

            for (let i = 0; i < activeIntervals.length - 1; i++) {
                const currentEnd = activeIntervals[i].end;
                const nextStart = activeIntervals[i + 1].start;
                if (currentEnd < nextStart) {
                    inactiveIntervals.push(
                        Interval.fromDateTimes(currentEnd, nextStart)
                    );
                }
            }

            const lastActive = activeIntervals[activeIntervals.length - 1];
            if (lastActive.end < endDateTime) {
                inactiveIntervals.push(
                    Interval.fromDateTimes(lastActive.end, endDateTime)
                );
            }
        } else {
            inactiveIntervals.push(
                Interval.fromDateTimes(startDateTime, endDateTime)
            );
        }

        const handleChange = async (newDateTime: DateTime) => {
            props.onChange(newDateTime);
        };

        return (
            <div
                id={props.id ? props.id : props.name}
                ref={ref}
                className={`time-picker-wrapper position-relative form-control p-0 ${
                    meta.touched && meta.error ? "is-invalid" : ""
                }`}
            >
                <TimePicker
                    i18n={i18next}
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    inactiveIntervals={inactiveIntervals}
                    stepInMinutes={interval}
                    onChange={handleChange}
                    value={startValue}
                    timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                    inputName={props.name}
                    isDisabled={props.isDisabled}
                    isRequired={false}
                />
            </div>
        );
    })
);
