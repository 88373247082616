import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Col, Row} from 'react-bootstrap';
import {FiltersButton, InfoBanner, TableHeader} from '@services/ui-components';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {CalendarFilterI} from './constants';
import CalendarWrapper from './CalendarWrapper';
import {currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';
import CalendarFilterWrapper from './CalendarFilterWrapper';
import {UserRole} from '../../utils/enums';
import ErrorService from '../../services/ErrorService';
import {useGetCurrentEmployeeIdQuery} from '@me-team/host/main/logbook/graphql/logbook.hooks';
import {useNavigate} from "react-router-dom";
import ToastComponent from "../ui-components/ToastComponent/ToastComponent";
import {useToast} from "../../hooks/useToast";

const Logbook: React.FC = () => {
  const {t, i18n} = useTranslation();
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const [showFilters, setShowFilters] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const navigate = useNavigate()

  const {data: currentEmployeeIdData, loading} = useGetCurrentEmployeeIdQuery({
    context: {
      errorType: "local",
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  })
  const currentEmployeeId = currentEmployeeIdData?.user?.employee?.id

  const initialValueFilter: CalendarFilterI = {
    statusId: null,
    positionId: null,
    employeeId: isEmployeeRole ? currentEmployeeId : null,
    serviceId: null,
  }

  const { showToast, toastText, setShowToast } = useToast();

  const [filters, setFilters] = useState<CalendarFilterI>(initialValueFilter);
  const handleCreateRecord = () => {
    navigate('/logbook/add-record')
  };

  const toggleFilters = () => setShowFilters(!showFilters);

  return (
    <>
      <Row className='mb-9'>
        <div className='px-6'>
          <InfoBanner>
            <p
                className='fs-7 mb-0'>{t('On this page, you can create, view, and edit customer records for the masters.')}
            </p>
          </InfoBanner>

          <TableHeader
              title={t('Logbook Journal')}
              colSize={8}
              btnColSize={4}
              buttons={
                <Row
                    className='gx-3 justify-content-lg-end mt-3 mt-lg-0'>
                  <Col
                      xs={7}
                      xxl={6}>
                    <Button
                        variant='green'
                        className='w-100 text-white'
                        onClick={handleCreateRecord}
                    >
                      <i className='bi bi-plus-lg fw-bold me-1 w-100 h-100'></i>
                      {t('Add record')}
                    </Button>
                  </Col>
                  <Col
                      xs={5}
                      xxl={4}>
                    <FiltersButton filter={filters} showFilters={showFilters} onClick={toggleFilters}/>
                  </Col>
                </Row>
              }
              showFilters={showFilters}
          >
            <CalendarFilterWrapper setFilters={setFilters}/>
          </TableHeader>
        </div>
        <CalendarWrapper filters={filters}/>
        {isErrorModalOpen ?
            <ErrorModalComponent
                i18n={i18next}
                onClose={() => {
                  setIsErrorModalOpen(null)
                }}
                isOpen={!!isErrorModalOpen}
                currentError={isErrorModalOpen}
            /> : null
        }
      </Row>
      { showToast &&  <ToastComponent show={showToast} setShow={setShowToast}  text={toastText}/>}
    </>
  );
};

export default Logbook;