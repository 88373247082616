import React from "react";
import {ReactSelectInterface} from "../../../../interfaces/interfaces";
import {ReactSelect} from "@services/ui-components";

type NotificationSelectProps = {
    name: string,
    isDisabled?: boolean,
    value: ReactSelectInterface,
    options : ReactSelectInterface[],
    onChange? : (selectedValue : ReactSelectInterface) => void
}

export const NotificationSelect: React.FC<NotificationSelectProps> = (props) => {
    const setCurrentNotification = (pickedValue?: ReactSelectInterface) => {
        props.onChange(pickedValue)
    };

    const onNotificationChangeHandler = (value: ReactSelectInterface) => {
        setCurrentNotification(value);
    };

    return (
        <>
            <ReactSelect
                options={props.options}
                value={props.value}
                onChange={(value: ReactSelectInterface) => {
                    onNotificationChangeHandler(value);
                }}
                placeholder={''}
                isSearchable
                name={props.name}
                disabled={props.isDisabled}
                menuPlacement={'auto'}
            />
        </>
    );
};
