import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import { TableHeader, Pagination, InfoBanner, StarRating, ReactSelect, Spinner, FiltersButton } from '@services/ui-components';
import {useBranchTypes} from '../../hooks/useBranchTypes';
import {useBranches} from '../../hooks/useBranches';
import {Row, Col, Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import LogoDefault from "../../images/logo-add.png";
import BranchModalDelete from "./BranchModalDelete";
import {
    currentBranchIdVar,
    currentCompanyIdVar,
    currentUserRoleVar
} from '@me-team/host/src/apollo/globalVar/state';
import AccessService from '@me-team/host/services/AccessService';
import BranchConfirmDelete from './BranchConfirmDelete';
import ToastComponent from "../ui-components/ToastComponent/ToastComponent";
import {useToast} from "../../hooks/useToast";
import { useDeleteBranchMutation } from '@me-team/host/main/branches/graphql/branches.hooks';
import { isRefetchBranches } from '@me-team/host/main/branches/state';
import {useReactiveVar} from '@apollo/client';

const Branch: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [currentFilter, setCurrentFilter] = useState({name: '', branchTypeId: ''});
    const [filter, setFilter] = useState({name: '', branchTypeId: ''});
    const [showFilters, setShowFilters] = useState(false);
    const [showModalDeleteBranch, setShowModalDeleteBranch] = useState<boolean>(false);
    const [showModalConfirmDeleteBranch, setShowModalConfirmDeleteBranch] = useState<boolean>(false);
    const [branchIdToDelete, setBranchIdToDelete] = useState<number | null>(null);
    const { showToast, toastText, setShowToast, toggleShowToast } = useToast();
    const toggleFilters = () => setShowFilters(!showFilters);
    const userRole = useReactiveVar(currentUserRoleVar);
    const accessService = AccessService.getInstance().isPremium;

    const [deleteBranch] = useDeleteBranchMutation()

    const basePath = process.env.REACT_APP_POS_URL;
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const {data: branchesData, loading, error, refetch} = useBranches(currentPage, itemsPerPage, filter, currentCompanyId);
    const {data: branchTypesData} = useBranchTypes();

    const branchTypeOptions = branchTypesData?.branchTypes.map((item: any) => ({
        value: item.id.toString(),
        label: item.name === "Barber-shop" ? "Beauty salon" : item.name
    })) || [];

    const selectedBranchType = branchTypeOptions.find(option => option.value === currentFilter.branchTypeId) || null;

    const handleCloseModalBranch = () => {
        setShowModalDeleteBranch(false);
    };

    const handleOpenConfirmModalBranch = (branchId: number) => {
        setBranchIdToDelete(branchId);
        setShowModalConfirmDeleteBranch(true);
    };

    const handleCloseConfirmModalBranch = () => {
        setBranchIdToDelete(null);
        setShowModalConfirmDeleteBranch(false);
    };

    const handleDeleteBranch = async () => {
        if (branchIdToDelete !== null) {
            try {
                await deleteBranch({
                    variables: { id: branchIdToDelete },
                });
                navigate("/branches");
                refetch();
                toggleShowToast(t('Deleted'))
                isRefetchBranches(true);
            } catch (error) {
                console.error('Error deleting branch:', error);
                if (error?.graphQLErrors?.[0]?.extensions?.category === "branch.latest") {
                    setShowModalDeleteBranch(true);
                }
            } finally {
                handleCloseConfirmModalBranch();
            }
        }
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentFilter({...currentFilter, name: event.target.value});
    };

    const handleBranchTypeChange = (selectedOption: { value: string; label: string } | null) => {
        setCurrentFilter((prevFilter) => ({
            ...prevFilter,
            branchTypeId: selectedOption ? selectedOption.value : ''
        }));
    };


    const handleApplyFilter = () => {
        setFilter(currentFilter);
        setCurrentPage(1);
    };

    const handleClearFilter = () => {
        setFilter({name: '', branchTypeId: ''});
        setCurrentFilter({name: '', branchTypeId: ''});
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleActiveBranchChange = (branchID: number) => {
        currentBranchIdVar(branchID);
        navigate(`/branches/main/branch/${branchID}`);
    };

    const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleApplyFilter();
        }
    };

    const colorTypeOfBranch = (type: string) => {
        switch (type) {
            case "Restaurant":
                return "#73D2FB";
            case "Cafe":
                return "#1AB394";
            case "Bar":
                return "#DC3545";
            case "Eatery":
                return "#5842BE";
            case "Fast-food":
                return "#EA89C2";
            case "Pub":
                return "#FFD600";
            case "Beauty salon":
                return "#EA89C2";
            default:
                return "#DEDEDE";
        }
    };

    const branches = branchesData?.user?.company?.branchesPaginated?.branches.map((item: any) => ({
        ...item,
        type: item.type.name === "Barber-shop" ? {name: "Beauty salon"} : item.type.name
    }));
    const activeBranches = branches?.filter(branch => !branch.deleted) || [];
    const pageCount = parseInt(branchesData?.user?.company?.branchesPaginated?.pageCount || '0');
    const totalItems = branchesData?.user?.company?.branchesPaginated?.totalCount || 0;
    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

    const hasAbilityToCreateBranch =  accessService || activeBranches.length < 2;

    if (loading) return <Spinner/>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="mb-4">
            <TableHeader
                title={t("branches")}
                paths={[{ path: "/branches", label: t('branches') }]}
                buttons={
                    <Row className='gx-3 justify-content-end mt-3 mt-lg-0'>
                        <Col xs={7} md={6} lg={7} xxl={5} className="mb-2 mb-sm-0">
                            { userRole !== 5 && <Button variant="primary" className="w-100 fw-bold rounded-1" disabled={!hasAbilityToCreateBranch} onClick={() => navigate('/create-branch')}><i className='bi bi-plus-lg me-2 px-1'></i>{t("Add Branch")}</Button>}
                        </Col>
                        <Col xs={5} md={6} lg={5} xxl={4}>
                            <FiltersButton filter={filter} showFilters={showFilters} onClick={toggleFilters} classesText="d-none d-sm-inline-block"/>
                        </Col>
                    </Row>
                }
                showFilters={showFilters}
                colSize={7}
                btnColSize={5}
            >
                <Row className='gx-4 pb-6'>
                    <Col lg={8} xl={9}>
                        <Row className='gx-3'>
                            <Col md={6}>
                                <div className="position-relative mb-3 mb-lg-0">
                                    <input
                                        id="brunchList-name-field"
                                        type="text"
                                        className="form-control rounded-1"
                                        placeholder={t("Search by name")}
                                        value={currentFilter.name}
                                        onChange={handleNameChange}
                                        onKeyDown={handleSearchKeyDown}
                                    />
                                    <i className='bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-3'></i>
                                </div>
                            </Col>
                            <Col md={6} className="mb-3 mb-lg-0">
                                <ReactSelect
                                    id="brunchList-type-field"
                                    value={selectedBranchType}
                                    options={branchTypeOptions}
                                    placeholder={t("Type of branch")}
                                    onChange={handleBranchTypeChange}
                                    isSearchable={true}
                                    disabled={false}
                                    onKeyDown={handleSearchKeyDown}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4} xl={3}>
                        <Row className='gx-3 h-100'>
                            <Col xs={6}>
                                <Button
                                    id="branch-clear-button"
                                    variant="outline-dark"
                                    className="border-grey w-100 h-100 fw-normal rounded-1"
                                    onClick={handleClearFilter}
                                >
                                    {t("clear")}
                                </Button>
                            </Col>
                            <Col xs={6}>
                                <Button
                                    id="branch-apply-button"
                                    variant="primary"
                                    className="w-100 h-100 rounded-1 fw-normal px-2"
                                    onClick={handleApplyFilter}
                                >
                                    {t("apply")}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </TableHeader>

            {
                !accessService && (
                    <InfoBanner>
                        <p className="fs-7 text-dark mb-0">{t("Only 2 active establishments are available with your tariff. For unlimited, order a tariff")}
                            <Link to={`/payment/company/${currentCompanyId}/tariff`} className="text-primary ms-1">Premium</Link></p>
                    </InfoBanner>
                )
            }

            {
                branches?.length ? (
                    <div className="table-responsive scrollbar mb-4">
                        <table className="table mt-6 mb-2">
                            <thead>
                            <tr className='fs-7'>
                                <th  className="col-1 text-center px-4">{t("logo")}</th>
                                <th className="col-2">{t("name")}</th>
                                <th className="col-4">{t("description")}</th>
                                {/*<th className="col-2 ps-4">{t("type")}</th>*/}
                                <th className="col-2">{t("rating")}</th>
                                <th className="col-1"></th>
                            </tr>
                            </thead>
                            <tbody>
                            {branches.map((branch) => (
                                branch.deleted ? (
                                    <tr key={branch.id} className="text-secondary">
                                        <td className="align-middle">
                                            <div className="position-relative d-flex justify-content-center align-items-center rounded-circle border border-secondary mx-auto" style={{ width: "40px", height: "40px" }}>
                                                <img src={branch?.image ? `${basePath}/${branch.image}` : LogoDefault} alt={branch?.name} className="rounded-circle mw-100 mh-100" />
                                                <div className="position-absolute top-0 left-0 w-100 h-100 rounded-circle opacity-50 bg-secondary"></div>
                                            </div>
                                        </td>
                                        <td className="text-secondary align-middle">{branch?.name}</td>
                                        <td>
                                            <p className="text-line-clamp text-secondary mb-0">{branch?.description}</p>
                                        </td>
                                        {/*<td>*/}
                                        {/*    {branch.type?.name && (*/}
                                        {/*        <div className="d-inline-block p-2 rounded bg-additional-grey text-secondary text-nowrap">*/}
                                        {/*            <span>{branch.type?.name}</span>*/}
                                        {/*        </div>*/}
                                        {/*    )}*/}
                                        {/*</td>*/}
                                        <td>
                                            <div className="d-flex justify-content-end">
                                                <StarRating rating={branch.rating} fontSize={6} textColor="secondary"/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex gap-2 justify-content-end align-items-center flex-nowrap">
                                                <Button variant="additional-grey" className="text-grey px-3 py-2 fw-normal rounded-1">
                                                    {t("Archive")}
                                                </Button>
                                                <OverlayTrigger overlay={<Tooltip>{t('Restore')}</Tooltip>}>
                                                    <Button
                                                        variant="outline-secondary"
                                                        disabled={!hasAbilityToCreateBranch} onClick={() => navigate(`/branch/${branch.id}`)}
                                                        className="text-dark rounded-1"
                                                    >
                                                        <i className="bi bi-arrow-counterclockwise d-flex fs-normal"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                            </div>
                                        </td>
                                    </tr>
                                ) : (
                                    <tr key={branch.id}>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-center align-items-center rounded-circle border border-secondary mx-auto" style={{ width: "40px", height: "40px" }}>
                                                <img src={branch?.image ? `${basePath}/${branch.image}` : LogoDefault} alt={branch?.name} className="rounded-circle mw-100 mh-100" />
                                            </div>
                                        </td>
                                        <td className="align-middle">
                                            <Link to={`/branches/main/branch/${branch.id}`} onClick={() => currentBranchIdVar(branch.id)} className="text-primary text-decoration-none">{branch?.name}</Link>
                                        </td>
                                        <td>
                                            <p className="text-line-clamp mb-0">{branch?.description}</p>
                                        </td>
                                        {/*<td>*/}
                                        {/*    <div className="d-inline-block p-2 rounded"*/}
                                        {/*         style={{ backgroundColor: hexToRgbaWithOpacity(colorTypeOfBranch(branch?.type?.name ? branch?.type?.name : branch?.category?.name)), color: colorTypeOfBranch(branch?.type?.name ? branch?.type?.name : branch?.category?.name) }}>*/}
                                        {/*        <span className="text-nowrap">{branch?.type ? branch?.type?.name : t(branch?.category?.name)}</span>*/}
                                        {/*    </div>*/}
                                        {/*</td>*/}
                                        <td><StarRating rating={branch.rating} fontSize={6}/></td>
                                        <td>
                                            <div className="d-flex gap-2 justify-content-end align-items-center flex-nowrap">
                                                <OverlayTrigger overlay={<Tooltip>{t('Main menu')}</Tooltip>}>
                                                    <Button
                                                        variant="outline-secondary"
                                                        onClick={() => handleActiveBranchChange(branch.id)}
                                                        className="py-2 text-dark rounded-1"
                                                    >
                                                        <i className="bi bi-gear"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={<Tooltip>{t('Edit')}</Tooltip>}>
                                                    <Button
                                                        variant="outline-secondary"
                                                        disabled={userRole === 5} onClick={() => navigate(`/branch/${branch.id}`)}
                                                        className="p-2 fs-7 text-dark rounded-1"
                                                    >
                                                        <i className="bi bi-pencil px-1"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={<Tooltip>{t('Delete')}</Tooltip>}>
                                                    <Button
                                                        variant="outline-secondary"
                                                        disabled={userRole === 5 || activeBranches.length === 1} onClick={() => handleOpenConfirmModalBranch(branch.id)}
                                                        className="py-2 text-dark rounded-1"
                                                    >
                                                        <i className="bi bi-trash3"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className='w-100 justify-content-md-center'>
                        <Col
                            md={10}
                            className='mt-5 pt-5 mx-auto'>
                            <h5 className='text-center font-weight-bold'>{t("NoDataFoundForTheGivenValues")}</h5>
                        </Col>
                    </div>
                )
            }

            { branches?.length ? (
                <div className="d-flex justify-content-between align-items-center">
                    <Pagination
                        t={t}
                        pages={Number(pageCount)}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        firstItemIndex={firstItemIndex}
                        lastItemIndex={lastItemIndex}
                        totalItems={totalItems}
                    />
                </div>
            ) : null}
            <BranchModalDelete show={showModalDeleteBranch} handleClose={handleCloseModalBranch}/>
            <BranchConfirmDelete show={showModalConfirmDeleteBranch} handleClose={handleCloseConfirmModalBranch} handleDelete={handleDeleteBranch}/>
            { showToast &&  <ToastComponent show={showToast} setShow={setShowToast} text={toastText}/>}
        </div>
    );
};

export default Branch;
