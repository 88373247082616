import * as Yup from 'yup';
import { PhoneNumberUtil } from 'google-libphonenumber';
import {emailValidation} from "../../../../utils/emailValidation";
import {TFunction} from 'i18next';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
        return false;
    }
};

export const beautySalonFormValidationSchema = (t:  TFunction, isBranch = false) => {
    return Yup.object().shape({
        name: Yup.string().max(50, t("Max length is", { number: 50 })).required(t('Name is required')),
        country: Yup.number().required(t('Country is required')),
        city: Yup.number()
            .nullable()
            .test(
                'city-required-if-no-customCity',
                t('City is required'),
                function (value) {
                    const { customCity } = this.parent;
                    return customCity ? true : value !== null && value !== undefined;
                }
            )
            .test(
                'customCity-error-location',
                '',
                function (value) {
                    const { customCity } = this.parent;
                    if (!customCity && (value === null || value === undefined)) {
                        return this.createError({
                            path: 'customCity',
                            message: t('Custom city name is required'),
                        });
                    }
                    return true;
                }
            ),
        customCity: Yup.string()
            .nullable()
            .test(
                'customCity-required-if-no-city',
                t('Custom city name is required'),
                function (value) {
                    const { city } = this.parent;
                    return city !== -1 ? true : value !== null && value !== undefined && value !== '';
                }
            ),
        address: Yup.string().required(t("Address is required")),
        phones: Yup.array()
            .min(1, t('At least one phone number is required'))
            .of(
                Yup.object().shape({
                    phone: Yup.string()
                        .required(t('Phone number is required'))
                        .test('is-valid-phone', t('Phone number is not valid'), value => isPhoneValid(value || '')),
                })
            ),
        email: !isBranch
          ? Yup.string()
            .email(t('Invalid email'))
            .test('is-valid-email', t('Invalid email'), (value) => emailValidation(value || ''))
          : Yup.mixed().notRequired(),
        website: Yup.string(),
        messageLanguage: Yup.string(),
        workSchedule: Yup.string().max(50, t("Max length is", { number: 50 })).required(t('Work schedule is required')),
        description: Yup.string().max(200, t("Max length is", { number: 200 })),
    });
};
