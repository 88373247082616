import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {CalendarWrapperComponent} from './CalendarWrapperComponent';
import {CalendarFilterI, getLogbookDates} from './constants';
import {ApolloError, useReactiveVar} from '@apollo/client';
import ErrorService from '../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {currentBranchIdVar, currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import EmptyList from '@services/ui-components/src/EmptyList/EmptyList';
import {
  useGetBookingByIdQuery,
  useGetBookingsListQuery
} from '@me-team/host/main/logbook/graphql/logbook.hooks';
import {useGetInfoCompanyQuery} from '@me-team/host/main/company/graphql/company.hooks';
import {Spinner} from '@services/ui-components';

type CalendarWrapperProps = {
  filters: CalendarFilterI,
};

const CalendarWrapper: React.FC<CalendarWrapperProps> = ({filters}) => {
  const location = useLocation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const currentBranchId = useReactiveVar(currentBranchIdVar);
  const [showPreloader, setShowPreloader] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const getDates = currentBranchId && getLogbookDates(currentBranchId.toString())
  const [isBookingRecords, setIsBookingRecords] = useState<boolean>(false);
  const newBookingId: number = location.state?.recordId

  const {data: infoCompanyData, loading} = useGetInfoCompanyQuery({
    variables: { companyId: +currentCompanyId },
    skip: !currentCompanyId,
    context: {
      errorType: "local",
    },
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  })

  const {data: bookingData, loading: loadingBooking} = useGetBookingByIdQuery({
    skip:!newBookingId || !currentCompanyId || !currentBranchId,
    variables: {
      branchId: +currentBranchId,
      bookingId: newBookingId && newBookingId,
      companyId: +currentCompanyId,
    },
    context: {
      errorType: "local",
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  })
  const newDate: string =  bookingData?.user?.company?.branches?.[0].bookings?.bookings?.[0].date
  const timeZone: string = infoCompanyData?.user?.company?.timeZone?.name

  const {data: bookingListData, loading: loadingBookingList} = useGetBookingsListQuery({
    variables: {
      branchId: +currentBranchId,
      isDeleted: false,
      startDate: getDates?.startDate && getDates?.startDate,
      endDate: getDates?.endDate && getDates?.endDate,
      statusId: filters?.statusId && filters?.statusId,
      serviceId: filters?.serviceId && filters?.serviceId,
      companyId: +currentCompanyId
    },
    skip: !filters?.statusId && !filters?.serviceId || !currentCompanyId || !currentBranchId,
    onCompleted: (data) => {
      const isBooking = data?.user?.company?.branches?.[0].bookingsList?.length > 0
      setIsBookingRecords(isBooking)
    },
    context: {
      errorType: "local",
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  })

  const showPreloaderHandler = () => {
    setShowPreloader(true)
  }
  const hidePreloaderHandler = () => {
    setShowPreloader(false);
  }

  const secondComponent = React.useMemo(() => <CalendarWrapperComponent
    currentCompanyId={+currentCompanyId}
    currentBranchId={+currentBranchId}
    filters={filters}
    timeZone={timeZone}
    showPreloaderHandler={showPreloaderHandler}
    hidePreloaderHandler={hidePreloaderHandler}
    newBookingId={newBookingId}
    newDate={newDate}
  />, [
    currentCompanyId,
    currentBranchId,
    JSON.stringify(filters),
    newBookingId,
    timeZone,
    newDate
  ]);

  if (loading || !currentBranchId || !currentCompanyId) return <Spinner />;

  return (
    <>
      {showPreloader && <div className='d-flex all-unset align-items-center justify-content-center position-fixed top-50 end-50 z-3'>
        <div className='preloader__spinner'></div>
      </div>}
      {
        !isBookingRecords && (filters.serviceId || filters.statusId || filters.positionId || filters.employeeId) ?
          <EmptyList title='No data found for the given values' customClass='mb-5' />
          : secondComponent
      }
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {setIsErrorModalOpen(null)}}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  )
}

export default CalendarWrapper;
