import { useDropzone } from 'react-dropzone';
import React, { useId, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

type SetFieldValueType = (field: string, value: string | boolean | string[] | File | (string | File)[]) => void;

interface DropItemProps {
    index?: number;
    handleImage?: (index?: number, imageURL?: string | null | File, setFieldValue?: SetFieldValueType) => void;
    file?: boolean;
    handleFile?: (field: string, file: File) => void;
    handleFileCallback?: (...args: any[]) => void;
    previewPhoto?: string;
    previewFile?: string;
    fieldName?: string;
    setFieldValue?: (field: string, file: File) => void;
    dismissFile?: () => void;
    rejection?: (text: string) => void;
    disabled?: boolean;
}

interface AcceptedTypes {
    [key: string]: string[];
}

const acceptedImageTypes: AcceptedTypes = {
    'image/jpeg': [],
    'image/png': [],
};

const acceptedFileTypes: AcceptedTypes = {
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
};

const DropItem: React.FC<DropItemProps> = ({ index, handleImage, file, handleFile, handleFileCallback, previewPhoto, previewFile, setFieldValue, fieldName, dismissFile, rejection, disabled = false }) => {
    const { t } = useTranslation();
    const basePath = process.env.REACT_APP_POS_URL;
    const id = useId();
    const [image, setImage] = useState<string | null>(previewPhoto || null);
    const [editing, setEditing] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<File | string>(previewFile ? process.env.REACT_APP_POS_UR + "/" + previewFile : null);

    const handleDrop = (acceptedFiles: File[], fileRejections: File[]) => {
        if (disabled) return;
        if (file) {
            setUploadedFile(null);
            const acceptedExtensions = ['.pdf', '.doc', '.docx'];
            const fileExtension = acceptedFiles[0].name.split('.').pop().toLowerCase();
            if (acceptedFiles[0].size === 0) {
                rejection && rejection(t('The file is empty (0 bytes). Please upload a valid file.'));
                return;
            }
            if (acceptedExtensions.includes(`.${fileExtension}`)) {
                setUploadedFile(acceptedFiles[0]);
                if (handleFile) handleFile(fieldName, acceptedFiles[0]);
                if (handleFileCallback) handleFileCallback(index, acceptedFiles[0], setFieldValue);
            }
            return;
        }

        const imageFile = acceptedFiles.find(file => file.type.startsWith('image/'));
        if (fileRejections && fileRejections.length > 0) {
            rejection(t('Please upload a valid png, jpg or jpeg image'));
        }
        if (imageFile) {
            const maxSizeInBytes = 3 * 1024 * 1024;
            if (imageFile.size === 0) {
                rejection && rejection(t('The image file is empty (0 bytes). Please upload a valid image.'));
                return;
            }
            if (imageFile.size <= maxSizeInBytes) {
                const imageURL = URL.createObjectURL(imageFile);
                setImage(imageURL);
                setEditing(true);
                if (handleImage) handleImage(index, imageFile, setFieldValue);
            } else {
                rejection(t('Image size can\'t be larger than 3 Mb'));
            }
        }
    };

    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        onDrop: (acceptedFiles, fileRejections) => handleDrop(acceptedFiles, fileRejections),
        accept: file ? acceptedFileTypes : acceptedImageTypes,
        disabled: disabled
    });

    const handleRemove = (event: MouseEvent<HTMLAnchorElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (disabled) return;
        if (image) {
            URL.revokeObjectURL(image);
            setImage(null);
        }
        setEditing(false);
        if (handleImage) handleImage(index, null, setFieldValue);
    };

    const handleEdit = (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (disabled) return;
        const fileInput = document.getElementById(id);
        fileInput?.click();
    };

    const handleRemoveFile = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (disabled) return;
        setUploadedFile(null);
        if (dismissFile) dismissFile();
    };

    return (
        <>
            {file ?
                <>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} id={id} disabled={disabled} />
                        <div
                            className={`cursor-pointer rounded-1 border d-flex justify-content-center align-items-center position-relative ${disabled ? 'bg-light' : ''}`}
                            style={{ height: '100px', maxWidth: '100px' }}
                        >
                            <div className='d-flex justify-content-center align-items-center flex-column cursor-pointer'>
                                <i className="bi bi-plus-lg"></i>
                                <p className="fs-7 mb-0">{t("Add")}</p>
                            </div>
                        </div>
                    </div>

                    {uploadedFile && (
                        <div className="d-flex align-items-center mt-2 ">

                            <span>
                                {
                                    uploadedFile instanceof File ? uploadedFile.name :
                                        <a className="text-decoration-none text-dark" href={`${basePath}/${previewFile}`} download={`${basePath}/${previewFile}`} target="_blank"
                                            rel="noopener noreferrer">
                                            {previewFile.split('/').pop()}
                                        </a>
                                }
                            </span>
                            <button
                                className="btn btn-link ml-2"
                                onClick={handleRemoveFile}
                            >
                                <i className="bi bi-x fs-4"></i>
                            </button>
                        </div>
                    )}
                </>
                :
                <>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} id={id} disabled={disabled} />
                        <div
                            className={`cursor-pointer rounded-1 border-dashed d-flex justify-content-center align-items-center position-relative ${disabled ? 'bg-light' : ''}`}
                            style={{ height: '100px', maxWidth: '100px' }}
                        >
                            {image && (
                                <>
                                    <div className="btn-photo position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center cursor-pointer">
                                        <a onClick={handleRemove} className="text-white bg-transparent border-0 cursor-pointer">
                                            <i className="bi bi-trash m-1"></i>
                                        </a>
                                        <a onClick={handleEdit} className="text-white bg-transparent border-0 cursor-pointer ms-2">
                                            <i className="bi bi-pencil m-1"></i>
                                        </a>
                                    </div>
                                    <img src={image} alt="Uploaded" className="mw-100 mh-100" />
                                </>
                            )}
                            {!image && (
                                <div className='d-flex justify-content-center align-items-center flex-column cursor-pointer'>
                                    <i className="bi bi-plus-lg"></i>
                                    <p className="fs-7 mb-0">{t("Add")}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default DropItem;

