import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import React from 'react';

type EmptyListPropsType = {
  children?: React.ReactNode;
  title: string;
  customClass?: string;
};

const EmptyList: React.FC<EmptyListPropsType> = ({ children, title, customClass }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row className='w-100 justify-content-md-center'>
        <Col
          md={5}
          className={`mt-5 ${customClass}`}>
          <h5 className='text-center font-weight-bold'>{t(title)}</h5>
        </Col>
      </Row>
      <Row className='w-100 justify-content-md-center'>
        <Col md={3}>
          {children}
        </Col>
      </Row>
    </>
  );
};

export default EmptyList;
