import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Button, Col } from "react-bootstrap";
import { useReactiveVar } from "@apollo/client";
import { currentBranchIdVar } from "../../apollo/globalVar/state";
import {BranchProxy} from '../../../main/graphql/types';

type SidebarBranchesProps = {
    branches: BranchProxy[],
    handleChangeBranch: (id: number | string) => void,
    handleCloseNavbar?: () => void
}

const SidebarBranches: React.FC<SidebarBranchesProps> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showAllBranches, setShowAllBranches] = useState<boolean>(false);

    const currentBranchId = useReactiveVar(currentBranchIdVar);

    const activeBranches = props.branches?.filter(branch => !branch.deleted);

    const sortedBranches = activeBranches.sort((a, b) => {
        if (a.id === currentBranchId) return -1;
        if (b.id === currentBranchId) return 1;
        return 0;
    });

    const handleEditBranch = (branchId: number) => {
        navigate(`/branch/${branchId}`);
        props.handleCloseNavbar();
    };

    return (
        <>
            {(showAllBranches ? sortedBranches : sortedBranches?.slice(0, 2))?.map((branch, index) => (
                <div key={branch.id}
                     className={`nav-item-menu rounded-1 px-3 d-flex justify-content-between mb-1 ${currentBranchId === branch.id ? "active" : ""}`}>
                    <Col xs={10}>
                        <div className="text-truncate me-2 w-100 cursor-pointer text-dark" onClick={() => props.handleChangeBranch(branch.id)}>
                            <i className='bi bi-scissors me-2'></i>
                            {branch.name}, {branch.city.name}
                        </div>
                    </Col>
                    <button className='edit-button border-0 px-0' onClick={() => handleEditBranch(branch.id)}>
                        <i className='bi bi-pencil text-primary'></i>
                    </button>
                </div>
            ))}
            {activeBranches.length > 2 && (
                <div className="rounded-1 text-center mb-2">
                    <Button variant="light" className="w-100 py-2 px-3 rounded-1 bg-white border-0 show-branch text-dark fw-normal fs-7" onClick={() => setShowAllBranches(!showAllBranches)}>
                        {showAllBranches ? t("Hide") : t("View all")}
                    </Button>
                </div>
            )}
        </>
    );
}

export default SidebarBranches;
